<template>
  <header class="page-header">
    <div class="row">
      <div class="group-left">
        <Filters
          :search="search"
          :search-placeholder="searchPlaceholder"
          :selected-filters="selectedFilters"
          @update-search="(e) => $emit('update-search', e)"
          @unselect-filter="unselectFilter"
          @unselect-all="unselectAllFilters"
        >
          <!-- GROUPS -->
          <FilterCascade
            name="groups"
            :color="filterGroupsColor"
            :values="dataGroups.selected"
            :options="dataGroups.options"
            @updated="updateSelectedGroups"
          />

          <!-- TYPE -->
          <FilterCascade
            name="campaignTypes"
            :color="filterCampaignTypesColor"
            :values="dataCampaignTypes.selected"
            :options="dataCampaignTypes.options"
            @updated="updateSelectedCampaignTypes"
          />

          <!-- TAGS -->
          <FilterCascade
            name="tags"
            :color="filterTagsColors[0]"
            :values="dataTags.selected"
            :options="dataTags.options"
            @updated="updateSelectedTags"
          />

          <!-- DATEPICKER -->
          <DatePicker
            class="filter date"
            @updated="$emit('updated-date-range')"
          />
        </Filters>
      </div>
      <div class="group-right">
        <DownloadButton
          :tags="tags"
          :show-popover="true"
          :loading="downloadLoading"
          :is-list="true"
          @click-download="(e) => $emit('download', e)"
        />
      </div>
    </div>

    <div class="statistics row">
      <div
        class="major"
        :class="`major-${channel}`"
      >
        <h2 v-show="kpis.nb_campaigns !== undefined">
          {{ kpis.nb_campaigns | formatNumber }}
          {{ $tc(`global.${channel}_campaign`, kpis.nb_campaigns) }}
        </h2>
        <p v-if="kpis.sms_sent !== undefined">
          {{ kpis.sms_sent | formatNumber }}
          {{ $tc(`global.${channel}`, kpis.sms_sent) }}
          {{ $tc('global.sent', kpis.sms_sent) }}
        </p>
        <p v-if="kpis.emails_sent !== undefined">
          {{ kpis.emails_sent | formatNumber }}
          {{ $tc(`global.${channel}`, kpis.emails_sent) }}
          {{ $tc('global.sent', kpis.emails_sent) }}
        </p>
        <p v-if="kpis.pn_sent !== undefined">
          {{ kpis.pn_sent | formatNumber }}
          {{ $tc(`global.${channel}`, kpis.pn_sent) }}
          {{ $tc('global.sent_e', kpis.pn_sent) }}
        </p>
        <span v-if="kpis.sms_countries_details && kpis.sms_countries_details.length > 1">
          <el-button
            type="text"
            class="show_country_btn"
            @click="showCountriesModal = true"
          >
            <i class="material-icons md-16 info">info_outline</i>
            <span class="sms_countries_details_text">
              {{ $t("kpi.sms.show_country_detail") }}
            </span>
          </el-button>
        </span>
      </div>

      <KPIStats
        v-if="kpis"
        :kpis="kpis"
        :channel="channel"
      />
    </div>
    <ModalCountriesDetail
      :sms-countries-details="kpis.sms_countries_details"
      :show-countries-modal.sync="showCountriesModal"
    />
  </header>
</template>


<script>
import KPIStats from '@/components/Parts/Header/KPI/KPIStats'
import {
  filterGroups,
  filterTags,
  filterCampaignTypes
} from '@/mixins'
import {
  Filters,
  FilterCascade,
  DatePicker,
  DownloadButton
} from '@/components/Common/Form'
import ModalCountriesDetail from '@/components/Parts/SMS'

export default {
  name: 'PartsHeaderChannel',
  components: {
    KPIStats,
    DownloadButton,
    Filters,
    FilterCascade,
    DatePicker,
    ModalCountriesDetail
  },
  mixins: [ filterGroups, filterTags, filterCampaignTypes ],
  props: {
    type: {
      type: String,
      required: true
    },
    kpis: {
      type: Object,
      required: true
    },
    selectedTags: {
      type: Array,
      required: true
    },
    channel: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
    channelTags: {
      type: Array,
      required: true
    },
    downloadLoading: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dataGroups: {
        options: [],
        selected: []
      },
      dataCampaignTypes: {
        options: [],
        selected: []
      },
      dataTags: {
        options: [],
        selected: []
      },
      showCountriesModal: false
    }
  },
  computed: {
    groups () {
      return this.$store.getters['session/groups']
    },
    selectedGroups () {
      return this.$store.getters['app/selectedGroups']
    },
    /**
     * Get all filters selected options to generate tags
     */
    selectedFilters () {
      let selectedFamilies = []
      return [
        ...this.dataGroups.selected.map((tag, index) => ({
          type: 'groups', color: this.filterGroupsColor, tag, index
        })),
        ...this.dataCampaignTypes.selected.map((tag, index) => ({
          type: 'campaignTypes', color: this.filterCampaignTypesColor, tag, index
        })),
        ...this.dataTags.selected.map(tags => [tags[1]]).map((tag, index) => {
          const familyId = tag[0].id.split(':')[0]
          if (selectedFamilies.indexOf(familyId) === -1) {
            selectedFamilies.push(familyId)
          }
          const colorIndex = selectedFamilies.indexOf(familyId) % this.filterTagsColors.length
          return {
            type: 'tags',
            color: this.filterTagsColors[colorIndex],
            tag,
            index
          }
        }),
      ]
    },
    /**
     * Get placeholder depending type
     */
    searchPlaceholder () {
      const ns = ['landing', 'powerbi'].indexOf(this.type) > -1 ? this.type : 'global'
      return this.$t(`${ns}.search`)
    },
  },
  watch: {
    tags() {
      this.dataTags = this.initSelectedTags(this.tags, this.channelTags)
    },
  },
  mounted () {
    // Initialize filters with defaults selected options
    this.dataGroups = this.initSelectedGroups(this.groups, this.selectedGroups)
    this.dataCampaignTypes = this.initSelectedCampaignTypes(this.type, this.channelTags)
    this.dataTags = this.initSelectedTags(this.tags, this.channelTags)
  },
  methods: {
    /**
     * Clear all selected values
     */
    unselectAllFilters () {
      this.dataGroups.selected = []
      this.dataCampaignTypes.selected = []
      this.dataTags.selected = []
      this.$emit('clear-selected-filters')
    },
    /**
     * On filter tag deletion, remove it from list of selected then trigger update
     * related to the filter type
     */
    unselectFilter (filter) {
      switch (filter.type) {
        case 'groups': {
          this.updateSelectedGroups(
            this.dataGroups.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
        case 'campaignTypes': {
          this.updateSelectedCampaignTypes(
            this.dataCampaignTypes.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
        case 'tags': {
          this.updateSelectedTags(
            this.dataTags.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
      }
    },
    updateSelectedGroups (values) {
      this.dataGroups.selected = values
      this.$emit('updated-group', this.formatGroupsCascader(values))
    },
    updateSelectedCampaignTypes (values) {
      this.dataCampaignTypes.selected = values
      this.$emit('updated-channel-tags', this.formatCampaignTypesCascader(values))
    },
    updateSelectedTags (values) {
      this.dataTags.selected = values
      this.$emit('updated-tags', this.formatTagsCascader(values))
    }
  }
}

</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.page-header {
  .el-dialog {
    .el-dialog__body {
      position: relative;
      padding-bottom: 70px;
    }
  }
  .filters_list {
    margin-bottom: 3rem;

    .el-tag {
      margin-bottom: 0.6rem;
      margin-right: 0.6rem;
    }
    .el-tag .el-icon-close {
      top: -1px;
      &::before {
        margin-top: 1px;
      }
    }
    .el-button {
      padding: 0;
    }
  }
  .statistics {
    margin-top: 40px;
  }
  .row {
    display: flex;
    width: 100%;

    & + .row {
      justify-content: space-between;

      @media only screen and (max-width: $--mobile-width) {
        display: flex;
        flex-direction: column;
      }
    }

    .group-left {
      position: relative;
      width: 100%;

      .el-select {
        float: left;
      }

      .el-button {
        border-radius: 4px 0 0 4px;
        &:hover,
        &:focus {
          position: relative;
          z-index: 10;
        }
      }
    }
    .group-right {
      display: flex;
      justify-content: flex-end;

      .el-checkbox,
      .el-button {
        margin: 0 !important;
        margin-left: 0.5rem !important;
      }
      .el-checkbox {
        border-radius: 2px;
        background-color: #fff;
        transition: background-color 0.3s;
        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }

        .el-checkbox__label {
          font-size: 12px;
          font-weight: 600;
          font-family: "Open Sans", sans-serif;
        }
      }
    }
  }
  .major-email {
    width: 482px;
    height: 152px;
    background-image: url(~@/assets/svg/pictos/email.svg) no-repeat center right; /* fallback */
    background: url(~@/assets/svg/pictos/email.svg) no-repeat calc(100% - 30px) 50%/auto 50px, repeating-linear-gradient(
        -60deg,
        $--color-light-blue 0%,
        $--color-light-blue 33%,
        $--color-primary 33%,
        $--color-primary 100%
      ) no-repeat 100% 100%;
  }
  .major-push {
    width: 482px;
    height: 152px;
    background-image: url(~@/assets/svg/pictos/push.svg) no-repeat center right; /* fallback */
    background: url(~@/assets/svg/pictos/push.svg) no-repeat calc(100% - 30px) 50%/auto 50px, repeating-linear-gradient(
        -60deg,
        $--color-light-blue 0%,
        $--color-light-blue 33%,
        $--color-primary 33%,
        $--color-primary 100%
      ) no-repeat 100% 100%;
  }
  .major-sms {
    width: 482px;
    height: 152px;
    background: url(~@/assets/svg/pictos/sms.svg) no-repeat calc(100% - 30px) 50%/auto 50px, repeating-linear-gradient(
      -60deg,
        $--color-light-blue 0%,
        $--color-light-blue 33%,
        $--color-primary 33%,
        $--color-primary 100%
      ) no-repeat 100% 100%;
  }
  .major {
    color: white;
    border-radius: 3px;
    height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 25px;
    h2 {
      font-size: 23px;
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    @media only screen and (max-width: $--mobile-width) {
      width: 100%;
    }
  }
  .show_country_btn{
    padding: 0;
    display: inline;
    height: auto;
    span {
      color: white;
      .sms_countries_details_text {
        text-decoration: underline;
        font-size: 12px;
      }
      .info {
        font-size: 16px;
        text-decoration: none;
      }
    }
  }
}
</style>
