import moment from 'moment'
import Vue from 'vue'
import axios from 'axios'

import APIException from './exceptions'
import i18n from '@/languages/i18n'


const SET_ITEMS = 'SET_ITEMS'
const SET_ITEMS_LOADING = 'SET_ITEMS_LOADING'
const SET_KPIS = 'SET_KPIS'
const SET_CHANNEL_TAGS = 'SET_CHANNEL_TAGS'
const SET_TAGS = 'SET_TAGS'
const REMOVE_TAGS = 'REMOVE_TAGS'
const SET_SORT_BY = 'SET_SORT_BY'
const SET_PAGE_LIMIT = 'SET_PAGE_LIMIT'
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
const SET_DOWNLOAD_LOADING = 'SET_DOWNLOAD_LOADING'
const SET_SEARCH = 'SET_SEARCH'

const CancelToken = axios.CancelToken
let emailListCancel
let kpisCancel

function exportListEmailFilename(client, lang) {
  // NomClient_CampagnesEmails_AAAAMMJJ / ClientName_EmailCampaigns_YYYYMMDD
  const clientName = client.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  let splitView = 'EmailCampaigns'
  let dateFmt = 'YYYYMMDD'

  if (lang === 'fr') {
    splitView = 'CampagnesEmail'
    dateFmt = 'DDMMYYYY'
  }
  return `${clientName}_${splitView}_${moment().format(dateFmt)}.xlsx`
}

const moduleEmail = {
  namespaced: true,
  state: {
    items: [],
    isLoadingItems: false,
    total: 0,
    totalIdms: 0,
    kpis: {},
    downloadLoading: false,
    filters: {
      limit: 20,
      page: 1,
      search: '',
      channelTags: ['one_shot', 'automatic', 'transactional'],
      tags: [],
      orderBy: null
    }
  },
  mutations: {
    [SET_ITEMS] (state, val) {
      Vue.set(state, 'items', val.objects)
      Vue.set(state, 'total', val.meta.total)
      Vue.set(state, 'totalIdms', val.meta.total_idms)
    },
    [SET_ITEMS_LOADING] (state, val) {
      state.isLoadingItems = val
    },
    [SET_KPIS] (state, val) {
      state.kpis = val
    },
    [SET_CHANNEL_TAGS] (state, channelTags) {
      Vue.set(state.filters, 'channelTags', channelTags)
    },
    [SET_TAGS] (state, tags) {
      Vue.set(state.filters, 'tags', tags)
    },
    [REMOVE_TAGS] (state, id) {
      const findId = state.filters.tags.findIndex(tag => tag.id === id)
      if (findId >= 0) {
        state.filters.tags.splice(findId, 1)
      }
    },
    [SET_SORT_BY] (state, sortBy) {
      Vue.set(state.filters, 'orderBy', sortBy)
    },
    [SET_PAGE_LIMIT] (state, pageLimit) {
      Vue.set(state.filters, 'limit', pageLimit)
    },
    [SET_CURRENT_PAGE] (state, currentPage) {
      Vue.set(state.filters, 'page', currentPage)
    },
    [SET_DOWNLOAD_LOADING] (state, status) {
      Vue.set(state, 'downloadLoading', status)
    },
    [SET_SEARCH] (state, search) {
      Vue.set(state.filters, 'search', search)
    }
  },
  actions: {
    resetFilters ({ commit }) {
      commit(SET_TAGS, [])
      commit(SET_SEARCH, '')
      commit(SET_CHANNEL_TAGS, [])
    },
    setSearch ({ commit }, search) {
      commit(SET_SEARCH, search)
    },
    updatePageLimit ({ commit }, pageLimit) {
      commit(SET_PAGE_LIMIT, pageLimit)
    },
    updateCurrentPage ({ commit }, currentPage) {
      commit(SET_CURRENT_PAGE, currentPage)
    },
    setSortBy ({ commit }, sortBy) {
      commit(SET_SORT_BY, sortBy)
    },
    removeSelectedTag ({ commit }, { id }) {
      commit(REMOVE_TAGS, id)
    },
    setTags ({ commit }, tags) {
      commit(SET_TAGS, tags)
    },
    setChannelTags ({ commit }, channelTags) {
      commit(SET_CHANNEL_TAGS, channelTags)
    },
    async getItems ({ commit }, params) {
      if (emailListCancel) { emailListCancel() }
      commit(SET_ITEMS_LOADING, true)
      try {
        const response = await Vue.axios.get(`/api/emails`, {
          params: params,
          cancelToken: new CancelToken(function executor(c) {
            emailListCancel = c
          })
        })
        if (response.status >= 300) { throw response }

        commit(SET_ITEMS, response.data)
        return response
      } catch (err) {
        return new APIException(err)
      } finally {
        commit(SET_ITEMS_LOADING, false)
      }
    },
    async getKpis ({ commit }, params) {
      if (kpisCancel) { kpisCancel() }
      try {
        const response = await Vue.axios.get(`/api/emails/kpis`, {
          params: params,
          cancelToken: new CancelToken(function executor(c) {
            kpisCancel = c
          })
        })
        if (response.status >= 300) { throw response }

        commit(SET_KPIS, response.data)
        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    async export ({ rootGetters, commit }, params) {
      const clientName = rootGetters['session/client'].name
      const filename = exportListEmailFilename(clientName, i18n.locale)

      commit(SET_DOWNLOAD_LOADING, true)
      try {
        const xlsx = await Vue.axios({
          url: `/api/emails/export`,
          method: 'POST',
          responseType: 'blob',
          params: params
        })
        const blob = new Blob([xlsx.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
        })

        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename)
        } else {
          const link = document.createElement('a')
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      } catch (err) {
        return new APIException(err)
      } finally {
        commit(SET_DOWNLOAD_LOADING, false)
      }
    }
  },
  getters: {
    isLoadingItems: state => state.isLoadingItems,
    emails: state => state.items,
    kpis: state => state.kpis,
    filters: state => state.filters,
    totalEmails: state => state.total,
    totalIdms: state => state.totalIdms,
    downloadLoading: state => state.downloadLoading
  }
}

export default moduleEmail
