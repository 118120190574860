<template>
  <el-dropdown
    trigger="click"
    class="tableview-selector"
    @command="setCurrentSplit"
    @visible-change="isOpen"
  >
    <el-button
      type="secondary"
    >
      {{ $t('detail.compare_view') }}
      <i
        class="el-icon--right"
        :class="arrowup ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
      />
    </el-button>

    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="(split, index) in splitMenu"
        :key="index"
        :command="split"
        :disabled="split.disabled < 1"
      >
        {{ split.name }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'TableSelector',
  props: {
    channel: {
      type: String,
      required: true
    },
    headerSplits: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      arrowup: false
    }
  },
  computed: {
    splitMenu () {
      let menu = []

      if (this.headerSplits.segments) {
        menu.push({
          name: `${this.$tc('detail.by_segments', 2)} (${this.headerSplits.segments.total})`,
          type: 'segment',
          cat: 'segments',
          disabled: this.headerSplits.segments.total
        })
      }
      if (this.headerSplits.sendings) {
        menu.push({
          name: `${this.$tc('detail.by_sendings', 2)} (${this.headerSplits.sendings.total})`,
          type: 'sending',
          cat: 'sendings',
          disabled: this.headerSplits.sendings.total
        })
      }
      if (this.headerSplits.versions) {
        menu.push({
          name: `${this.$tc('detail.by_versions', 2)} (${this.headerSplits.versions.total})`,
          type: 'version',
          cat: 'versions',
          disabled: this.headerSplits.versions.total
        })
      }
      if (this.headerSplits.typologies) {
        menu.push({
          name: `${this.$tc('detail.by_typologies', 2)} (${this.headerSplits.typologies.total})`,
          type: 'typology',
          cat: 'typologies',
          disabled: this.headerSplits.typologies.total
        })
      }
      if (this.headerSplits.countries) {
        menu.push({
          name: `${this.$t('detail.by_countries')} (${this.headerSplits.countries.total})`,
          type: 'countries',
          cat: 'countries',
          disabled: this.headerSplits.countries.total
        })
      }
      if (this.headerSplits.extra_data) {
        for (let i in this.headerSplits.extra_data.objects) {
          let extraData = this.headerSplits.extra_data.objects[i]
          let extraDataLabel = this.$tc(`extra_data.by_${extraData.name}`, 2)
          menu.push({ name: `${extraDataLabel} (${extraData.values.length})`, values: extraData.values, type: 'extra_data', cat: 'extra_data', disabled: extraData.values.length, 'extra_data_id': extraData.id })
        }
      }
      return menu
    }
  },
  methods: {
    isOpen (open) {
      this.arrowup = open
      return open
    },
    setCurrentSplit (split) {
      let splits = []

      if (split.cat === 'sendings') {
        splits.push({
          id: '',
          from: split.name,
          name: '',
          type: 'sending',
          cat: 'sendings'
        })
      } else if (split.cat === 'countries') {
        splits.push({
          id: '',
          from: split.name,
          name: '',
          type:split.type,
          cat: split.cat
        })
      } else if (split.cat === 'extra_data') {
        for (let i in split.values) {
          let splitData = {
            from: split.name,
            name: split.values[i].name,
            id: split.values[i].id,
            type: split.type,
            cat: split.cat,
            extra_data_id: split.extra_data_id
          }
          splits.push(splitData)
        }
      } else {
        for (let i in this.headerSplits[split.cat].objects) {
            splits.push({
              from: split.name,
              name: this.headerSplits[split.cat].objects[i].name,
              id: this.headerSplits[split.cat].objects[i].id,
              type: split.type,
              cat: split.cat
            })
        }
      }
      this.$emit('select-split', splits)
    }
  }
}
</script>
