<template>
  <div class="empty-table">
    <img
      class="empty-table__puddle"
      :src="require('../../../assets/svg/patate.svg')"
      alt="Empty background"
    >
    <img
      class="empty-table__box"
      :src="require('../../../assets/svg/pictos/box.svg')"
      alt="Empty foreground"
    >
    <span class="empty-table__text">
      {{ emptyLabel }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'EmptyData',
  props: {
    emptyLabel: { type: String, required: true }
  }
}
</script>

<style lang="scss" scoped>
.empty-table {
  position: relative;
  margin: auto;
  padding: 20px 0;
  text-align: center;
  &__puddle {
    margin-top: 20px;
  }
  &__box {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 40%;
  }
  &__text {
    position: absolute;
    transform: translate(-50%, -50%);
    font-weight: 600;
    color: #051119;
    left: 50%;
    top: 57%;
    line-height: normal;
  }
}
</style>
