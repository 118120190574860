<template>
  <header class="header-landing-list page-header">
    <div class="row">
      <div class="group-left">
        <Filters
          :search="search"
          :search-placeholder="searchPlaceholder"
          :selected-filters="selectedFilters"
          @update-search="(e) => $emit('update-search', e)"
          @unselect-filter="unselectFilter"
          @unselect-all="unselectAllFilters"
        >
          <!-- GROUPS -->
          <FilterCascade
            name="groups"
            :color="filterGroupsColor"
            :values="dataGroups.selected"
            :options="dataGroups.options"
            @updated="updateSelectedGroups"
          />

          <!-- Status -->
          <FilterCascade
            name="status"
            :color="filterStatusColor"
            :values="dataStatus.selected"
            :options="dataStatus.options"
            @updated="updateSelectedStatus"
          />
        </Filters>
      </div>
      <div class="group-right">
        <DownloadButton
          :loading="downloadLoading"
          :is-list="true"
          @click-download="(e) => $emit('download', e)"
        />
      </div>
    </div>

    <!-- Results block -->
    <div class="row statistics">
      <div class="hlanding">
        <p v-if="kpis">
          <span class="led led_published" />
          {{ kpis.nb_lp_published }} {{ $tc('landing.kpis_published', kpis.nb_lp_published) }}
        </p>
        <p v-if="kpis">
          <span class="led led_unpublished" />
          {{ kpis.nb_lp_unpublished }} {{ $tc('landing.kpis_unpublished', kpis.nb_lp_unpublished) }}
        </p>
      </div>
    </div>
  </header>
</template>

<script>
import { DownloadButton } from '@/components/Common/Form'
import {
  filterStatus,
  filterGroups
} from '@/mixins'
import {
  Filters,
  FilterCascade
} from '@/components/Common/Form'

export default {
  name: 'PartsHeaderLanding',
  components: { DownloadButton, Filters, FilterCascade },
  mixins: [ filterGroups, filterStatus ],
  props: {
    type: {
      type: String,
      required: true
    },
    search: {
      type: String,
      required: true
    },
    kpis: {
      type: Object,
      required: true
    },
    status: {
      type: Array,
      required: true
    },
    downloadLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataGroups: {
        options: [],
        selected: []
      },
      dataStatus: {
        options: [],
        selected: []
      }
    }
  },
  computed: {
    groups () {
      return this.$store.getters['session/groups']
    },
    selectedGroups () {
      return this.$store.getters['app/selectedGroups']
    },
    /**
     * Get all filters selected options to generate tags
     */
    selectedFilters () {
      return [
        ...this.dataGroups.selected.map((tag, index) => ({
          type: 'groups', color: this.filterGroupsColor, tag, index
        })),
        ...this.dataStatus.selected.map((tag, index) => ({
          type: 'status', color: this.filterStatusColor, tag, index
        })),
      ]
    },
    /**
     * Get placeholder depending type
     */
    searchPlaceholder () {
      const ns = ['landing', 'powerbi'].indexOf(this.type) > -1 ? this.type : 'global'
      return this.$t(`${ns}.search`)
    },
  },
  mounted () {
    // Initialize filters with defaults selected options
    this.dataGroups = this.initSelectedGroups(this.groups, this.selectedGroups)
    this.dataStatus = this.initSelectedStatus(this.status)
  },
  methods: {
    /**
     * Clear all selected values
     */
    unselectAllFilters () {
      this.dataStatus.selected = []
      this.dataGroups.selected = []
      this.$emit('clear-selected-filters')
    },
    /**
     * On filter tag deletion, remove it from list of selected then trigger update
     * related to the filter type
     */
    unselectFilter (filter) {
      switch (filter.type) {
        case 'groups': {
          this.updateSelectedGroups(
            this.dataGroups.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
        case 'status': {
          this.updateSelectedStatus(
            this.dataStatus.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
      }
    },
    updateSelectedGroups (values) {
      this.dataGroups.selected = values
      this.$emit('updated-group', this.formatGroupsCascader(values))
    },
    updateSelectedStatus (values) {
      this.dataStatus.selected = values
      this.$emit('updated-status', this.formatStatusCascader(values))
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
.header-landing-list {
  .hlanding {
    border-radius: 3px;
    padding: 1rem;
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 250px;
    background-color: $--color-header-detail-darkgreen-bg;
    background-image: url(~@/assets/svg/pictos/landing_page.svg),
      url(~@/assets/svg/lp-green-bg.svg);
    background-repeat: no-repeat;
    background-position: 70px 60%, -43px;
    background-size: auto auto, 108%;

    p {
      font-size: 23px;
      font-weight: 600;
      color: white;
    }
  }
}
</style>
