import Vue from 'vue'

import APIException from './exceptions'

const SET_PREFERENCES = 'SET_PREFERENCES'

const moduleTag = {
  namespaced: true,
  state: {
    preferences: []
  },
  mutations: {
    [SET_PREFERENCES] (state, preferences) {
      Vue.set(state, 'preferences', preferences)
    }
  },
  actions: {
    async getPreferences ({ commit }) {
      try {
        const response = await Vue.axios.get('/api/user_preferences')
        if (response.status >= 300) { throw response }

        commit(SET_PREFERENCES, response.data)
        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    async updatePreferences ({ dispatch }, {property, value}) {
      const data = {
        "property": property,
        "value": value.toString()
      }

      try {
        const response = await Vue.axios.post('/api/user_preferences', data)
        if (response.status >= 300) { throw response }

        dispatch("getPreferences")
      } catch (err) {
        return new APIException(err)
      }
    },
  },
  getters: {
    preferences: state => state.preferences,
  }
}

export default moduleTag
