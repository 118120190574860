<template>
  <section class="page_reports">
    <header class="page-header">
      <div>
        <h1>{{ $t('reports.title') }}</h1>
        <p>{{ $t('reports.subtitle') }}</p>
      </div>
      <GroupSelector @updated="applyGroupFilter" />
    </header>
    <main
      v-loading="loading"
      class="page_content"
    >
      <div class="reports-container">
        <ReportCard
          v-for="report in reports"
          :key="report.id"
          :item="report"
        />
      </div>

      <!-- NO DATA -->
      <TableEmptyData
        v-if="reports.length === 0"
        :empty-label="$t('reports.no_data')"
      />

      <!-- PAGINATION -->
      <el-pagination
        small
        layout="prev, pager, next"
        :current-page.sync="currentPage"
        :page-size="limit"
        :hide-on-single-page="false"
        :pager-count="7"
        :total="totalReports"
      />
    </main>
  </section>
</template>

<script>
import { get as _get } from 'lodash-es'
import { ReportCard } from '@/components/Parts/Reports'
import { GroupSelector } from '@/components/Common/Form'
import { TableEmptyData }  from '@/components/Common/Table'

export default {
  name: 'ViewsReportsList',
  components: {
    ReportCard,
    GroupSelector,
    TableEmptyData
  },
  data() {
    return {
      limit: 6,
      page: 1
    }
  },
  computed: {
    loading () {
      return this.$store.getters['reports/loading']
    },
    currentGroup () {
      const groups = this.$store.getters['app/selectedGroups']
      const hasMultiSelectedGroup = groups.length > 1

      // If more than one group selected reset the filters
      if (hasMultiSelectedGroup) {
        this.$store.dispatch('app/selectGroups', [])
        return null
      }

      return _get(groups, '0') || null
    },
    reports () {
      return this.$store.getters['reports/items']
    },
    totalReports () {
      return this.$store.getters['reports/total']
    },
    currentPage: {
      get () {
        return this.page
      },
      set (newPage) {
        if (newPage === this.page) { return }
        this.loadReports(newPage)
      }
    }
  },
  async mounted () {
    await this.loadReports(1)
  },
  methods: {
    async applyGroupFilter() {
      await this.loadReports(1)
    },
    async loadReports(page) {
      let params = {
        l: this.limit,
        p: page
      }
      if (this.currentGroup) {
        params.group = this.currentGroup.id
      }
      try {
        await this.$store.dispatch('reports/listReports', params)
        this.page = page
      } catch(err) {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.page_reports {
  max-width: $--max-width;
  margin: auto;
  padding: 0 50px;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 4rem;
    font-size: 16px;

    @media only screen and (max-width: $--mobile-width) {
      flex-direction: column-reverse;
      .el-dropdown {
        align-self: flex-end;
        margin-bottom: 1rem;
      }
    }
  }
  .page_content {
    .reports-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 20px;
    }

    ::v-deep .el-pagination {
      margin-top: 20px;
      text-align: center;

      .btn-next, .btn-prev, .el-pager li {
        background: none;
      }
    }
  }
}
</style>
