<template>
  <header class="header-qr page-header">
    <div class="row">
      <Breadcrumb :items="breadcrumbs" />
    </div>
    <div class="entete block-meta">
      <div class="left">
        <div
          class="header_qr"
        >
          <i class="custom_icon">
            <img src="@/assets/svg/icons/qrcode.svg">
          </i>
          <span>{{ $t('qr.generated') | capitalize }}</span>
          <span v-if="headerMeta.created_at">
            {{ headerMeta.created_at | formatDate($i18n.locale, userTimezone, $t('global.date_format')) }}
          </span>
        </div>

        <div
          v-if="headerMeta.redir_content"
          class="header_link"
        >
          <i class="material-icons md-24">link</i>
          <a
            :href="headerMeta.redir_content"
            target="_blank"
          >
            {{ headerMeta.redir_content }}
          </a>
        </div>
      </div>

      <div class="right">
        <DatePickerQr
          v-if="headerMeta.created_at"
          :publication-date="headerMeta.created_at"
          @updated="(e) => $emit('range-date-updated', e)"
        />
      </div>
    </div>
  </header>
</template>

<script>
import {
  DatePickerQr
} from '@/components/Common/Form'
import Breadcrumb from '@/components/Common/Breadcrumb'
import { logifier_id } from '@/mixins'

export default {
  name: 'PartHeaderQRDetail',
  components: {
    DatePickerQr,
    Breadcrumb
  },
  mixins: [logifier_id],
  props: {
    headerMeta: {
      type: Object,
      default: () => {}
    },
    downloadLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    },
    groupName () {
      if(this.headerMeta.group) {
        let gname = this.logifier_id(this.headerMeta.group).name
        return `(${gname})`
      } else {
        return ''
      }
    },
    startDate () {
      return (new Date(this.$store.getters['qrCodes/startDate'])).toJSON()
    },
    endDate () {
      return (new Date(this.$store.getters['qrCodes/endDate'])).toJSON()
    },
    breadcrumbs () {
      return [
        {
          state: 'qr-list',
          name: `${this.$t('labels.qr_codes')} ${this.groupName}`
        },
        {
          state: null,
          name: this.headerMeta.name
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.header-qr {
  .block-meta {
    border-radius: 3px;
    padding: 2rem;
    margin-bottom: 3rem;
    background-color: $--color-header-detail-dark-bg;
    background: repeating-linear-gradient(
      -45deg,
      $--color-header-detail-dark-bg 0%,
      $--color-header-detail-dark-bg 37%,
      $--color-header-detail-bg 37%,
      $--color-header-detail-bg 100%
    );
    i {
      margin-right: 0.5rem;
    }
  }
  .header_qr {
    margin-bottom: 1rem;
    line-height: 18px;
    i {
      margin-left: 0.3rem;
      padding-right: 0.3rem;
      line-height: 18px;
      vertical-align: top;
    }
  }
  .header_link {
    i {
      line-height: 18px;
      vertical-align: text-top;
    }
    a {
      color: black;
      text-decoration: none;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem !important;

    @media only screen and (max-width: $--mobile-width) {
      flex-direction: column;
    }
  }
  .entete {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
    .left {
      i {
        text-align: center;
        width: 24px;
        font-size: 24px;
        height: 24px;
        margin-right: 10px;
        color: $--color-primary;
      }
    }
    .right {
      display: flex;
      align-items: center;
      button,
      .el-dropdown {
        margin-left: 0.3rem;
      }
    }
  }
}
</style>
