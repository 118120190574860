<template>
  <div class="select-idm">
    <div>
      <div class="title">
        {{ $t('detail.select_idm.multiple_idm') }}
      </div>

      <div class="desc">
        {{ description }}
      </div>
    </div>
    <div>
      <el-select
        v-model="selectedIdm"
        placeholder="Select"
        @change="redirect"
      >
        <el-option
          v-for="(campaign, index) in formatedCampaigns"
          :key="index"
          :label="campaign.label"
          :value="campaign.value"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeaderSelectIdm',
    props: {
      campaigns: {
        type: Array,
        default: () => []
      },
      campaignType: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        selectedIdm: parseInt(this.$route.params.campaignId)
      }
    },
    computed: {
      description() {
        let description = this.$t('detail.select_idm.AB_desc')
        if (this.campaignType === 'transactional') {
          description = this.$t('detail.select_idm.transac_desc')
        }
        else if (this.campaignType === 'automatic') {
          description = this.$t('detail.select_idm.auto_desc')
        }
        return description
      },
      formatedCampaigns() {
        if (!this.campaigns.length) {
          return []
        }
        return this.campaigns.map(elem => {
          return {
            value: elem,
            label: elem,
          }
        })
      }
    },
    methods: {
      redirect(campaignId) {
        if (campaignId === parseInt(this.$route.params.campaignId)) {
          return
        }
        this.$emit('change-campaign', campaignId)
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.select-idm {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 21px;
  border-radius: 3px;
  justify-content: space-between;
  background-color: $--color-select-idm;
  color: $--color-white;
  padding: 20px;
  .title {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .desc {
    font-size: 12px;
    white-space: pre-line
  }
}
</style>
