<template>
  <div class="block-param bp-evolution-landing bp-landing">
    <div class="row">
      <h2>{{ $t('detail.evolution.lp') }}</h2>
      <ul class="time_menu">
        <li
          :class="{'currentstep': currentstep === 1}"
          @click="updateStep(1)"
        >
          {{ $t('landing.daily') }}
        </li>
        <li
          :class="{'currentstep': currentstep === 2}"
          @click="updateStep(2)"
        >
          {{ $t('landing.weekly') }}
        </li>
        <li
          :class="{'currentstep': currentstep === 3}"
          @click="updateStep(3)"
        >
          {{ $t('landing.monthly') }}
        </li>
      </ul>
    </div>
    <ChartLine
      :chart-data="bothCharts"
      :options="chartOptions"
    />
    <div class="legende-container">
      <GraphLegend :labels="labels" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ChartLine } from '@/components/Common/Chart'
import { GraphLegend } from '@/components/Common/Graph'

export default {
  name: 'BlockEvolutionLanding',
  components: { ChartLine, GraphLegend },
  props: {
    reactions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      labels: ['opens_lp', 'clicks'],
      // start_date: moment(this.publishdate).startOf('day'),
      // end_date: moment(),
      // start_date: this.first_publish_date,
      endDate: new Date(),
      currentstep: 1,
      // periods: [24, 48, 72],
      // currentPeriod: 24,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    }
  },
  computed: {
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    },
    headerMeta () {
      return this.$store.getters['landingDetail/headerMeta']
    },
    startDate () {
      if (this.headerMeta.first_publish_date) {
        return new Date(this.headerMeta.first_publish_date)
      }
      return new Date()
    },
    bothCharts () {
      if (this.reactions.length === 0) {
        return { labels: [], datasets: [] }
      }
      return {
        labels: this.labelizer(this.reactions),
        datasets: [
          this.customizer(this.reactions[0]), // opens
          this.customizer(this.reactions[1]) // clicks
        ]
      }
    }
  },
  methods: {
    updateStep (step) {
      this.$store.dispatch('landingDetail/getReactions', {
        id: this.headerMeta.id,
        params: {
          step: step,
          start_date: this.startDate,
          end_date: this.endDate
        }
      })
      this.currentstep = step
    },
    filterDates (_data) {
      let _start, _end

      _start = moment(this.startDate).startOf('day')
      _end = moment()
      return _data.filter(item => moment(item.x).isBetween(_start, _end, null, '[]'))
    },
    labelizer (data) {
      return this.filterDates(data[0].data).map(item => 
        this.$options.filters.formatDate(item.x, this.$i18n.locale, this.userTimezone, 'L')
      )
    },
    customizer (curveData) {
      const isOpensLabel = curveData.label === 'opens'

      const _label = isOpensLabel ? this.labels[0] : curveData.label
      const _color = this.$options.filters.colorize(_label)
      const _data = this.filterDates(curveData.data).map(item => item.y)
      return {
        label: `${this.$t('global.' + _label)}`,
        data: _data,
        fill: false,
        borderColor: _color,
        backgroundColor: _color,
        pointBackgroundColor: _color
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.bp-evolution-landing {
  .legende-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .legende {
      ul {
        display: flex;
        li {
          margin: 1rem;
        }
      }
    }
  }
  .time_menu {
    list-style: none;
    padding-left: 0;
    font-size: 12px;
    font-weight: 600;

    li {
      display: inline-block;
      margin-left: 1rem;
      cursor: pointer;
    }

    .currentstep {
      color: $--color-primary;
      cursor: default;
    }
  }
}
</style>
