import LoaderGeneric from './Generic'

export default {
  name: 'LoaderSummary',
  functional: true,
  render (h, { data }) {
    return (
      <LoaderGeneric {...data} height={36} width={350}>
        <rect x="0" y="9" rx="4" ry="4" width="30%" height="9" />
        <rect x="0" y="24" rx="4" ry="4" width="88%" height="9" />
      </LoaderGeneric>
    )
  }
}
