import { intersection as _intersection } from 'lodash-es'

export default {
  methods: {
    urlParams (params, rangeDate = [], defaultTags = []) {
      let queryParams = {}
      for (let key in params) {
        switch (key) {
        case 'start_date':
          queryParams['start_date'] = rangeDate.start
          break
        case 'end_date':
          queryParams['end_date'] = rangeDate.end
          break
        case 'l':
          if (params.l !== 20) { queryParams.l = params.l }
          break
        case 'p':
          if (params.p !== 1) { queryParams.p = params.p }
          break
        case 'tags':
          if (!(_intersection(params.tags.split(',').map((v) => parseInt(v)), defaultTags).length === defaultTags.length &&
            params.tags.split(',').length === defaultTags.length)) {
            queryParams.tags = params.tags
          }
          break
        default:
          queryParams[key] = params[key]
        }
      }
      this.$router.replace({ query: queryParams }).catch(() => {})
    }
  }
}
