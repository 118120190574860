import Vue from 'vue'
import numeral from 'numeral'
import i18n from '../languages/i18n'

Vue.filter('formatNumber', function (value, format = '0,0') {
  const num = Number(value)
  if (isNaN(num)) {
    return value
  }
  if (num % 1 !== 0) {
    if (format) {
      numeral.locale(i18n.locale)
      return numeral(value).format(format)
    } else {
      const intlLocale = i18n.locale === 'en' ? 'en-US' : 'fr-FR'
      const fixedNum = Number(num.toFixed(2))
      return fixedNum.toLocaleString(intlLocale)
    }
  } else {
    if (format) {
      numeral.locale(i18n.locale)
      return numeral(value).format(format)
    }
    return num
  }
})

Vue.filter('formatDate', function (value, locale, tz, format) {
  moment.locale(locale)

  // moment requires format to be in uppercase (example: 'YYYY/MM/DD' and not 'yyyy/MM/dd')
  let momentFormat = format ? format.toUpperCase() : 'L'
  return window.moment(value).tz(tz).format(momentFormat)
})

Vue.filter('formatTime', function (value, locale, tz) {
  window.moment.locale(locale)
  // LT: HH:mm
  let momentFormat = 'LT'
  return window.moment(value).tz(tz).format(momentFormat)
})

Vue.filter('round2dec', function (value) {
  const num = Number(value)
  if (isNaN(num)) {
    return value
  }
  if (num % 1 !== 0) {
    const intlLocale = i18n.locale === 'en' ? 'en-US' : 'fr-FR'
    const fixedNum = Number(num.toFixed(2))
    return fixedNum.toLocaleString(intlLocale)
  } else {
    return num
  }
})

Vue.filter('truncate', function (value, limit) {
  return value.length > limit ? value.substring(0, limit - 1) + '…' : value
})

Vue.filter('capitalize', function (str) {
  return str.replace(/^\w/, c => c.toUpperCase())
})

Vue.filter('camelize', function (str) {
  return str.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
})

Vue.filter('unnull', function (val) {
  return val === null ? '-' : val
})

Vue.filter('colorize', function (val) {
  const param = val.toLowerCase()
  /* Datalively colors:
  'color-1st : '#228786',
  'color-2nd': '#153745',
  'color-3rd': '#8bc500',
  'color-4th': '#1cca70',
  'color-5th': '#573879',
  'color-6th': '#c42a65',
  'color-7th': '#fa7476',
  'color-8th': '#fc9c4e',
  'color-9th': '#fac24a',
  'color-10th': '#c6dc4b',
  'color-11th': '#BCAD8E',
  'color-12th': '#3e516f',
  'color-13th': '#5ED0DB',
  'color-14th': '#3EAE82',
  */
  switch (param) {
    case 'fixe':
    case 'clicks':
    case 'desktop':
    case 'conversions':
    case 'revenues':
    case 'unique_opens':
    case 'email':
    case 'emails':
    case 'click': // conversion id 1
      return '#228786'

    case 'mobile':
    case 'opens':
    case 'scans':
    case 'opens_lp':
    case 'sms':
    case 'online_order': // conversion id 2
      return '#153745'
      
    case 'push':
    case 'pushs':
    case 'ind\u00E9termin\u00E9 / gmail':
    case 'indéterminé / gmail':
    case 'indetermine / gmail':
    case 'indéterminé':
    case 'indetermine':
    case 'undefined_gmail':
    case 'gmail':
    case 'Other':
    case 'other':
    case 'offline_order': // conversion id 3
      return '#8bc500'

    // Conversion specific (Legend component)
    case 'visit': // conversion id 4
      return '#1cca70'    
    case 'engaged_visit': // conversion id 5
      return '#573879'
    case 'appointment_request': // conversion id 6
      return '#c42a65'
    case 'completed_form': // conversion id 7
      return '#fa7476'
    case 'outlet': // conversion id 8
      return '#fc9c4e'
    case 'online_no_coupon': // conversion id 2000
      return '#FAC24A'
    case 'online_with_coupon': // conversion id 2001
      return '#C6DC4B'
    case 'offline_no_coupon': // conversion id 3000
      return '#BCAD8E'
    case 'offline_with_coupon': // conversion id 3001
      return '#3E516F'
    default:
      return '#D5DDE3'
  }
})

Vue.filter('autocolorize', function (index) {
  const colors = ['#228786', '#153745', '#8bc500', '#1cca70', '#573879', '#c42a65', '#fa7476', '#fc9c4e', '#fac24a', '#c6dc4b', '#BCAD8E', '#3e516f', '#5ED0DB', '#3EAE82']
  return colors[index]
})

Vue.filter('initials', function (name) {
  if (!name) {
    return ''
  }
  let initials = name.match(/\b\w/g) || []
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  return initials
})
