<template>
  <section
    v-loading="loading"
    class="detail-page"
    :class="{'reports-loaded': reportId}"
  >
    <header class="page-header">
      <div class="row">
        <Breadcrumb :items="breadcrumbs" />
        <GroupSelector
          v-if="report.has_group_filter"
          @updated="applyGroupFilter"
        />
      </div>
    </header>

    <main class="pbi_container">
      <EmbeddedReport
        v-if="report && embedInfo.embed_url"
        ref="embeddedReport"
        :embedded="isEmbedded"
        :report-id="report.pbi_report_id"
        :embed-url="embedInfo.embed_url"
        :embed-token="embedInfo.token"
        :group-filter="report.has_group_filter || false"
      />
    </main>
  </section>
</template>

<script>
import config from '@/config'
import { EmbeddedReport } from '@/components/Parts/Reports'
import Breadcrumb from '@/components/Common/Breadcrumb'
import { GroupSelector } from '@/components/Common/Form'

export default {
  name: 'ViewsReportsDetail',
  components: {
    EmbeddedReport,
    Breadcrumb,
    GroupSelector
  },
  data () {
    return {
      refreshTokenInterval: null,
      refreshTokenIntervalSeconds: 10,
      minutesBeforeTokenExpiration: 5,
    }
  },
  computed: {
    reportId () {
      return this.$route.params.reportId
    },
    loading () {
      return this.$store.getters['reports/loading'] || this.$store.getters['reports/reportLoading']
    },
    report () {
      return this.$store.getters['reports/currentReport'] || {}
    },
    embedInfo () {
      return this.$store.getters['reports/embedInfo'] || {}
    },
    isEmbedded () {
      return this.report && this.report.type_id === config.REPORT_TYPES.POWERBI_EMBEDDED
    },
    breadcrumbs () {
      return [
        { state: 'reports-list', name: this.$t('labels.reports') },
        { state: null, name: this.report.name },
      ]
    }
  },
  async created () {
    this.$store.dispatch('app/setFullWidth', true)
    this.$store.dispatch('reports/embed', this.reportId)
    // getReport has to be awaited as we need to know if the report is embedded
    await this.$store.dispatch('reports/getReport', { reportId: this.reportId })
    if (this.isEmbedded) {
      this.refreshTokenInterval = setInterval(this.refreshToken, this.refreshTokenIntervalSeconds * 1000)
    }
  },
  beforeDestroy () {
    this.$store.dispatch('app/setFullWidth', false)
    if (this.refreshTokenInterval) {
      clearInterval(this.refreshTokenInterval)
    }
  },
  methods: {
    applyGroupFilter () {
      this.$refs.embeddedReport.applyGroupFilter()
    },
    async refreshToken () {
      const currentTime = Date.now()
      const expiration = Date.parse(this.embedInfo.token_expiration)
      // time until token expiration in milliseconds
      const timeUntilExpiration = expiration - currentTime
      const timeToUpdate = this.minutesBeforeTokenExpiration * 60 * 1000
      // update the token if it is about to expired
      if (timeUntilExpiration <= timeToUpdate)
      {
        await this.$store.dispatch('reports/refreshToken', this.reportId)
        this.$refs.embeddedReport.setToken(this.embedInfo.token)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.detail-page {
  height: calc(100vh - 48px)!important;
  width: 100%;
  max-width: $--max-width;
  margin: 0;
  padding: 0 50px;
  display: flex;
  &.reports-loaded {
    max-width: inherit !important;
  }
  flex-direction: column;

  .page-header {
    width: 100%;
    margin: auto;
    

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3rem;
      @media only screen and (max-width: $--mobile-width) {
        flex-direction: column;
        .group-selector {
          margin-top: 20px;
        }
      }
      
      // Match group-selector height
      .el-breadcrumb{
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }
  

  .pbi_container {
    flex-grow: 1;
  }
}
</style>
