<template>
  <section class="overview-section">
    <div class="col-left">
      <template v-for="(kpisValue, kpisKey) in overview">
        <component
          :is="components_map[kpisKey].name"
          v-if="isKpi(kpisKey)"
          :key="kpisKey"
          :option="components_map[kpisKey].option"
          :kpiskey="kpisKey"
          :kpisvalue="kpisValue"
          :kpis="kpis"
          :channel="'landing'"
        />
      </template>
      <BlockEvolutionLanding
        v-if="reactions"
        :reactions="reactions"
      />
      <BlockGraphLanding v-if="devices" />
    </div>

    <div class="col-right">
      <div class="switch">
        <el-radio-group
          v-model="preview"
          size="medium"
        >
          <el-radio-button label="mobile">
            <i class="material-icons md-16">phone_iphone</i>
          </el-radio-button>
          <el-radio-button label="desktop">
            <i class="material-icons md-16">desktop_windows</i>
          </el-radio-button>
        </el-radio-group>
      </div>
      <div
        v-if="preview === 'mobile'"
        class="preview mobile-emulator"
      >
        <div class="screen">
          <div
            v-if="contentPreviews && contentPreviews.mobile_url"
            class="content"
          >
            <img
              :src="contentPreviews.mobile_url"
              alt="Mobile preview image"
            >
          </div>
          <div
            v-else
            v-loading="previewLoading"
            class="content-no-data"
          />
        </div>
      </div>
      <div
        v-if="preview === 'desktop'"
        class="preview desktop-emulator"
      >
        <div
          v-if="contentPreviews && contentPreviews.desktop_url"
          class="content"
        >
          <img
            :src="contentPreviews.desktop_url"
            alt="Desktop preview image"
          >
        </div>
        <div
          v-else
          v-loading="previewLoading"
          class="content-no-data"
        />
      </div>
    </div>
  </section>
</template>

<script>
import {
  BlockInLanding,
  BlockMultiTraffic,
  BlockGraphLanding,
  BlockEvolutionLanding
} from '@/components/Common/Block'

export default {
  name: 'PartsOverviewLanding',
  components: { BlockInLanding, BlockMultiTraffic, BlockGraphLanding, BlockEvolutionLanding },
  data () {
    return {
      components_map: {
        performances: { name: 'BlockInLanding', option: null },
        campaign_trafic_sources: { name: 'BlockMultiTraffic', option: null }
      },
      kpis: {
        sortOrder: {
          performances: [
            'total_visits',
            'total_clicks',
            'total_submissions'
          ],
          campaign_trafic_sources: [
            'campaign_name',
            'total_visits',
            'total_clicks',
            'total_submissions'
          ]
        },
        important: [
          'total_visits',
          'total_clicks'
        ],
        name: {
          total_visits: 'total_visits',
          total_clicks: 'total_clicks',
          total_submissions: 'total_submissions'
        }
      },
      notKpi: [
        'other_trafic_sources'
      ],
      preview: 'mobile'
    }
  },
  computed: {
    landingId () {
      return this.$route.params.landingId
    },
    /**
     * Generate sorted overview and update kpis format
     */
    overview () {
      let orderedOverview = {}
      for (const key in this.$store.getters['landingDetail/overview']) {
        if (!this.kpis.sortOrder[key]) {
          orderedOverview[key] = this.$store.getters['landingDetail/overview'][key]
          continue
        }

        orderedOverview[key] = this.kpis.sortOrder[key]
          .map(kpiKey => {
            return {
              key: kpiKey,
              name: this.kpis.name[kpiKey] || null,
              value: this.$store.getters['landingDetail/overview'][key][kpiKey]
            }
          })

      }

      return orderedOverview
    },
    raw () {
      return this.$store.getters['landingDetail/overview']
    },
    reactions () {
      return this.$store.getters['landingDetail/reactions']
    },
    devices () {
      return this.$store.getters['landingDetail/devices']
    },
    contentPreviews () {
      return this.$store.getters['landingDetail/previews']
    },
    isLoading () {
      return this.$store.getters['landingDetail/loading']
    },
    previewLoading () {
      return this.$store.getters['landingDetail/previewLoading']
    }
  },
  methods: {
    /**
     * Check if they key is a KPI
     * @param {String} key (kpi key)
     * @return {Boolean}
     */
    isKpi(key) {
      return !this.notKpi.includes(key)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.overview-section {
  display: flex;
  padding-bottom: 3rem;

  @media only screen and (max-width: $--mobile-width) {
    flex-direction: column;
  }
  .col-left {
    width: 680px;
    margin-top: 56px;
    @media only screen and (max-width: $--mobile-width) {
      width: 100%;
      flex: 1;
    }
  }
  .col-right {
    // flex: 1;
    width: 520px;
    .switch {
      text-align: center;
      margin-bottom: 1.2rem;
    }
    .preview {
      margin: auto;
    }
    .mobile-emulator {
      background: url("~@/assets/svg/emails/preview/frame.svg") no-repeat center center;
      width: 400px;
      height: 800px;
      display: flex;
      justify-content: center;
      align-items: center;
      .screen {
        position: relative;
        width: 320px;
        min-height: 630px;
        max-height: 630px;
        height: 630px;
        background-color: #fff;
        border: 1px solid #e9e9e9;
        top: 2px;

        .content {
          width: 100%;
          height: 100%;
          overflow-y: scroll;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .desktop-emulator {
      position: relative;
      width: 520px;
      min-height: 630px;
      background-color: #d5dde3;
      border: solid 1px #dcdfe6;
      .content {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .content-no-data {
      display: flex;
      height: 630px;
      width: 100%;
      justify-content: center;
      align-items: center;
      span {
        width: 80%;
        word-wrap: break-word;
      }
    }
    @media only screen and (max-width: $--mobile-width) {
      width: 100%;
      .mobile-emulator,
      .desktop-emulator {
        width: 100%;
      }
    }
  }
}
</style>
