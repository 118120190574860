import Vue from 'vue'

const SET_CLIENTS = 'SET_CLIENTS'
const SET_CLIENT = 'SET_CLIENT'

const moduleClients = {
  namespaced: true,
  state: {
    clients: [],
    client: {}
  },
  mutations: {
    [SET_CLIENTS] (state, clients) {
      Vue.set(state, 'clients', clients)
    },
    [SET_CLIENT] (state, client) {
      Vue.set(state, 'client', client)
    },
  },
  actions: {
    setClient({commit}, client) {
      commit(SET_CLIENT, client)
    },
    getClients ({ commit }) {
        const url = '/gate/clients'
        return new Promise((resolve, reject) => {
          Vue.axios.get(url)
            .then(response => {
              if (response.status === 200) {
                commit(SET_CLIENTS, response.data)
                resolve(response)
              } else {
                reject(response)
              }
            })
            .catch(error => {
              reject(error.response ? error.response : error)
            })
        })
    }
  },
  getters: {
    clients: state => state.clients,
    client: state => state.client
  }
}

export default moduleClients
