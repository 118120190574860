import * as Sentry from '@sentry/vue'

import store from '@/store'
import i18n from '@/languages/i18n'

const authGuard = async function (to, from, next) {
  // work-around to get to the Vuex store (as of Vue 2.0)
  const user = store.getters['session/user']
  if (user) { next() }
  else {
    await store.dispatch('session/getProfile')
    .then(res => {
      if (res.status === 401 || res.status === 403) {
        const redirect = res.status === 401 ? '/login' : '/logout'
        store.dispatch('session/clearSession')
        window.location = redirect
      } else {
        if (res.data.lang) {
          i18n.locale = res.data.lang
        }
        const profileQueries = [ // NOTE(@cky): Order matter !
          store.dispatch('session/getGroups'),
        ]
        Promise.all(profileQueries.map(p => p.catch(e => e))).then(res => {
          const errors = res.filter(result => result.status && result.status >= 300)
          if (errors.length) {
            return handleError(errors[0])
          }

          const queryString = window.location.search
          const urlParams = new URLSearchParams(queryString)
          const groupIdsInUrl = urlParams.get('group_ids')
          const availableGroups = res[0].data.objects
          if (availableGroups.length === 1) {
            // If only 1 group available, select it as default
            store.dispatch('app/selectGroups',[availableGroups[0]])
          } else if (groupIdsInUrl) {
            // If group ids in url, add them as select
            const groupIds = groupIdsInUrl.split(',')
            store.dispatch('app/selectGroups', availableGroups.filter(g => groupIds.includes(g.id)))
          }

          if (store.getters['session/hasCRMDataBase']) {
            const crmQueries = [ // NOTE(@cky): Order matter !
              store.dispatch('tag/getTags', {
                family: null,
                queryString: null
              }),
              store.dispatch('conversions/getConversionTypes')
            ]
            Promise.all(crmQueries.map(p => p.catch(e => e))).then(resp => {
              const errors = resp.filter(result => result.status && result.status >= 300)
              if (errors.length) {
                return handleError(errors[0])
              }
            })
          }

          const clientQuery = [
            store.dispatch('clients/getClients'),
          ]
          Promise.all(clientQuery).finally(
            () => document.body.setAttribute("loaded", 1)
          )
          next()
        })
      }
    })
  }
}

const handleError = function (res) {
  if (res.status === 401 || res.status === 403) {
    const redirect = res.status === 401 ? '/login' : '/logout'
    store.dispatch('session/clearSession')
    window.location = redirect
  } else {
    Sentry.captureException(res)
    window.location = '/502.html'
  }
}

export default authGuard
