<template>
  <el-select
    v-model="currentGroup"
    class="group-selector"
    placeholder="Select"
    :disabled="lockSelector"
    value-key="name"
    filterable
  >
    <el-option
      :label="$t('global.all_group')"
      :value="{ name: $t('global.all_group') }"
    />

    <el-option
      v-for="(group, index) in orderedGroups"
      :key="'grp_' + index"
      :label="group.name"
      :value="group"
    />

    <!-- NO DATA -->
    <template slot="empty">
      <div style="padding: 10px 15px; opacity: 0.5;">
        {{ $t( 'global.no_matching_data' ) }}
      </div>
    </template>
  </el-select>
</template>

<script>
import { orderBy as _orderBy } from 'lodash-es'

export default {
  name: 'GroupSelector',
  computed: {
    groups () {
      return this.$store.getters['session/groups']
    },
    lockSelector () {
      return this.groups.length === 1
    },
    selectedGroup () {
      const groups = this.$store.getters['app/selectedGroups']
      return groups && groups.length ? groups[0] : null
    },
    orderedGroups () {
      if (!this.groups) { return [] }
      return _orderBy(this.groups, [group => group.name.toLowerCase()])
    },
    currentGroup: {
      get () {
        return this.selectedGroup
          ? this.selectedGroup
          : { name: this.$t('global.all_group'), id: null, logo_url: null }
      },
      set (group) {
        this.$store.dispatch('app/selectGroups', group.id ? [group] : [])
        this.$emit('updated')
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.group-selector {
  width: $--button-min-width;
}
</style>
