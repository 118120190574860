<template>
  <section class="detail-page landing_detail">
    <HeaderLandingDetail
      :contactly-url="contactlyUrl"
      :landing-id="landingId"
      :header-meta="headerMeta"
      :download-loading="downloadLoading"
      @range-date-updated="rangeDateUpdated"
      @generate-export="generateExport"
    />
    <el-tabs
      :value="$route.name"
      :stretch="true"
      @tab-click="handleClickTabs"
    >
      <el-tab-pane name="landing-detail-overview">
        <span slot="label">
          <i class="material-icons md-18">visibility</i>
          {{ $t(`detail.overview`) }}
        </span>
      </el-tab-pane>
      <el-tab-pane name="landing-detail-click-map">
        <span slot="label">
          <i class="material-icons md-18">link</i>
          {{ $t('detail.click_map') }}
        </span>
      </el-tab-pane>
    </el-tabs>
    <router-view class="detail-tabs-view" />
  </section>
</template>

<script>
import { HeaderLandingDetail } from '@/components/Parts/Header'
import { isoDateString } from '@/helpers/isoDate'
import { urlParams } from '@/mixins'

export default {
  name: 'ViewsLandingDetail',
  components: {
    HeaderLandingDetail,
  },
  mixins: [ urlParams ],
  computed: {
    landingId () {
      return this.$route.params.landingId
    },
    startDate () {
      return this.$store.getters['landingDetail/startDate']
    },
    endDate () {
      return this.$store.getters['landingDetail/endDate']
    },
    rangeDateRaw () {
      return {
        start: this.startDate,
        end: this.endDate
      }
    },
    rangeDate () {
      const tz = this.$store.getters['session/user'].timezone || 'UTC'

      return {
        start: isoDateString(this.startDate, tz),
        end: isoDateString(this.endDate, tz, true),
      }
    },
    headerMeta () {
      return this.$store.getters['landingDetail/headerMeta']
    },
    overview () {
      return this.$store.getters['landingDetail/overview'] || {}
    },
    performances () {
      return this.overview.performances || {}
    },
    nbSubmissions () {
      return this.performances.total_submissions
    },
    contactlyUrl () {
      return this.$store.getters['landingDetail/contactlyUrl']
    },
    downloadLoading () {
      return this.$store.getters['landingDetail/downloadLoading']
    }
  },
  async created () {
    // Load campaign informations
    this.$store.dispatch('landingDetail/reset')
    const response = await this.$store.dispatch('landingDetail/getHeaderMeta', this.landingId)
    if (response.status != 200) {
      if (response.status >= 500) {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      } else {
        this.$message({
          showClose: true,
          center: true,
          duration: 0,
          message: this.$t('global.alert_no_campaign'),
        })
      }
      this.$router.push({ name: 'home' })
      return
    }

    // Load params from url
    for (let key in this.$route.query) {
      switch (key) {
        case 'start_date':
          this.$store.dispatch('landingDetail/setStartDate', this.$route.query[key])
          break
        case 'end_date':
          this.$store.dispatch('landingDetail/setEndDate', this.$route.query[key])
          break
      }
    }
    this.$store.dispatch('landingDetail/getPreviews', this.landingId)
    this.loadData()
  },
  methods: {
    async loadData () {
      const id = this.landingId
      const channel = 'landing_pages'
      const params = {
        start_date: this.rangeDate.start,
        end_date: this.rangeDate.end
      }
      // Update url first prior to retrieve data
      this.urlParams(params, this.rangeDateRaw)

      this.$store.dispatch('landingDetail/getOverview', { id, params })
      this.$store.dispatch('landingDetail/getReactions', { id, params })
      this.$store.dispatch('landingDetail/getDevices', { id, params })

      if (this.headerMeta.list_uid) {
        this.$store.dispatch('landingDetail/getContactlyURL', { id, params })
      }
      this.$store.dispatch('map/getStatsClickMap', { id, channel, params })
    },
    generateExport () {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate
      }
      this.$store.dispatch('landingDetail/exportLanding', {
        id: this.landingId,
        params: params
      })
    },
    rangeDateUpdated () {
      this.loadData()
    },
    handleClickTabs (tab) {
      if (tab.name === this.$route.name) {
        return
      }
      this.$router.push({
        name: tab.name,
        params: { landingId: this.landingId },
        query: {
          start_date: this.rangeDateRaw.start,
          end_date: this.rangeDateRaw.end
        }
      })
    }
  }
}
</script>

<style lang="scss">
.landing_detail {
  position: relative;
  .el-tabs__item {
    font-size: 16px;
    i {
      line-height: 2.3;
      height: 16px;
      vertical-align: top;
      display: inline-block;
    }
  }
}
</style>
