import Vue from 'vue'

import APIException from './exceptions'

const moduleDataExports = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getDataExports(_, params) {
      try {
        const response = await Vue.axios.get("/api/data_exports", {
          params: params
        })
        if (response.status >= 300) {
          throw response
        }
        return response
      } catch (err) {
        throw new APIException(err)
      }
    },
    async getDataExportPresignedUrl(_, exportUid) {
      try {
        const params = {"export_uid": exportUid}
        const response = await Vue.axios.get("/api/data_exports/presigned_url", {params: params})
        if (response.status >= 300) {
          throw response
        }
        return response
      } catch (err) {
        throw new APIException(err)
      }
    },
    async createDataExport(_, data) {
      try {
        const response = await Vue.axios.post("/api/data_exports", data)
        if (response.status >= 300) {
          throw response
        }
        return response
      } catch (err) {
        throw new APIException(err)
      }
    }
  },
  getters: {}
}

export default moduleDataExports
