import LoaderGeneric from './Generic'

export default {
  name: 'LoaderListing',
  functional: true,
  render (h, { data }) {
    return (
      <LoaderGeneric {...data} height={40} width={755}>
        <circle cx="15" cy="20" r="10" />

        <rect x="35" y="10" rx="4" ry="4" width="100%" height="20" />
      </LoaderGeneric>
    )
  }
}
