<template>
  <el-dialog
    class="countries-detail-modal"
    :title="$t('kpi.sms.country_detail')"
    :visible="showCountriesModal"
    width="584px"
    :append-to-body="true"
    @close="triggerClose"
  >
    <el-table
      :data="smsCountriesDetails"
      stripe
    >
      <el-table-column
        prop="country_code"
        :label="$t('kpi.sms.country')"
        width="180"
      >
        <template slot-scope="scopeco">
          <span v-if="scopeco.row.country_code != -1">
            <country-flag
              :country="scopeco.row.country_code"
              class="aligned_with_text"
              size="small"
            />
            {{ displaysLocalCountryName(scopeco.row.country_code) }}
          </span>
          <span v-else>
            {{ $t('kpi.sms.anonymous') }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="sms_sent"
        :label="$t('kpi.sms.sms_sent_head')"
        width="180"
      />
      <el-table-column
        prop="unit_sms_billed"
        :label="$t('kpi.sms.sms_billed_head')"
      />
    </el-table>
  </el-dialog>
</template>
<script>
import CountryFlag from 'vue-country-flag'
export default {
  name: 'ModalCountriesDetail',
  components: {
    CountryFlag,
  },
  props: {
    smsCountriesDetails: {
      type: Array,
      default: (() => []),
    },
    showCountriesModal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    triggerClose() {
        this.$emit('update:showCountriesModal', false)
    },
    displaysLocalCountryName(countryCode) {
      const localeCountryName = new Intl.DisplayNames([this.$i18n.locale], { type: 'region' });
      try {
        return localeCountryName.of(countryCode)
      } catch (error) {
        return null
      }
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/styles/_variables.scss';

// override default position to align with text
.flag.small-flag.aligned_with_text {
      margin: -0.9em -1.2em -1.0em -1.2em;
}
// Center modal & flex modal height between 350 & 550px
.countries-detail-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-dialog{
    margin: 0 !important;
    min-height: 350px;
    .el-table__body-wrapper.is-scrolling-none {
      max-height: 320px;
      overflow-y: auto;
    }
  }

  // Disable default hover style on table rows
  tr.el-table__row:hover{
    td.el-table__cell {
      background-color: inherit;
    }
  }
  tr.el-table__row--striped.el-table__row:hover {
    td.el-table__cell {
      background-color: $--main-bg;
    }
  }

}
</style>
