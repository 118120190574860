const config = {
  EMAIL_CHANNEL_TAGS: {
    one_shot: 2,
    automatic: 1,
    transactional: 5
  },
  SMS_CHANNEL_TAGS: {
    one_shot: 7004,
    automatic: 7003,
    transactional: 7005
  },
  PUSH_CHANNEL_TAGS: {
    one_shot: 6997,
    automatic: 6998,
    transactional: 6996
  },
  LANDING: {
    published: true,
    unpublished: true
  },
  REPORT_TYPES: {
    SSRS: 1,
    POWERBI_EMBEDDED: 2
  },
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  CHANNELS: {
    EMAIL: 1,
    SMS: 2,
    PUSH_NOTIFICATION: 3
  },
  DEVICE_LABELS: ["pc", "mac", "iphone", "android-smartphone", "other", "unknown_device"],
  PLATFORM_LABELS: ["desktop", "mobile", "apple-mpp", "gmail-proxy", "yahoo-proxy", "unknown", ],
  MAX_SPLIT_DISPLAY: 15,
  CONVERSION_TYPES: {
    CLICK: 1,
    ONLINE_ORDER: 2,
    OFFLINE_ORDER: 3,
    VISIT: 4,
    ENGAGED_VISIT: 5,
    APPOINTMENT_REQUEST: 6,
    COMPLETED_FORM: 7,
    OUTLET: 8,
    ONLINE_NO_COUPON: 2000,
    ONLINE_WITH_COUPON: 2001,
    OFFLINE_NO_COUPON: 3000,
    OFFLINE_WITH_COUPON: 3001
  },

  SWITCH_VIEW:{
    APPS : 'apps',
    CLIENT: 'clients'
  }
}

export default config
