<template>
  <div class="auto-legende">
    <ul :position="position">
      <li
        v-for="(label, index) in labels"
        :key="index"
      >
        <span
          class="casecolor"
          :style="'background-color:' + $options.filters.autocolorize(index) + ';'"
        />
        {{ label.label }}
        <el-tooltip
          v-if="tooltips"
          class="item"
          effect="dark"
          placement="top"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            slot="content"
            v-html="label.tooltip"
          />
          <!--eslint-enable-->
          <i class="material-icons md-12 help">help_outline</i>
        </el-tooltip>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'GraphAutoLegend',
  props: {
    labels: {
      type: Array,
      default: () => []
    },
    tooltips: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: "right"
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
.auto-legende {
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    list-style: none;
    padding-left: 0px;
    li {
      line-height: 20px;
      height: 20px;
      vertical-align: middle;
      margin-bottom: 1rem;
    }
  }

  ul[position="bottom"] {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left:4rem;
    margin-right:4rem;
    justify-content: space-evenly;

    li {
      margin-right: 1rem;
      .casecolor {
        margin-right: 5px;
      }
    }
  }
  .help {
    color: $--text-light;
    height: 20px;
    line-height: 20px;
    vertical-align: middle;
  }
  .casecolor {
    vertical-align: text-bottom;
    margin-right: 10px;
    display: inline-block;
    border-radius: 2px;
    width: 16px;
    height: 16px;
  }
}
</style>
