<template>
  <div class="table-push">
    <div class="display-all-kpis">
      <el-switch
        :value="displayAllKpis"
        @change="updateDisplay"
      />
      <span class="label">{{ $t('global.display_all_kpis') }}</span>
    </div>

    <el-table
      id="table"
      ref="table"
      :data="displayedData"
      :span-method="spanMethod"
      :default-sort="{ prop: 'sending_date', order: 'descending' }"
      :tree-props="{children: 'idms'}"
      stripe
      row-key="idd"
      header-cell-class-name="table-header"
      style="width: 100%"
      @sort-change="orderBy"
      @row-click="rowClick"
    >
      <!-- NO DATA -->
      <div slot="empty">
        <div class="empty-table">
          <img
            class="empty-table__puddle"
            :src="require('../../../assets/svg/patate.svg')"
            alt="Empty background"
          >
          <img
            class="empty-table__box"
            :src="require('../../../assets/svg/pictos/box.svg')"
            alt="Empty foreground"
          >
          <span class="empty-table__text">
            {{ emptyLabel }}
          </span>
        </div>
      </div>

      <!-- GROUP OR FIRST ROW-->
      <el-table-column
        width="90"
        prop="group"
        :fixed="true"
        :label="$t('table.header.group')"
      >
        <template slot-scope="scopeg">
          <!-- TOTAL ROW -->
          <template v-if="scopeg.$index === 0">
            <div class="total">
              <p>
                {{ $t('table.content.total') }}
              </p>
              <p class="total-description">
                {{ $tc('table.content.total_description', total, {total: total, channel: "push"}) }}
              </p>
            </div>
          </template>
          <!-- GROUP -->
          <template v-else>
            <span
              v-if="scopeg.row.group"
              class="pictify"
            >
              <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                :content="scopeg.row.group"
              >
                <avatar-holder
                  :avatar-url="logifier(scopeg.row.group)"
                  :display-name="scopeg.row.group"
                  :width="27"
                  :height="27"
                />
              </el-tooltip>
            </span>
            <span v-else>
              <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                :content="$t('table.content.no_group')"
              >
                <span class="pictify-0" />
              </el-tooltip>
            </span>
          </template>
        </template>
      </el-table-column>
      <!-- ID -->
      <el-table-column
        prop="id"
        width="90"
        :fixed="true"
        :label="$t('table.header.idm')"
      >
        <template slot-scope="scopeidm">
          <p>{{ scopeidm.row.id ? scopeidm.row.id : $t('table.content.multiples') }}</p>
        </template>
      </el-table-column>


      <!-- TYPE -->
      <el-table-column
        prop="campaign_type"
        :fixed="true"
        width="36"
      >
        <template slot-scope="scopep">
          <el-tooltip
            v-if="isOneShot(scopep.row)"
            :content="$t('global.one_shot_campaign')"
            placement="top"
          >
            <i
              class="material-icons md-16 narrow-width"
              :aria-describedat="$t('global.one_shot_campaign')"
            >
              send_outline
            </i>
          </el-tooltip>
          <!-- AUTOMATIC_EMAIL = 1 (automatique) -->
          <el-tooltip
            v-else-if="isAutomatic(scopep.row)"
            :content="$t('global.automatic_campaign')"
            placement="top"
          >
            <i
              class="material-icons md-16 narrow-width"
              :aria-describedat="$t('global.automatic_campaign')"
            >
              cached_outline
            </i>
          </el-tooltip>
        </template>
      </el-table-column>

      <!-- NAME -->
      <el-table-column
        width="220"
        prop="campaign_name"
        :fixed="true"
        :label="$t('table.header.campaign')"
      >
        <template slot-scope="scopec">
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            :content="scopec.row.campaign_name"
          >
            <strong>{{ scopec.row.campaign_name | truncate(24) }}</strong>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
        v-for="(kpi, index) in displayedKpis"
        :key="index"
        :width="displayAllKpis ? 100 : null"
        align="right"
        :sortable="kpi.sortable ? 'custom' : false"
        :label="kpi.label"
        :prop="kpi.prop"
        :formatter="kpi.formatter"
      />
    </el-table>
  </div>
</template>

<script>
import AvatarHolder from '@/components/Common/AvatarHolder'
import { logifier } from '@/mixins'
import config from '@/config'

export default {
  name: 'ListPush',
  components: {
    AvatarHolder
  },
  mixins: [logifier],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    emptyLabel: {
      type: String,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    displayAllKpis: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      kpis: [
        {
          prop: "os_name",
          formatter: null,
          label: this.$t('table.header.os_name'),
          display: true,
          sortable: true
        },
        {
          prop: "sending_date",
          formatter: this.formatDate,
          label: this.$t('table.header.send_date'),
          display: true,
          sortable: true
        },
        {
          prop: "pn_sent",
          formatter: this.formatNumber,
          label: this.$t('table.header.sent'),
          display: true,
          sortable: true
        },
        {
          prop: "delivered",
          formatter: this.formatNumber,
          label: this.$t('kpi.push.delivered'),
          display: this.displayAllKpis,
          sortable: true
        },
        {
          prop: "delivery_rate",
          formatter: this.formatPercentage,
          label: this.$t('table.header.delivery_rate_push'),
          display: true,
          sortable: true
        },
        {
          prop: "undelivered",
          formatter: this.formatNumber,
          label: this.$t('kpi.push.undelivered'),
          display: this.displayAllKpis,
          sortable: true
        },
        {
          prop: "hard_bounces",
          formatter: this.formatNumber,
          label: this.$t('kpi.push.hard_bounces'),
          display: this.displayAllKpis,
          sortable: true
        },
        {
          prop: "soft_bounces",
          formatter: this.formatNumber,
          label: this.$t('kpi.push.soft_bounces'),
          display: this.displayAllKpis,
          sortable: true
        },
        {
          prop: "in_progress",
          formatter: this.formatNumber,
          label: this.$t('kpi.push.in_progress'),
          display: this.displayAllKpis,
          sortable: true
        },
        {
          prop: "unique_opens",
          formatter: this.formatNumber,
          label: this.$t('kpi.push.unique_opens'),
          display: this.displayAllKpis,
          sortable: true
        },
        {
          prop: "open_rate",
          formatter: this.formatPercentage,
          label: this.$t('table.header.open_rate'),
          display: true,
          sortable: true
        },
        {
          prop: "total_conversions",
          formatter: this.formatNumber,
          label: this.$t('kpi.push.total_conversions'),
          display: this.displayAllKpis,
          sortable: false
        },
        {
          prop: "total_revenue",
          formatter: this.formatCurrency,
          label: this.$t('kpi.push.total_revenue'),
          display: this.displayAllKpis,
          sortable: false
        },
        {
          prop: "conversion_rate",
          formatter: this.formatPercentage,
          label: this.$t('kpi.push.conversion_rate'),
          display: this.displayAllKpis,
          sortable: false
        },
        {
          prop: "average_revenue_per_send",
          formatter: this.formatCurrency,
          label: this.$t('kpi.push.average_revenue_per_send'),
          display: this.displayAllKpis,
          sortable: false
        },
        {
          prop: "average_basket",
          formatter: this.formatCurrency,
          label: this.$t('kpi.push.average_basket'),
          display: this.displayAllKpis,
          sortable: false
        },
        {
          prop: "articles",
          formatter: this.formatNumber,
          label: this.$t('kpi.push.articles'),
          display: this.displayAllKpis,
          sortable: false
        },
        {
          prop: "buyers",
          formatter: this.formatNumber,
          label: this.$t('kpi.push.buyers'),
          display: this.displayAllKpis,
          sortable: false
        },
      ]
    }
  },
  computed: {
    userTimezone() {
      return this.$store.getters['session/user'].timezone || 'UTC'
    },
    displayedKpis () {
      return this.kpis.filter(kpi => kpi.display || this.displayAllKpis)
    },
    displayedData () {
      // we set an idd(id display) for each element of data and each children of an element
      // because el-table needs a `row-key` (https://element.eleme.io/#/en-US/component/table#tree-data-and-lazy-mode)
      // to display parents and childrens (The key can't have the same value between a parent and its child)
      // We are also avoiding mutations on the original objects

      const array = this.data.map((datum, index) => {
        const tmp = {...datum}
        if (tmp.idms) {
          tmp.idms = tmp.idms.map(dat => ({...dat, idd: dat.id}))
        }
        return { ...tmp, idd: index + 1 }
      })
      array.unshift({
        idd: 0,
        ...this.totalKpis()
      })
      return array
    },
    clientCurrency() {
      return this.$store.getters['session/client'].currency
    }
  },
  methods: {
    spanMethod({ rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        if (columnIndex === 0) {
          return [1, 5];
        } else if (columnIndex > 0 && columnIndex < 5) {
          return [0, 0];
        }
      }
    },
    isOneShot (row) {
      return row && row.campaign_type === config.PUSH_CHANNEL_TAGS.one_shot
    },
    isAutomatic (row) {
      return row && row.campaign_type === config.PUSH_CHANNEL_TAGS.automatic
    },
    rowClick(row, _, event) {
      // if the row is the total one, do nothing
      if (row.idd === 0) {
        return
      }
      // Simulate click on expand lines button if campaign is multiple, otherwise navigate to details overview
      if (row.idms) {
        const expandButtonSearch = event.target
          .closest('tr')
          .getElementsByClassName('el-table__expand-icon')
        if (expandButtonSearch.length > 0) {
          expandButtonSearch[0].click()
        }
      } else {
        this.$router.push({
          name: 'push-detail-overview',
          params: { campaignId: row.id }
        })
      }
    },
    orderBy (val) {
      let orderBy = null
      if (val.order) {
        orderBy = val.prop
        if (val.order === 'descending') {
          orderBy = `-${orderBy}`
        }
      }
      this.$emit('sort-by', orderBy)
    },
    formatDate (row, column, cellValue) {
      if (!cellValue) {
        return ''
      }
      return this.$options.filters.formatDate(cellValue, this.$i18n.locale, this.userTimezone, this.$t('global.date_format'))
    },
    formatPercentage (row, column, cellValue) {
      return this.$options.filters.round2dec(cellValue * 100) + '%'
    },
    formatNumber (row, column, cellValue) {
      return this.$options.filters.formatNumber(cellValue)
    },
    formatCurrency (row, column, cellValue) {
      return `${this.$options.filters.formatNumber(cellValue, '0,0.00')} ${this.clientCurrency}`
    },
    updateDisplay() {
      this.$emit('update-display-all-kpis', !this.displayAllKpis)
    },
    totalKpis () {
      return this.$store.getters['push/kpis']
    },
  }
}
</script>

<style lang='scss'>
.table-header {
  .cell {
    line-height: normal;
  }
}
.table-push {
  .el-table th {
    padding-left: 10px;
  }

  .el-table {
    table {
      table-layout: auto;
      width: 100%;
    }

    .el-table__body {
      border-collapse: collapse;
    }
    .el-table__row {
      height: 60px;
      background-color: #FAFAFA;

      &--striped {
        td {
          background-color: white !important;
        }

        &.hover-row td {
          background-color: #F5F7FA !important;
        }
      }
      &:first-child {
        background-color: #E9F3F3;
        border-bottom: 3px solid #BDDBDB;
        font-weight: bold;

        &.hover-row td {
          background-color: #E9F3F3 !important;
        }

        .total {
          display: flex;
          flex-direction: row;

          .total-description {
            margin-left: 3px;
            font-weight: normal;
          }
        }
      }
      .cell {
        white-space: nowrap;
      }
    }
  }
  .empty-table {
    position: relative;
    margin: auto;
    padding: 20px 0;
    &__puddle {
      margin-top: 20px;
    }
    &__box {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 40%;
    }
    &__text {
      position: absolute;
      transform: translate(-50%, -50%);
      font-weight: 600;
      color: #051119;
      left: 50%;
      top: 57%;
      line-height: normal;
    }
  }
}
.material-icons.narrow-width {
  width: 16px
}
</style>
