<template>
  <section class="overview-section">
    <div class="col-left">
      <template v-for="(kpisValue, kpisKey) in overview">
        <component
          :is="components_map[kpisKey].name"
          v-if="isKpi(kpisKey)"
          :key="kpisKey"
          :option="components_map[kpisKey].option"
          :kpiskey="kpisKey"
          :kpisvalue="kpisValue"
          :kpis="kpis"
          :channel="'email'"
          :image-src="amplifierImgUrl"
        />
      </template>
      <BlockEvolution
        v-if="showEvolution"
        :reactions="reactions"
        :sendingdate="campaign.start_date"
        :channel="'email'"
      />
    </div>

    <div class="col-right">
      <div class="switch">
        <el-radio-group
          v-model="preview"
          size="medium"
        >
          <el-radio-button label="mobile">
            <i class="material-icons md-16">phone_iphone</i>
          </el-radio-button>
          <el-radio-button label="desktop">
            <i class="material-icons md-16">desktop_windows</i>
          </el-radio-button>
        </el-radio-group>
      </div>

      <div
        v-if="preview === 'mobile'"
        class="preview mobile-emulator"
      >
        <div class="screen">
          <div
            v-if="contentPreviews && contentPreviews.mobile_url"
            class="content"
          >
            <img
              :src="contentPreviews.mobile_url"
              alt="Mobile preview image"
            >
          </div>
          <div
            v-else
            v-loading="isLoading"
            class="content-no-data"
          />
        </div>
      </div>

      <div
        v-if="preview === 'desktop'"
        class="preview desktop-emulator"
      >
        <div
          v-if="contentPreviews && contentPreviews.desktop_url"
          class="content"
        >
          <img
            :src="contentPreviews.desktop_url"
            alt="Desktop preview image"
          >
        </div>
        <div
          v-else
          v-loading="isLoading"
          class="content-no-data"
        />
      </div>
    </div>
  </section>
</template>

<script>
import config from '@/config'
import {
  BlockAmplifier,
  BlockGeneric,
  BlockLanding,
  BlockEvolution
} from '@/components/Common/Block'

export default {
  name: 'ViewsEmailDetailOverview',
  components: { BlockGeneric, BlockLanding, BlockEvolution, BlockAmplifier },
  data () {
    return {
      components_map: {
        reactions: { name: 'BlockGeneric', option: null },
        delivery: { name: 'BlockGeneric', option: null },
        landing_pages: { name: 'BlockLanding', option: null },
        unsubscriptions: { name: 'BlockGeneric', option: null },
        amplifier: { name: 'BlockAmplifier', option: null }
      },
      imgAmplifierUrl: null,
      kpis: {
        sortOrder: {
          reactions: [
            'open_rate',
            'click_rate',
            'total_opens',
            'unique_opens',
            'total_clicks',
            'unique_clicks',
            'reaction_rate'
          ],
          delivery: [
            'emails_sent',
            'delivery_rate',
            'delivered',
            'undelivered',
            'soft_bounces',
            'hard_bounces'
          ],
          landing_pages: [
            'visit_rate',
            'click_rate',
            'total_visits',
            'unique_visits',
            'total_clicks',
            'unique_clicks',
            'total_submissions'
          ],
          unsubscriptions: [
            'unsubscription_rate',
            'unsub_unique_clicks',
            'unsubscribed'
          ],
          amplifier: [
            'reach_rate',
            'average',
            'exposed',
            'non_openers_exposed'
          ]
        },
        important: [
          'emails_sent',
          'delivery_rate',
          'open_rate',
          'click_rate',
          'ack_rate',
          'unsub_rate',
          'unsubscription_rate',
          'conversion_rate',
          'revenue',
          'visit_rate',
          'click_rate',
          'reach_rate',
          'average',
        ],
        name: {
          emails_sent: 'send',
          delivery_rate: 'delivery',
          open_rate: 'open',
          click_rate: 'click',
          ack_rate: 'acknowledged',
          unsub_rate: 'unsubscribe',
          unsubscription_rate: 'unsubscribe',
          conversion_rate: 'conversion',
          revenue: 'revenue',
          visit_rate: 'visit',
          unique_clicks: 'unique_clicks',
          total_submissions: 'total_submissions',
          unique_visits: 'unique_visits',
          reach_rate: 'reach',
          average: 'average',
          exposed: 'exposed',
          non_openers_exposed: 'non_openers_exposed'
        }
      },
      notKpi: [
        'html_src'
      ],
      preview: 'mobile'
    }
  },
  computed: {
    /**
     * Generate sorted overview and update kpis format
     */
    overview () {
      let orderedOverview = {}
      const campaignOverview = this.$store.getters['emailCampaign/overview']
      for (const key in campaignOverview) {
        if (!this.kpis.sortOrder[key]) {
          orderedOverview[key] = campaignOverview[key]
          continue
        }
        if (key === 'landing_pages') {
          orderedOverview.landing_pages = []
          const lps = campaignOverview.landing_pages

          for (let i in lps) {
            orderedOverview.landing_pages[i] = {
              id: lps[i].id,
              lp_name: lps[i].lp_name,
              data: this.kpis.sortOrder.landing_pages
                .map(kpiKey => {
                  return {
                    key: kpiKey,
                    name: this.kpis.name[kpiKey] || null,
                    value: lps[i][kpiKey]
                  }
                })
            }
          }
        }
        else if (key === 'amplifier' && !Object.keys(campaignOverview.amplifier).length) {
          continue
        }
        else {
          orderedOverview[key] = this.kpis.sortOrder[key]
            .map(kpiKey => {
              return {
                key: kpiKey,
                name: this.kpis.name[kpiKey] || null,
                value: campaignOverview[key][kpiKey]
              }
            })
        }
      }

      return orderedOverview
    },
    campaign () {
      return this.$store.getters['emailCampaign/campaign']
    },
    amplifierImgUrl () {
      const amplifier = this.$store.getters['emailCampaign/overview'].amplifier || null
      return amplifier ? amplifier.image_url : null
    },
    reactions () {
      return this.$store.getters['emailCampaign/reactions']
    },
    contentPreviews () {
      return this.$store.getters['emailCampaign/previews']
    },
    isLoading () {
      return this.$store.getters['emailCampaign/loading']
    },
    showEvolution () {
      return this.campaign && this.campaign.campaign_type === config.EMAIL_CHANNEL_TAGS.one_shot
    }
  },
  methods: {
    /**
     * Check if they key is a KPI
     * @param {String} key (kpi key)
     * @return {Boolean}
     */
    isKpi (key) {
      return !this.notKpi.includes(key)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.overview-section {
  display: flex;
  padding-bottom: 3rem;

  @media only screen and (max-width: $--mobile-width) {
    flex-direction: column;
  }
  .col-left {
    width: 680px;
    margin-top: 54px;
    @media only screen and (max-width: $--mobile-width) {
      width: 100%;
      flex: 1;
    }
  }
  .col-right {
    width: 520px;
    .switch {
      text-align: center;
      margin-bottom: 1.2rem;
    }
    .preview {
      margin: auto;
    }
    .mobile-emulator {
      background: url("~@/assets/svg/emails/preview/frame.svg") no-repeat center center;
      width: 400px;
      height: 800px;
      display: flex;
      justify-content: center;
      align-items: center;
      .screen {
        position: relative;
        width: 320px;
        min-height: 630px;
        max-height: 630px;
        height: 630px;
        background-color: #fff;
        border: 1px solid #e9e9e9;
        top: 2px;

        .content {
          width: 100%;
          height: 100%;
          overflow-y: scroll;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .desktop-emulator {
      position: relative;
      width: 520px;
      min-height: 630px;
      background-color: #d5dde3;
      border: solid 1px #dcdfe6;
      .content {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .content-no-data {
      display: flex;
      height: 630px;
      width: 100%;
      justify-content: center;
      align-items: center;
      span {
        width: 80%;
        word-wrap: break-word;
      }
    }
    @media only screen and (max-width: $--mobile-width) {
      width: 100%;
      .mobile-emulator,
      .desktop-emulator {
        width: 100%;
      }
    }
  }
}
</style>
