<template>
  <header class="header-landing page-header">
    <div class="row">
      <Breadcrumb :items="breadcrumbs" />
    </div>
    <div class="entete block-meta">
      <div class="left">
        <div
          v-if="isPublished"
          class="header_publication"
        >
          <span class="led led_published" />
          <span>{{ $t('landing.published') | capitalize }}</span>
          <span v-if="headerMeta.first_publish_date">
            {{ headerMeta.first_publish_date | formatDate($i18n.locale, userTimezone, $t('global.date_format')) }}
          </span>
        </div>
        <div
          v-else
          class="header_publication"
        >
          <span class="led led_unpublished" />
          <span v-if="headerMeta.expiration_date">
            {{ $t('landing.expired') | capitalize }}
            {{ headerMeta.expiration_date | formatDate($i18n.locale, userTimezone, $t('global.date_format')) }}
          </span>
          <span v-else-if="headerMeta.unpublish_date">
            {{ $t('landing.unpublished') | capitalize }}
            {{ headerMeta.unpublish_date | formatDate($i18n.locale, userTimezone, $t('global.date_format')) }}
          </span>
          <span v-if="headerMeta.first_publish_date">
            ({{ $t('landing.published') }} {{ headerMeta.first_publish_date | formatDate($i18n.locale, userTimezone, $t('global.date_format')) }})
          </span>
        </div>

        <div
          v-if="headerMeta.lp_url"
          class="header_link"
        >
          <i class="material-icons md-24">link</i>
          <a
            :href="headerMeta.lp_url"
            target="_blank"
          >
            {{ headerMeta.lp_url | truncate(70) }}
          </a>
        </div>
      </div>

      <div class="right">
        <DatePickerLanding
          v-if="headerMeta.first_publish_date"
          :publication-date="headerMeta.first_publish_date"
          @updated="(e) => $emit('range-date-updated', e)"
        />
        <el-button
          v-if="headerMeta.list_uid"
          class="dbt custom-bt-download-white"
          @click="goToSubmissions"
        >
          <i class="el-material-icons">exit_to_app</i>
          {{ $t('landing.view_full_sub_list') }}
        </el-button>
        <DownloadButton
          :loading="downloadLoading"
          @click-download="() => $emit('generate-export')"
        />
      </div>
    </div>
  </header>
</template>

<script>
import {
  DatePickerLanding,
  DownloadButton
} from '@/components/Common/Form'
import Breadcrumb from '@/components/Common/Breadcrumb'

export default {
  name: 'PartsHeaderLandingDetail',
  components: {
    DownloadButton,
    DatePickerLanding,
    Breadcrumb
  },
  props: {
    landingId: {
      type: String,
      required: true
    },
    contactlyUrl: {
      type: String,
      default: ''
    },
    headerMeta: {
      type: Object,
      default: () => {}
    },
    downloadLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    },
    isPublished () {
      return this.headerMeta.published
    },
    groupName () {
      return this.headerMeta.group ? `(${this.headerMeta.group})` : ''
    },
    startDate () {
      return (new Date(this.$store.getters['landingDetail/startDate'])).toJSON()
    },
    endDate () {
      return (new Date(this.$store.getters['landingDetail/endDate'])).toJSON()
    },
    breadcrumbs () {
      return [
        {
          state: 'landing-list',
          name: `${this.$t('labels.landing_page')} ${this.groupName}`
        },
        {
          state: null,
          name: this.headerMeta.lp_name
        }
      ]
    }
  },
  methods: {
    goToSubmissions () {
      window.open(this.contactlyUrl)
    }
  }
}
</script>

<style lang="scss" scioed>
@import "@/assets/styles/_variables.scss";

.header-landing {
  .block-meta {
    border-radius: 3px;
    padding: 2rem;
    background-color: $--color-header-detail-dark-bg;
    background: repeating-linear-gradient(
      -45deg,
      $--color-header-detail-dark-bg 0%,
      $--color-header-detail-dark-bg 37%,
      $--color-header-detail-bg 37%,
      $--color-header-detail-bg 100%
    );
    i {
      margin-right: 0.5rem;
    }
  }
  .led {
    margin-right: 1rem;
  }
  .header_publication {
    margin-bottom: 1rem;
  }
  .header_link {
    i {
      line-height: 18px;
      vertical-align: text-top;
    }
    a {
      color: black;
      text-decoration: none;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem !important;

    @media only screen and (max-width: $--mobile-width) {
      flex-direction: column;
    }
  }
  .entete {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      i {
        text-align: right;
        width: 1.6rem;
        margin-right: 0.5rem;
        color: $--color-primary;
      }
    }
    .right {
      display: flex;
      align-items: center;
      button,
      .el-dropdown {
        margin-left: 0.3rem;
      }
    }
  }
}
</style>
