import Vue from 'vue'
import Vuex from 'vuex'

import moduleSession from './session'
import moduleApp from './app'
import moduleApps from './apps'
import moduleClients from './clients'
import moduleTag from './tag'
import moduleBoard from './board'
import moduleEmailCampaign from './email_campaign'
import moduleEmail from './email'
import moduleLandingDetail from './landing_detail'
import moduleLanding from './landing'
import moduleMap from './map'
import moduleSMSCampaign from './sms_campaign'
import moduleSMS from './sms'
import moduleReports from './reports'
import moduleGroup from './groups'
import modulePush from './push'
import modulePushCampaign from './push_campaign'
import moduleCampaignConversions from './campaign_conversions'
import moduleQRCodes from './qr_codes'
import moduleQRDetails from './qr_detail'
import moduleDataExports from './data_exports'
import moduleRecipients from './recipients'
import user from './user'
import moduleSnapcall from './snapcall'

Vue.use(Vuex)

const storeModule = {
  app: moduleApp,
  apps: moduleApps,
  clients: moduleClients,
  tag: moduleTag,
  board: moduleBoard,
  session: moduleSession,
  map: moduleMap,
  emailCampaign: moduleEmailCampaign,
  email: moduleEmail,
  landingDetail: moduleLandingDetail,
  landing: moduleLanding,
  smsCampaign: moduleSMSCampaign,
  sms: moduleSMS,
  reports: moduleReports,
  group: moduleGroup,
  pushCampaign: modulePushCampaign,
  push: modulePush,
  conversions: moduleCampaignConversions,
  qrCodes: moduleQRCodes,
  qrDetail: moduleQRDetails,
  dataExport: moduleDataExports,
  recipients: moduleRecipients,
  user: user,
  snapcall: moduleSnapcall
}

// Store
const store = new Vuex.Store({
  modules: storeModule
})

export default store
