import i18n from '@/languages/i18n'
import store from '@/store'

// Scroll to top and init page's title when route changes
const scrollGuard = function (to, from, next) {
  // Reset filters section change
  const viewNames = ['email', 'sms', 'push']
  const storeMap = {
    email: 'email/resetFilters',
    push: 'push/resetFilters',
    sms: 'sms/resetFilters'
  }
  const routeNameFrom = from.name && from.name.split('-')[0]
  const routeNameTo = to.name && to.name.split('-')[0]
  const leaveOneViewName = routeNameFrom && viewNames.includes(routeNameFrom) && routeNameFrom !== routeNameTo

  if (leaveOneViewName) {
    store.dispatch(storeMap[routeNameFrom])
  }

  window.scrollTo(0, 0)
  const pageTitle = i18n.t(to.meta.title)
  document.title = pageTitle.length > 0 ? `${pageTitle} - Dashboardly` : 'Dashboardly'
  next()
}

export default scrollGuard
