<template>
  <div class="kpi-stats">
    <div
      v-for="(userKPI, index) in userChannelKpiPreferences"
      :key="index"
      class="kpi-stats-block"
    >
      <div
        v-if="index !== 0"
        class="kpi-stats-line"
      />
      <div
        class="kpi-stats-card"
      >
        <KPICard
          :kpi="userKPI"
          :value="kpis[userKPI]"
          :channel="channel"
          :kpi-preferences="userChannelKpiPreferences"
          :all-kpis="kpisPerChannel[channel]"
          @update-preferences="(e) => onUpdatePreferences(userKPI, e)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import KPICard from '@/components/Parts/Header/KPI/KPICard'
import { kpisPerChannel } from '@/helpers/kpis'

export default {
  name: "KPIStats",
  components: {
    KPICard,
  },
  props: {
    kpis: {
      type: Object,
      required: true,
    },
    channel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      kpisPerChannel: kpisPerChannel
    }
  },
  computed: {
    userPreferences() {
      return this.$store.getters["user/preferences"]
    },
    userChannelKpiPreferences() {
      const channelPreference = this.userPreferences.find(
        (pref) => pref.property === `${this.$props.channel}_kpi`
      )
      if (!channelPreference) {
        const kpis = []

        for (const key in this.kpisPerChannel[this.channel]) {
          if (this.kpisPerChannel[this.channel][key].default) {
            kpis.push(key)
          }
        }
        return kpis
      }

      return channelPreference.value.split(',')
    },
  },
  beforeMount() {
    this.$store.dispatch("user/getPreferences");
  },
  methods: {
    onUpdatePreferences(toReplace, newKpi) {
      let preferences = this.userChannelKpiPreferences

      let index = preferences.indexOf(toReplace);
      if (preferences !== -1) {
        preferences[index] = newKpi;
      }
      this.$store.dispatch("user/updatePreferences", {
        'property': `${this.$props.channel}_kpi`,
        'value': preferences
      })
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.kpi-stats {
  width: 100%;
  height: 135px;
  padding: 20px 0px;
  background-color: #FFFFFF;
  display: grid;
  grid-auto-flow: column;
  justify-items: stretch;
  border: 1px solid #BDDBDB;
  border-radius: 2px;
  margin-top: 24px;

  @media only screen and (min-width: $--mobile-width) {
    max-width: 700px;
    margin-left: 24px;
    margin-top: 0;
  }

  .kpi-stats-block {
    display: flex;
    justify-content: space-between;

    .kpi-stats-line {
      width: 1px;
      height: 70px;
      margin: auto;
      background-color: #BDDBDB;
    }
    .kpi-stats-card {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
