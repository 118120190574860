<template>
  <div class="block-param bp-in-push bp-push">
    <div class="row">
      <h2>{{ $t(`kpi.push.${kpiskey}`) }}</h2>
    </div>

    <div class="infos-container">
      <div class="kpis">
        <ChartKPI
          v-for="impKpi in importantKpiList(kpisvalue)"
          :key="impKpi.key"
          :channel="'push'"
          :data="{
            name: impKpi.name,
            kpi_value: impKpi.value
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ChartKPI } from '@/components/Common/Chart'

export default {
  name: 'BlockInPush',
  components: { ChartKPI },
  props: {
    kpiskey: {
      type: String,
      required: true
    },
    kpisvalue: {
      type: Array,
      default: () => []
    },
    kpis: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    /**
     * Check if it's an important KPI
     * @param  {String} key (kpi key)
     * @return {Boolean}
     */
    isImportantKpi (key) {
      return this.kpis.important.includes(key)
    },
    /**
     * Filter kpis list for important ones
     * @param  {Array} kpis (collection of kpis object)
     * @return {Array} (Should not exeed size of 2)
     */
    importantKpiList (kpis) {
      return kpis.filter(kpi => this.isImportantKpi(kpi.key))
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.bp-in-push {
  .infos-container {
    display: flex;

    .kpis {
      flex: 1;
      display: flex;
      .kpi {
        margin-right: 1rem;
        width:50%
      }
    }
  }
}
</style>
