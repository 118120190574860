<template>
  <div>
    <el-drawer
      custom-class="data-export-drawer"
      :title="$t('exports.sending_data.drawer.title')"
      :visible="showDrawer"
      size="25%"
      :before-close="handleClose"
      :destroy-on-close="true"
      @open="handleOpen"
    >
      <div
        v-infinite-scroll="getExports"
        infinite-scroll-disabled="disabledInfiniteScroll"
      >
        <div class="export-description">
          {{ $t('exports.sending_data.drawer.description') }}
          <br>
          <br>
          {{ $t('exports.sending_data.drawer.description_1') }}
        </div>
        <div 
          v-if="dataExports.length > 0"
        >
          <div
            v-for="(exportObj, index) in dataExports"
            :key="index"
          >
            <!-- CARD -->
            <div class="export-card">
              <div class="export-card-date">
                {{ formatExportClaimDate(exportObj.export_claim_date) }}
              </div>
              <div class="export-author">
                {{ $t('exports.sending_data.drawer.card.description', {author: `${exportObj.author.first_name} ${exportObj.author.last_name}`}) }}
              </div>
              <div class="export-metadata">
                {{ $t('exports.sending_data.drawer.card.volume', {volume: exportObj.rows_number}) }}
                •
                <span class="export-metadata-expiration-date">
                  {{ $t('exports.sending_data.drawer.card.expiration_date', {date: getExpirationDate(exportObj.export_claim_date)}) }}
                </span>
              </div>
              <div class="export-download-btn">
                <el-button
                  :loading="downloading"
                  @click="download(exportObj.export_uid)"
                >
                  {{ $t('exports.sending_data.drawer.card.download_btn') }}
                </el-button>
              </div>
            </div>
          </div>
          <div
            v-loading="loadingExport"
            class="export-loading"
          />
        </div>
        <el-empty
          v-else
          v-loading="loadingExport"
          class="export-empty"
          :image-size="150"
          :description="$t('exports.sending_data.drawer.empty')"
        />
      </div>
    </el-drawer>
  </div>
</template>
  
<script>
import moment from 'moment'
import {
  cloneDeep as _cloneDeep,
} from 'lodash-es'


export default {
  name: 'DataExportDrawerList',
  components: {},
  props: {
    showDrawer: {
      type: Boolean,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    },
    channelId: {
      type: Number,
      required: true
    },
  },
  data(){
    return {
      loadingExport: false,
      dataExports: [],
      total: 0,
      pagination: 1,
      downloading: false
    }
  },
  computed: {
    disabledInfiniteScroll () {
      return this.dataExports.length >= this.total || this.loadingExport
    }
  },
  methods: {
    handleOpen() {
      let queryParams = _cloneDeep(this.$route.query) || { }
      if (!queryParams["drawer"]) {
        queryParams["drawer"] = true
        this.$router.push({query: queryParams})
      }

      // init data
      this.dataExports = []
      this.total = 0
      this.pagination = 1

      // fetch exports
      this.getExports()
    },
    handleClose() {
      let queryParams = _cloneDeep(this.$route.query) || { }
      queryParams["drawer"] = undefined
      this.$router.push({query: queryParams})

      // close drawer
      this.$emit('update-show-drawer', false)
    },
    async getExports() {
      this.loadingExport = true
      const params = {
        "campaign_id": this.campaign.id,
        "channel_id": this.channelId,
        "l": 10,
        "p": this.pagination
      }
      try {
        const response = await this.$store.dispatch("dataExport/getDataExports", params)
        const data = response.data
        this.dataExports = this.dataExports.concat(data.objects)
        this.total = data.meta.total
        this.pagination += 1
      } catch (_) {
        this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
      } finally {
        this.loadingExport = false
      }
    },
    getExpirationDate(claimDate) {
      let expirationDate = new Date(claimDate)
      expirationDate.setDate(expirationDate.getDate() + 30)
      return moment(expirationDate).format('ll')
    },
    formatExportClaimDate(claimDate) {
      return moment(claimDate).format('lll')
    },
    async download(exportUid) {
      this.downloading = true
      try {
        const response = await this.$store.dispatch('dataExport/getDataExportPresignedUrl', exportUid)
        var link = document.createElement("a")
        link.href = response.data
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch(_) {
        this.$notify({
            title: this.$t('ERRORS.UNEXPECTED'),
            message: this.$t('ERRORS.UNEXPECTED_DESC'),
            type: 'error'
          })
      }finally {
        this.downloading = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
.data-export-drawer {
  .el-drawer__header span {
    color: black;
    font-weight: 600;
  }
  .el-drawer__body {
    padding: 0px 20px;
    overflow-y: auto;
    max-height: auto;
    .export-description {
      margin-bottom: 10px;
      color: $--color-gray;
    }
    .export-card {
      width: 100%;
      border: 1px solid $--color-light-gray;
      height: auto;
      padding: 20px;
      margin: 20px 0px;
      font-size:12px;
      .export-card-date {
        font-weight: 600;
        margin-bottom: 6px;
      }

      .export-metadata {
        .export-metadata-expiration-date {
          color: $--color-gray;
        }
      }
      .export-download-btn {
        margin-top: 30px;
        text-align: center;
      }
    }
    .export-loading {
      margin-top: 40px;
    }
    .export-empty {
      margin: 80px 0px;
      text-align: center;
      .el-empty__image {
        margin: auto;
      }
    }
  }
}
</style>