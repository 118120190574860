<template>
  <section class="detail-page qr-detail">
    <HeaderQRDetail
      :header-meta="headerMeta"
      :download-loading="downloadLoading"
      @range-date-updated="rangeDateUpdated"
    />
    <section class="overview-section">
      <div class="col-left">        
        <template v-for="(kpisValue, kpisKey) in overview">
          <component
            :is="components_map[kpisKey].name"
            v-if="isKpi(kpisKey)"
            :key="kpisKey"
            :option="components_map[kpisKey].option"
            :kpiskey="kpisKey"
            :kpisvalue="kpisValue"
            :kpis="kpis"
            :channel="'qr'"
          />
        </template>
        <BlockEvolutionQR
          v-if="reactions"
          :reactions="reactions"
        />
      </div>
      <div class="col-right">
        <div class="qr-preview">
          <img
            :src="headerMeta.img_url"
            alt="qr code"
          >
          <p>{{ headerMeta.img_url }}</p>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { HeaderQRDetail } from '@/components/Parts/Header'
import { isoDateString } from '@/helpers/isoDate'
import {
  BlockInQR,
  BlockEvolutionQR
} from '@/components/Common/Block'
import { urlParams } from '@/mixins'

export default {
  name: 'ViewQRDetail',
  components: {
    HeaderQRDetail, BlockInQR, BlockEvolutionQR
  },
  mixins: [ urlParams ],
  data () {
    return {
      components_map: {
        performances: { name: 'BlockInQR', option: null }
      },
      kpis: {
        sortOrder: {
          performances: [
            'total_scans'
          ]
        },
        important: [
          'total_scans'
        ],
        name: {
          total_scans: 'total_scans'
        }
      },
      notKpi: []
    }
  },
  computed: {
    qrId () {
      return this.$route.params.qrId.toString()
    },
    startDate () {
      return this.$store.getters['qrDetail/startDate']
    },
    endDate () {
      return this.$store.getters['qrDetail/endDate']
    },
    rangeDateRaw () {
      return {
        start: this.startDate,
        end: this.endDate
      }
    },
    rangeDate () {
      const tz = this.$store.getters['session/user'].timezone || 'UTC'

      return {
        start: isoDateString(this.startDate, tz),
        end: isoDateString(this.endDate, tz, true),
      }
    },
    headerMeta () {
      return this.$store.getters['qrDetail/headerMeta']
    },
    overview () {
      return this.$store.getters['qrDetail/overview'] || {}
    },
    performances () {
      return this.overview.performances || {}
    },
    reactions () {
      return this.$store.getters['qrDetail/reactions']
    },
    downloadLoading () {
      return this.$store.getters['qrDetail/downloadLoading']
    }
  },
  async created () {
    // Load campaign informations
    this.$store.dispatch('qrDetail/reset')
    const response = await this.$store.dispatch('qrDetail/getHeaderMeta', this.qrId)
    if (response.status != 200) {
      if (response.status >= 500) {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      } else {
        this.$message({
          showClose: true,
          center: true,
          duration: 0,
          message: this.$t('global.alert_no_campaign'),
        })
      }
      this.$router.push({ name: 'home' })
      return
    }

    // Load params from url
    for (let key in this.$route.query) {
      switch (key) {
        case 'start_date':
          this.$store.dispatch('qrDetail/setStartDate', this.$route.query[key])
          break
        case 'end_date':
          this.$store.dispatch('qrDetail/setEndDate', this.$route.query[key])
          break
      }
    }
    this.loadData()
  },
  methods: {
    async loadData () {
      const id = this.qrId
      const params = {
        start_date: this.rangeDate.start,
        end_date: this.rangeDate.end,
        step: 1
      }
      // Update url first prior to retrieve data
      this.urlParams(params, this.rangeDateRaw)
      
      this.$store.dispatch('qrDetail/getOverview', { id, params })
      this.$store.dispatch('qrDetail/getReactions', { id, params })
    },
    rangeDateUpdated () {
      this.loadData()
    },
    isKpi(key) {
      return !this.notKpi.includes(key)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
.qr-detail {
  position: relative;
  
  .overview-section {
    display: flex;
    padding-bottom: 3rem;

    @media only screen and (max-width: $--mobile-width) {
      flex-direction: column;
    }
    .col-left {
      width: 680px;
      margin-top: 56px;
      @media only screen and (max-width: $--mobile-width) {
        width: 100%;
        flex: 1;
      }
      .kpi {
        width: 100% !important;
      }
    }
    .col-right {
      display: flex;
      justify-content: center;
      width: 520px;
      margin-top: 56px;
      margin-left: 2rem;
      
      @media only screen and (max-width: $--mobile-width) {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
      }
      
      .qr-preview {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 359px;
        height: 359px;
        background-color: #fff;
        border: solid 1px #dcdfe6;
        padding: 2rem;
        border-radius: 3px;
        
        p {
          margin-top: 2rem;
          max-width: 100%;
          text-align: center;
          word-wrap: break-word;
        }
      }
    }
  }
}
</style>
