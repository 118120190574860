<template>
  <section class="hostsdevices">
    <div
      v-if="showDevice"
      class="devicesections"
      data-tested-as="devices-section"
    >
      <CampaignDevices
        :chart-clicks="chartPlatformsClicks"
        :chart-opens="chartPlatformsOpens"
        :chart-legends="chartPlatformsLabels"
        :chart-title="$t('labels.reactionsperplatform')"
        :click-tooltip="$t('labels.clicksperplatform_tip')"
        :open-tooltip="$t('labels.opensperplatform_tip')"
      />
      <CampaignDevices
        :chart-clicks="chartDevicesClicks"
        :chart-opens="chartDevicesOpens"
        :chart-legends="chartDevicesLabels"
        :chart-title="$t('labels.reactionsperdevice')"
        :click-tooltip="$t('labels.clicksperdevice_tip')"
        :open-tooltip="$t('labels.opensperdevice_tip')"
      />
    </div>
    <div
      class="block table"
      data-tested-as="hosts-section"
    >
      <h3>Hosts</h3>
      <el-table
        :data="hosts"
        :col-class-name="tableColClassName"
        style="width: 100%"
      >
        <!-- NO DATA -->
        <div slot="empty">
          <TableEmptyData
            :empty-label="$t('table.content.empty_campaigns')"
          />
        </div>

        <!-- Columns -->
        <el-table-column
          v-for="(col, index) in hostsColumns"
          :key="col.id"
          :class-name="isHighlighted(col.prop) ? 'highlight' : ''"
          :label="$t(`table.header.${col.prop}`)"
          :formatter="col.formatter"
          :align="index > 0 ? 'right' : 'left'"
          :prop="col.prop"
          width="150"
          sortable
        />
      </el-table>
    </div>
  </section>
</template>

<script>
import config from '@/config'
import CampaignDevices from './Devices'
import { TableEmptyData } from '@/components/Common/Table'
import {
  cloneDeep as _cloneDeep,
} from 'lodash-es'

export default {
  name: 'PartsHostsDevices',
  components: {
    TableEmptyData,
    CampaignDevices
  },
  props: {
    hosts: {
      type: Array,
      required: true
    },
    devicesData: {
      type: Object,
      default: null
    },
    showDevice: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex]
              const meta = dataset._meta[Object.keys(dataset._meta)[0]]
              const total = meta.total
              const currentValue = dataset.data[tooltipItem.index]
              const percentage = parseFloat((currentValue / total * 100).toFixed(1))
              return percentage + '%'
            },
            title: function (tooltipItem, data) {
              return data.labels[tooltipItem[0].index]
            }
          }
        }
      },
      deviceLabels: config.DEVICE_LABELS,
      platformLabels: config.PLATFORM_LABELS,
      defaultEmptyClicksData: { "labels": [], "datasets": [ { "label": "Clicks", "data": [], "backgroundColor": [] } ] },
      defaultEmptyOpensData: { "labels": [], "datasets": [ { "label": "Opens", "data": [], "backgroundColor": [] } ] }
    }
  },
  computed: {
    hostsColumns() {
      return this.columnOrdener(this.hosts[0])
    },
    chartDevicesClicks() {
      if (!this.devicesData || !this.devicesData.devices) { return this.defaultEmptyClicksData }
      return this.customizer(_cloneDeep(this.devicesData.devices.clicks), 'Clicks', this.deviceLabels)
    },
    chartDevicesOpens() {
      if (!this.devicesData || !this.devicesData.devices) { return this.defaultEmptyOpensData }
      return this.customizer(_cloneDeep(this.devicesData.devices.opens), 'Opens', this.deviceLabels)
    },
    chartPlatformsClicks() {
      if (!this.devicesData || !this.devicesData.platforms) { return this.defaultEmptyClicksData }
      return this.customizer(this.devicesData.platforms.clicks, 'Clicks', this.platformLabels)
    },
    chartPlatformsOpens() {
      if (!this.devicesData || !this.devicesData.platforms) { return this.defaultEmptyOpensData }
      return this.customizer(this.devicesData.platforms.opens, 'Opens', this.platformLabels)
    },
    chartPlatformsLabels() {
      return this.platformLabels.map(key => ({'label': this.$t(`labels.${key}`), 'tooltip': this.$t(`labels.${key}_tip`)}))
    },
    chartDevicesLabels() {
      return this.deviceLabels.map(key => ({'label': this.$t(`labels.${key}`), 'tooltip': this.$t(`labels.${key}_tip`)}))
    }
  },
  methods: {
    isHighlighted(prop) {
      const highlightedProperties = [
        'details_hard_bounces',
        'details_hard_complaints',
        'details_soft_user_bounces',
        'details_soft_block_bounces',
        'details_soft_technical_errors'
      ]
      return highlightedProperties.includes(prop)
    },
    tableColClassName({ colIndex }) {
      if (colIndex === 1) {
        return 'warning-col';
      } else if (colIndex === 3) {
        return 'success-col';
      }
      return '';
    },
    columnOrdener: function (arr) {
      let props = []
      for (let i in arr) {
        const id = this.getIndex(i)
        if (id > -1) {
          props[id] = {
            id: `id_${i}`,
            prop: i,
            label: this.$t(`table.header.${i}`),
            formatter: this.getFormatter(i)
          }
        }
      }
      return props
    },
    getIndex: function (propName) {
      const columns = [
        'host_name',
        'emails_sent',
        'open_rate',
        'click_rate',
        'delivery_rate',
        'hard_bounce_rate',
        'nb_hard_bounces',
        'details_hard_bounces',
        'details_hard_complaints',
        'soft_bounce_rate',
        'nb_soft_bounces',
        'details_soft_user_bounces',
        'details_soft_block_bounces',
        'details_soft_technical_errors'
      ]
      return columns.indexOf(propName)
    },
    getFormatter: function (prop) {
      const filters = this.$options.filters
      if (prop.includes('_rate')) {
        return (row, col, cell) => filters.round2dec(cell * 100) + '%'
      }
      return (row, col, cell) => filters.formatNumber(cell)
    },
    customizer: function (original, label, labelRefs) {
      let bgc = []
      let labelTrad = []
      let datasets = []
      if (original && original.labels) {
        for (let i in original.labels) {
          if (original.labels[i] === 'unknown' && labelRefs.includes('unknown_device')) {
            bgc.push(this.$options.filters.autocolorize(labelRefs.indexOf('unknown_device')))
          } else {
            bgc.push(this.$options.filters.autocolorize(labelRefs.indexOf(original.labels[i])))
          }
          labelTrad.push(this.$t(`labels.${original.labels[i]}`))
        }
      }
      if (original && original.datasets) {
        datasets = _cloneDeep(original.datasets[0].data)
      }
      return {
        labels: labelTrad,
        datasets: [{ label: label, data: datasets, backgroundColor: bgc }]
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.hostsdevices {
  padding-bottom: 3rem;

  thead {
    font-size: 11px;
    .cell {
      color: black !important;
    }
    .highlight .cell {
      color: #858f96 !important;
    }
  }
  tbody {
    font-size: 13px;
    color: black !important;
    .highlight {
      color: #858f96;
    }
  }
  .el-table__header .cell {
    justify-content: flex-start;
    color: black;
  }
  .black {
    color: black;
  }
  .devicesections {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  .el-table {
    margin-left: 10px;

    .el-table_1_column_1,
    .el-table_1_column_2,
    .el-table_1_column_3,
    .el-table_1_column_4,
    .el-table_1_column_5,
    .el-table_1_column_6,
    .el-table_1_column_9,
    .el-table_1_column_10 {
      .cell {
        color: $--text-dark;
      }
    }
    .el-table_1_column_7,
    .el-table_1_column_8,
    .el-table_1_column_11,
    .el-table_1_column_12,
    .el-table_1_column_13 {
      .cell {
        color: $--text-light;
      }
    }
  }
  .table {
    h3 {
      padding-left: 10px;
    }
  }
}
</style>
