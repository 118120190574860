const iconColors = {
  main: "#30BFBE",
  red: "#EF4836",
  yellow: "#F4D03F",
};

const icons = {
  check: {
    key: "check",
    color: iconColors.main,
  },
  drafts: {
    key: "drafts",
    color: iconColors.main,
  },
  ads_click: {
    key: "ads_click",
    color: iconColors.main,
  },
  shopping_cart: {
    key: "shopping_cart",
    color: iconColors.main,
  },
  attach_money: {
    key: "paid",
    color: iconColors.main,
  },
  shopping_basket: {
    key: "shopping_basket",
    color: iconColors.main,
  },
  group: {
    key: "group",
    color: iconColors.main,
  },
  schedule: {
    key: "schedule",
    color: iconColors.yellow,
  },
  close: {
    key: "close",
    color: iconColors.red,
  },
};

const kpis = {
  delivery_rate: {
    type: "rate",
    icon: icons.check,
    translate_key: "delivery_rate",
  },
  open_rate: {
    type: "rate",
    icon: icons.drafts,
    translate_key: "open_rate",
  },
  click_rate: {
    type: "rate",
    icon: icons.ads_click,
    translate_key: "click_rate",
  },
  unsubscription_rate: {
    type: "rate",
    icon: icons.close,
    translate_key: "unsubscription_rate",
  },
  delivered: {
    type: "number",
    icon: icons.check,
    translate_key: "delivered",
  },
  hard_bounces: {
    type: "number",
    icon: icons.close,
    translate_key: "hard_bounces",
  },
  soft_bounces: {
    type: "number",
    icon: icons.close,
    translate_key: "soft_bounces",
  },
  bounce_rate: {
    type: "rate",
    icon: icons.close,
    translate_key: "bounce_rate",
  },
  total_opens: {
    type: "number",
    icon: icons.drafts,
    translate_key: "total_opens",
  },
  unique_opens: {
    type: "number",
    icon: icons.drafts,
    translate_key: "unique_opens",
  },
  total_clicks: {
    type: "number",
    icon: icons.ads_click,
    translate_key: "total_clicks",
  },
  unique_clicks: {
    type: "number",
    icon: icons.ads_click,
    translate_key: "unique_clicks",
  },
  reaction_rate: {
    type: "rate",
    icon: icons.ads_click,
    translate_key: "reaction_rate",
  },
  unsubscribed: {
    type: "number",
    icon: icons.close,
    translate_key: "unsubscribed",
  },
  undelivered: {
    type: "number",
    icon: icons.close,
    translate_key: "undelivered",
  },
  unsub_unique_clicks: {
    type: "number",
    icon: icons.close,
    translate_key: "unsub_unique_clicks",
  },
  total_conversions: {
    type: "number",
    icon: icons.shopping_cart,
    translate_key: "total_conversions",
  },
  total_revenue: {
    type: "currency",
    icon: icons.attach_money,
    translate_key: "total_revenue",
  },
  conversion_rate: {
    type: "rate",
    icon: icons.shopping_cart,
    translate_key: "conversion_rate",
  },
  average_basket: {
    type: "currency",
    icon: icons.shopping_basket,
    translate_key: "average_basket",
  },
  articles: {
    type: "number",
    icon: icons.shopping_cart,
    translate_key: "articles",
  },
  buyers: {
    type: "number",
    icon: icons.group,
    translate_key: "buyers",
  },
  unit_sms_billed: {
    type: "number",
    icon: icons.attach_money,
    translate_key: "unit_sms_billed",
  },
  expired_numbers: {
    type: "number",
    icon: icons.close,
    translate_key: "expired_numbers",
  },
  rejected_numbers: {
    type: "number",
    icon: icons.close,
    translate_key: "rejected_numbers",
  },
  ported_numbers: {
    type: "number",
    icon: icons.close,
    translate_key: "ported_numbers",
  },
  numbers_on_hold: {
    type: "number",
    icon: icons.close,
    translate_key: "numbers_on_hold",
  },
  cancelled_numbers: {
    type: "number",
    icon: icons.close,
    translate_key: "cancelled_numbers",
  },
  operator_errors: {
    type: "number",
    icon: icons.close,
    translate_key: "operator_errors",
  },
  unidentified: {
    type: "number",
    icon: icons.schedule,
    translate_key: "unidentified",
  },
  average_revenue_per_send: {
    type: "currency",
    icon: icons.shopping_cart,
    translate_key: "average_revenue_per_send",
  },
  in_progress: {
    type: "number",
    icon: icons.schedule,
    translate_key: "in_progress",
  },
  pn_sent: {
    type: "number",
    icon: icons.check,
    translate_key: "pn_sent",
  }
};

const kpisPerChannel = {
  email: {
    delivery_rate: {
      ...kpis.delivery_rate,
      default: true
    },
    open_rate: {
      ...kpis.open_rate,
      default: true
    },
    click_rate: {
      ...kpis.click_rate,
      default: true
    },
    unsubscription_rate: {
      ...kpis.unsubscription_rate,
      default: true
    },
    delivered: kpis.delivered,
    hard_bounces: kpis.hard_bounces,
    soft_bounces: kpis.soft_bounces,
    bounce_rate: kpis.bounce_rate,
    total_opens: kpis.total_opens,
    unique_opens: kpis.unique_opens,
    total_clicks: kpis.total_clicks,
    unique_clicks: kpis.unique_clicks,
    reaction_rate: kpis.reaction_rate,
    unsubscribed: kpis.unsubscribed,
    unsub_unique_clicks: kpis.unsub_unique_clicks,
    total_conversions: kpis.total_conversions,
    total_revenue: kpis.total_revenue,
    conversion_rate: kpis.conversion_rate,
    average_basket: kpis.average_basket,
    articles: kpis.articles,
    buyers: kpis.buyers,
  },
  sms: {
    delivery_rate: {
      ...kpis.delivery_rate,
      default: true
    },
    click_rate: {
      ...kpis.click_rate,
      default: true
    },
    unsubscription_rate: {
      ...kpis.unsubscription_rate,
      default: true
    },
    unit_sms_billed: {
      ...kpis.unit_sms_billed,
      default: true
    },
    delivered: kpis.delivered,
    undelivered: kpis.undelivered,
    rejected_numbers: kpis.rejected_numbers,
    expired_numbers: kpis.expired_numbers,
    ported_numbers: kpis.ported_numbers,
    numbers_on_hold: kpis.numbers_on_hold,
    cancelled_numbers: kpis.cancelled_numbers,
    operator_errors: kpis.operator_errors,
    unidentified: kpis.unidentified,
    total_clicks: kpis.total_clicks,
    unique_clicks: kpis.unique_clicks,
    unsubscribed: kpis.unsubscribed,
    total_conversions: kpis.total_conversions,
    total_revenue: kpis.total_revenue,
    conversion_rate: kpis.conversion_rate,
    average_revenue_per_send: kpis.average_revenue_per_send,
    average_basket: kpis.average_basket,
    articles: kpis.articles,
    buyers: kpis.buyers,
  },
  push: {
    delivery_rate: {
      ...kpis.delivery_rate,
      default: true
    },
    open_rate: {
      ...kpis.open_rate,
      default: true
    },
    conversion_rate: {
      ...kpis.conversion_rate,
      default: true
    },
    unique_opens: {
      ...kpis.unique_opens,
      default: true
    },
    delivered: kpis.delivered,
    undelivered: kpis.undelivered,
    hard_bounces: kpis.hard_bounces,
    soft_bounces: kpis.soft_bounces,
    in_progress: kpis.in_progress,
    total_conversions: kpis.total_conversions,
    total_revenue: kpis.total_revenue,
    average_revenue_per_send: kpis.average_revenue_per_send,
    average_basket: kpis.average_basket,
    articles: kpis.articles,
    buyers: kpis.buyers,
  }
};

export { kpisPerChannel }