<template>
  <section class="conversions">
    <div
      class="block table"
    >
      <h3>
        {{ $t('global.summary') }}
      </h3>
      <div
        v-loading="loadingConversions"
        class="kpis"
      >
        <ChartKPI
          v-if="conversions.total_conversions !== null"
          :data="{name:'total_conversions', kpi_value: conversions.total_conversions}"
          class="main-kpi"
          :channel="channel"
        />
        <ChartKPI
          v-if="conversions.total_revenue !== null"
          :data="{name:'total_revenue', kpi_value: conversions.total_revenue}"
          :currency="clientCurrency"
          class="main-kpi"
          :channel="channel"
        />

        <ChartKPI
          v-if="conversions.buyers !== null"
          :data="{name: 'buyers', kpi_value: conversions.buyers}"
          :channel="channel"
        />

        <ChartKPI
          v-if="conversions.articles !== null"
          :data="{name: 'articles', kpi_value: conversions.articles}"
          :channel="channel"
        />

        <ChartKPI
          v-if="conversions.average_basket !== null"
          :data="{name:'average_basket', kpi_value: conversions.average_basket}"
          :currency="clientCurrency"
          :channel="channel"
        />

        <ChartKPI
          v-if="conversions.conversion_rate !== null"
          :data="{name:'conversion_rate', kpi_value: conversions.conversion_rate}"
          :channel="channel"
        />

        <ChartKPI
          v-if="conversions.average_revenue_per_send !== null"
          :data="{name:'average_revenue_per_send', kpi_value: conversions.average_revenue_per_send}"
          :currency="clientCurrency"
          :channel="channel"
        />
      </div>
    </div>
    <div
      v-if="displayDailyConversions"
      v-loading="loadingDailyConversions"
      class="block table mb-3"
    >
      <el-select
        v-model="currentPeriod"
        class="period-select"
        size="medium"
        @change="updatePeriod"
      >
        <el-option
          v-for="p in periods"
          :key="p"
          :label="p + `${$t('detail.days_following_sending')}`"
          :value="p"
        />
      </el-select>
      <h3>
        {{ $t("conversions.graph_per_day") }}
      </h3>
      <div class="graph-title">
        {{ $t("conversions.daily_conversions_numbers") }}
      </div>
      <div>
        <ChartBar
          :chart-data="dailyConversionNumbers"
          :options="chartConversionOptions"
        />
        <div class="legende-container">
          <GraphLegend
            :labels="dailyConversionLabels"
            prefix="conversions.labels"
            position="bottom"
          />
        </div>
      </div>

      <div class="graph-title">
        {{ $t("conversions.daily_revenue_numbers") }} {{ clientCurrency ? `(${clientCurrency})` : "" }}
      </div>
      <div>
        <ChartBar
          :chart-data="dailyConversionRevenues"
          :options="chartRevenueOptions"
        />
        <div class="legende-container">
          <GraphLegend
            :labels="dailyConversionLabels"
            prefix="conversions.labels"
            position="bottom"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import { ChartKPI, ChartBar } from '@/components/Common/Chart'
import { GraphLegend } from '@/components/Common/Graph'

export default {
  name: 'PartsCampaignConversions',
  components: { ChartKPI, GraphLegend, ChartBar },
  props: {
    channel: {
      type: String,
      required: true
    },
    conversions: {
      type: Object,
      required: true
    },
    displayDailyConversions: {
      type: Boolean,
      required: true,
    },
    loadingConversions: {
      type: Boolean,
      required: true
    },
    dailyConversions: {
      type: Object,
      default: null
    },
    loadingDailyConversions: {
      type: Boolean,
      default: false
    },
    updateDailyConversions: {
      type: Function,
      default: () => {}
    },
  },
  data () {
     return {
      periods: [30, 60 ,90],
      currentPeriod: 30,
      chartConversionOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              callback: function (value) {
                return moment(value).format("L")
              }
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {min: 0}
          }]
        },
        tooltips: {
            callbacks: {
                title: this.formatTooltipDateTitle
            }
        }
      },
      chartRevenueOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              callback: function (value) {
                return moment(value).format("L")
              }
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {min: 0}

          }]
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem, data) => {
                    var value = tooltipItem.value
                    if (value) {
                        var label = data.datasets[tooltipItem.datasetIndex].label || '';
                        value = `${label}: ${this.$options.filters.round2dec(tooltipItem.value)}${this.clientCurrency}`;
                    }
                    return value;
                },
                title: this.formatTooltipDateTitle
            }
        }
      },
      labels: [this.$t("labels.conversions"), this.$t("labels.revenues")],
     }
  },
  computed: {
    clientCurrency () {
      return this.$store.getters['session/client'].currency
    },
    dailyConversionNumbers () {
      if (this.dailyConversions != null) {
        const custom = { labels: [], datasets: [] }    
        const dailyConversions = this.dailyConversions.conversion_numbers
        custom.labels = dailyConversions.labels
        for (let i in dailyConversions.datasets) {
          const _set = dailyConversions.datasets[i]
          custom.datasets.push({
            backgroundColor: this.$options.filters.colorize(_set.label),
            label: this.$t(`conversions.labels.${_set.label}`),
            data: _set.data
          })
        }
        return custom
      }
      return {}
    },
    dailyConversionRevenues () {
      if (this.dailyConversions != null) {
        const custom = { labels: [], datasets: [] }    
        const dailyConversions = this.dailyConversions.conversion_revenues
        
        custom.labels = dailyConversions.labels
        for (let i in dailyConversions.datasets) {
          const _set = dailyConversions.datasets[i]
          custom.datasets.push({
            backgroundColor: this.$options.filters.colorize(_set.label),
            label: this.$t(`conversions.labels.${_set.label}`),
            data: _set.data
          })
        }
        return custom
      }
      return {}
    },
    dailyConversionLabels () {
      const labels = []
      if (this.dailyConversions != null) {
        const dailyConversions = this.dailyConversions.conversion_numbers
        for (let i in dailyConversions.datasets) {
          const _set = dailyConversions.datasets[i]
          labels.push(_set.label)
        }
      }
      return labels
    }
},
  methods: {
    formatTooltipDateTitle(tooltipItem, data) {
      var currentIdx = tooltipItem[0].index
      var label = data.labels[currentIdx] || '';
      var nextDateLabel = data.labels[currentIdx + 1] || ''
      if (nextDateLabel && [60, 90].indexOf(this.currentPeriod) > -1) {
        var previousDate = new Date(nextDateLabel)
        previousDate.setDate(previousDate.getDate()-1);
        return `${moment(label).format("L")} - ${moment(previousDate).format("L")}`
      }
      return moment(label).format("L")
    },
    updatePeriod(period) {
      this.$emit('update-period', period)
      this.updateDailyConversions(period)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.conversions {
  // display: flex;s
  padding-bottom: 3rem;

  .block.table {
    width: 100%;
    padding: 3rem;
    margin-bottom: 2rem;
    h3 {
      margin-bottom: 2rem;
    }
    .kpis {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 24px;
      .kpi {
        width: calc(20% - 24px);
      }
      .main-kpi {
        width: calc(50% - 24px);
        background-color: $--bg-color-large-kpi;
      }
    }
    .period-select {
      width: 220px;
      float: right;
    }
    .graph-title {
      margin-bottom: 1rem;
    }
    .legende-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .legende {
        ul {
          display: flex;
          li {
            margin: 1rem;
          }
        }
      }
    }
  }
}
</style>
