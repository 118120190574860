<template>
  <div
    class="block-param bp-trafic"
    :class="{'bp-email': channel === 'email', 'bp-sms': channel === 'sms', 'bp-landing': channel === 'landing'}"
  >
    <div class="row">
      <h2>{{ $t(`kpi.${channel}.${kpiskey}`) }}</h2>
    </div>

    <div v-if="campaign.length">
      <table class="campaign-table">
        <thead>
          <tr style="border-bottom: solid 1px #dceeee;">
            <th class="campaign_name">
              {{ $t('table.header.campaign_name') }}
            </th>
            <th class="total_visits">
              {{ $t('table.header.total_visits') }}
            </th>
            <th class="total_clicks">
              {{ $t('table.header.total_clicks') }}
            </th>
            <th class="total_submissions">
              {{ $t('table.header.total_submissions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(c, i) in campaign"
            :key="'c'+i"
          >
            <td class="campaign_name">
              <router-link :to="{ name: `${channels[c.channel_id]}-detail-overview`, params: { campaignId: c.id }}">
                {{ c.campaign_name }} / {{ `${channels[c.channel_id]}-detail` }}
              </router-link>
            </td>
            <td class="total_visits">
              {{ c.total_visits }}
            </td>
            <td class="total_clicks">
              {{ c.total_clicks }}
            </td>
            <td class="total_submissions">
              {{ c.total_submissions }}
            </td>
          </tr>
          <tr>
            <td class="campaign_name">
              {{ $t('table.content.other_trafic') }}
            </td>
            <td class="total_visits">
              {{ other.total_visits }}
            </td>
            <td class="total_clicks">
              {{ other.total_clicks }}
            </td>
            <td class="total_submissions">
              {{ other.total_submissions }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      {{ $t('kpi.landing.no_trafic') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockMultiTraffic',
  props: {
    channel: {
      type: String,
      required: true
    },
    kpiskey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      channels: [
        '', 'email', 'sms', 'push'
      ]
    }
  },
  computed: {
    campaign () {
      return this.$store.getters['landingDetail/overview'].campaign_trafic_sources
    },
    other () {
      return this.$store.getters['landingDetail/overview'].other_trafic_sources
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.bp-trafic {
  .campaign-table {
    table-layout: fixed;
    width: 100%;
    font-size: 11px;

    td {
      word-wrap: break-word;
    }
    .campaign_name {
      text-align: left;
      width: 235px;
      border-bottom: solid 1px $--color-pale-blue;
      padding-bottom: 0.35rem;
      margin-bottom: 0.35rem;
      a {
        color: $--text-dark;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        margin: 0 auto;
        line-height: 1.4;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .total_visits,
    .total_clicks,
    .total_submissions {
      width: 100px;
      text-align: center;
      border-bottom: solid 1px $--color-pale-blue;
      padding-bottom: 0.5rem;
      margin-bottom: 0.35rem;
    }
  }
}
</style>
