import Vue from 'vue'
import moment from 'moment'

const SET_GROUPS = 'SET_GROUPS'
const SET_START_DATE = 'SET_START_DATE'
const SET_END_DATE = 'SET_END_DATE'
const SET_RANGE_DATE = 'SET_RANGE_DATE'
const SET_FULL_WIDTH = 'SET_FULL_WIDTH'

const moduleSession = {
  namespaced: true,
  state: {
    defaultDate: {
      startDate: moment().subtract(1, 'month').endOf('day').format('YYYY-MM-DD'),
      endDate: moment().endOf('day').format('YYYY-MM-DD'),
    },
    search: {
      groups: [],
      startDate: moment().subtract(1, 'month').endOf('day').format('YYYY-MM-DD'),
      endDate: moment().endOf('day').format('YYYY-MM-DD'),
    },
    fullWidth: false
  },
  mutations: {
    [SET_GROUPS] (state, groups) {
      Vue.set(state.search, 'groups', groups)
    },
    [SET_START_DATE] (state, start) {
      Vue.set(state.search, 'startDate', start)
    },
    [SET_END_DATE] (state, end) {
      Vue.set(state.search, 'endDate', end)
    },
    [SET_RANGE_DATE] (state, { start, end }) {
      Vue.set(state.search, 'startDate', start)
      Vue.set(state.search, 'endDate', end)
    },
    [SET_FULL_WIDTH] (state, value) {
      state.fullWidth = value
    }
  },
  actions: {
    selectGroups ({ commit }, groups) {
      commit(SET_GROUPS, groups)
    },
    setStartDate ({ commit }, startDate) {
      commit(SET_START_DATE, startDate)
    },
    setEndDate ({ commit }, endDate) {
      commit(SET_END_DATE, endDate)
    },
    setRangeDate ({ commit }, rangeDate) {
      commit(SET_RANGE_DATE, rangeDate)
    },
    setFullWidth ({ commit }, fullWidth) {
      commit(SET_FULL_WIDTH, fullWidth)
    }
  },
  getters: {
    defaultDate: state => state.defaultDate,
    selectedGroups: state => state.search.groups,
    rangeDate: state => {
      return { start: state.search.startDate, end: state.search.endDate}
    },
    fullWidth: state => state.fullWidth
  }
}

export default moduleSession
