<template>
  <div class="block-param bp-graph-countries">
    <div class="row">
      <h2>{{ $t(`kpi.sms.sendings_by_countries`) }}</h2>
      <el-button
        type="secondary"
        @click="viewComparison"
      >
        {{ $t("kpi.sms.view_comparison") }}
      </el-button>
    </div>


    <div class="block-countries">
      <div class="chart-container">
        <ChartBar
          :chart-data="chartData"
          :options="chartOptions"
          :width="500"
          :height="200"
        />
      </div>
      <div class="legende-container">
        <GraphAutoLegend
          :labels="chartLabels"
          position="bottom"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ChartBar } from '@/components/Common/Chart'
import { GraphAutoLegend } from '@/components/Common/Graph'

export default {
  name: 'BlockGraph',
  components: { ChartBar, GraphAutoLegend },
  props: {
    kpisvalue : {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex]
              const currentValue = dataset.data[tooltipItem.index]
              return currentValue
            },
            title (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem[0].datasetIndex]
              const typeLabel = dataset.label
              const countryLabel = tooltipItem[0].label
              return `${countryLabel}, ${typeLabel}`
            }
          }
        },
        scales: {
          yAxes: [{
            stacked: false,
            ticks: { min: 0 }
          }]
        }
      }
    }
  },
  computed: {
    chartData() {     
      const localeCountryName = new Intl.DisplayNames(
        [this.$i18n.locale], { type: 'region' }
      );
      const data = {}
      if (this.kpisvalue.datasets) {
        if (this.kpisvalue.labels.length <= 5) {
          data.datasets = this.kpisvalue.datasets.map((set, index) => (
            {
                label: this.$t(`kpi.sms.${set.label}_legend`), 
                data: set.data.slice(0, 5), 
                backgroundColor: this.$options.filters.autocolorize(index),
            }
          ))
          data.labels = this.kpisvalue.labels.slice(0, 5).map(countryCode => (
              localeCountryName.of(countryCode)
          ))
        } else { 
            data.datasets = this.kpisvalue.datasets.map((set, index) => (
              {
                label: this.$t(`kpi.sms.${set.label}_legend`),
                data: [
                  ...set.data.slice(0, 4), 
                  set.data.slice(4).reduce((prev, next) => prev + next, 0)
                  ],
                backgroundColor: this.$options.filters.autocolorize(index),
              })
            ),
            data.labels = [
              ...this.kpisvalue.labels.slice(0, 4).map(countryCode => (
                localeCountryName.of(countryCode)
              )), 
              this.$t(`kpi.sms.others_countries`)]
        }
      }
      return data
    },
    chartLabels() {
      let labels = []
      if (this.kpisvalue.datasets) {
        labels.push(...this.kpisvalue.datasets.map(set => (
            {
              label: this.$t(`kpi.sms.${set.label}_legend`),
            })
          ))
      }
      return labels
    }
  },
  methods: {
    viewComparison() {
      let campaignId = parseInt(this.$route.params.campaignId)
      if (isNaN(campaignId)) {
        campaignId = this.$route.params.campaignId
      }
      this.$store.dispatch('smsCampaign/selectSplit', [{
        id: '',
        from: this.$t('detail.by_countries'),
        name: '',
        type: 'countries',
        cat: 'countries'
      }])
      this.$router.push({
        name: 'sms-detail-table',
        params: { campaignId }
      })

    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.bp-graph-countries {
  width: 640px;
  .row {
    margin-bottom: 1rem;
  }
}
.block-countries {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .chart-container {
    position: relative;
    width: 100%;
    margin: 0 2rem;
    border: none;
    padding: 1rem;
  }
  .no-data {
    position: relative;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $--text-light;
    border-radius: 50%;

    strong {
      text-align: center;
      line-height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 30px;
      margin-left: -50%;
      margin-top: -15px;
    }
    &::before {
      content: "";
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 50%;
      background-color: white;
      border-radius: 50%;
    }
  }
  .legende-container {
    flex: 0.8;
    .legende {
      justify-content: start;
    }
  }
}
</style>
