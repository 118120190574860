<template>
  <div class="img-radio">
    <el-radio
      v-for="img in items"
      :key="img.id"
      v-model="selectedImg"
      :label="img.id"
    >
      <img
        class="img-radio__img"
        :src="img.src"
        alt="Radio image"
      >
      <span
        v-show="selectedImg === img.id"
        class="img-radio__check"
      >
        <span class="material-icons">check</span>
      </span>
    </el-radio>
  </div>
</template>

<script>
export default {
  name: 'ImageRadio',
  props:{
    items: {
      type: Array,
      default: () => []
    },
    currentValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    selectedImg: {
      get () {
        return this.currentValue
      },
      set (img) {
        this.$emit('updated', img)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.img-radio {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ::v-deep .el-radio {
    margin: 0 0 10px 0;

    .el-radio__input {
      display: none;
    }

    .el-radio__label {
      display: block;
      padding: 0;
      background-color: #fff;
      font-size: 0px;
    }
  }

  .img-radio__img {
    width: 165px;
    height: 85px;
    opacity: 1;
    transition: all .4s;
    -webkit-transition: all .4s;
  }

  .el-radio:hover .img-radio__img,
  .el-radio.is-checked .img-radio__img
  {
    outline: 2px solid $--color-primary;
    outline-offset: -2px;
    opacity: .7;
  }

  .el-radio.is-checked .el-radio__label {
    position: relative;

    .img-radio__check {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: $--color-primary;
      border-radius: 50%;
      display: block;
      height: 14px;
      width: 14px;
      text-align: center;

      .material-icons {
        font-size: 10px;
        color: #fff;
        line-height: 14px;
      }
    }
  }
}
</style>
