<template>
  <div class="block-param bp-in-landing bp-landing">
    <div class="row">
      <h2>{{ $t(`kpi.landing.${kpiskey}`) }}</h2>
    </div>

    <div class="infos-container">
      <!-- Important KPIS -->
      <div class="kpis">
        <ChartKPI
          v-for="impKpi in importantKpiList(kpisvalue)"
          :key="impKpi.key"
          :channel="'landing'"
          :data="{
            name: impKpi.name,
            kpi_value: impKpi.value
          }"
        />
      </div>

      <div class="submissions">
        <div class="picto">
          <i class="material-icons md-24">assignment_turned_in</i>
        </div>
        <div v-if="has_form">
          <div class="k_value">
            {{ formattingValue(getSubmissions(kpisvalue)) }}
          </div>
          <div class="k_label">
            {{ $t('kpi.landing.total_submissions') }}
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
            >
              <div slot="content">
                {{ $t('kpi.landing.total_submissions_tip') }}
              </div>
              <i class="material-icons md-12 help">help_outline</i>
            </el-tooltip>
          </div>
        </div>
        <div v-else>
          <!-- <div class="k_value">-</div> -->
          <div class="k_label">
            {{ $t('kpi.landing.no_form') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChartKPI } from '@/components/Common/Chart'

export default {
  name: 'BlockInLanding',
  components: { ChartKPI },
  props: {
    kpiskey: {
      type: String,
      required: true
    },
    kpisvalue: {
      type: Array,
      default: () => []
    },
    kpis: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    has_form() {
      return this.$store.getters['landingDetail/headerMeta'].list_uid
    }
  },
  methods: {
    /**
     * Check if they key is a KPI
     * @param {String} key (kpi key)
     * @return {Boolean}
     */
    // isKpi(key) {
    //   return !this.notKpi.includes(key)
    // },
    /**
     * Check if it's an important KPI
     * @param  {String} key (kpi key)
     * @return {Boolean}
     */
    isImportantKpi (key) {
      return this.kpis.important.includes(key)
    },
    /**
     * Filter kpis list for important ones
     * @param  {Array} kpis (collection of kpis object)
     * @return {Array} (Should not exeed size of 2)
     */
    importantKpiList (kpis) {
      return kpis.filter(kpi => this.isImportantKpi(kpi.key))
    },
    /**
      * Filter kpis list for important ones
      * @param  {Array} kpis (collection of kpis object)
      * @return {Object} (kpi total_submissions)
      */
    getSubmissions (kpis) {
      return kpis.filter(kpi => kpi.key === 'total_submissions')[0] || []
    },
    /**
     * Formatting values to make them human readable
     */
    formattingValue (kpi) {
      if (!Object.prototype.hasOwnProperty.call(kpi, 'key')) {
        /* eslint-disable no-console */
        console.error('KPI without key', kpi)
        /* eslint-enable no-console */
        return
      }
      const isPercentage = kpi.key.includes('_rate') && kpi.value !== null
      const isNumber = !isPercentage && !isNaN(kpi.value) && kpi.value !== null
      const isNotApplicable = kpi.value === null

      switch (true) {
        case isPercentage:
          return `${this.$options.filters.round2dec(kpi.value * 100)}%`
        case isNumber:
          return this.$options.filters.formatNumber(kpi.value)
        case isNotApplicable:
          return 'N/A'
        default:
          return kpi.value
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.bp-in-landing {
  .infos-container {
    display: flex;

    .kpis {
      flex: 1;
      display: flex;
      .kpi {
        margin-right: 1rem;
      }
    }

    .submissions {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: $--bg-color-large-kpi;
      border-radius: 3px;
      text-align: center;
      .picto {
        color: $--color-secondary;
      }
      .k_value {
        font-size: 18px;
        font-weight: 600;
        margin: 0.5rem 0;
      }
      .k_label {
        color: $--text-light;
        font-size: 13px;
      }
    }
  }
}
</style>
