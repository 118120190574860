<template>
  <header class="header-qr-list page-header">
    <div class="row">
      <div class="group-left">
        <Filters
          :search="search"
          :search-placeholder="$t('qr.search')"
          :selected-filters="selectedFilters"
          @update-search="(e) => $emit('update-search', e)"
          @unselect-filter="unselectFilter"
          @unselect-all="unselectAllFilters"
        >
          <!-- GROUPS -->
          <FilterCascade
            name="groups"
            :color="filterGroupsColor"
            :values="dataGroups.selected"
            :options="dataGroups.options"
            @updated="updateSelectedGroups"
          />
        </Filters>
      </div>
      <div class="group-right">
        <DownloadButton
          :loading="downloadLoading"
          :is-list="true"
          @click-download="(e) => $emit('download', e)"
        />
      </div>
    </div>
    <div class="row statistics">
      <div class="hqr">
        <p v-if="kpis">
          {{ kpis.total_qr_codes }} {{ $tc('qr.codes_created', kpis.total_qr_codes) }}
        </p>
        <p v-if="kpis">
          {{ kpis.total_scans }} {{ $tc('qr.scans_total', kpis.total_scans) }}
        </p>
      </div>
    </div>
  </header>
</template>

<script>
import { DownloadButton } from '@/components/Common/Form'
import {
  filterGroups
} from '@/mixins'
import {
  Filters,
  FilterCascade
} from '@/components/Common/Form'

export default {
  name: 'PartsHeaderQR',
  components: { DownloadButton, Filters, FilterCascade },
  mixins: [ filterGroups ],
  props: {
    search: {
      type: String,
      required: true
    },
    kpis: {
      type: Object,
      required: true
    },
    downloadLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataGroups: {
        options: [],
        selected: []
      }
    }
  },
  computed: {
    groups () {
      return this.$store.getters['session/groups']
    },
    selectedGroups () {
      return this.$store.getters['app/selectedGroups']
    },
    /**
     * Get all filters selected options to generate tags
     */
    selectedFilters () {
      return [
        ...this.dataGroups.selected.map((tag, index) => ({
          type: 'groups', color: this.filterGroupsColor, tag, index
        })),
      ]
    },
  },
  mounted () {
    // Initialize filters with defaults selected options
    this.dataGroups = this.initSelectedGroups(this.groups, this.selectedGroups)
  },
  methods: {
    /**
     * Clear all selected values
     */
    unselectAllFilters () {
      this.dataGroups.selected = []
      this.$emit('clear-selected-filters')
    },
    /**
     * On filter tag deletion, remove it from list of selected then trigger update
     * related to the filter type
     */
    unselectFilter (filter) {
      switch (filter.type) {
        case 'groups': {
          this.updateSelectedGroups(
            this.dataGroups.selected.filter((elem, index) => index !== filter.index)
          )
          break
        }
      }
    },
    updateSelectedGroups (values) {
      this.dataGroups.selected = values
      this.$emit('updated-group', this.formatGroupsCascader(values))
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
.header-qr-list {
  .hqr {
    border-radius: 3px;
    padding: 1rem;
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 250px;
    background-color: $--color-header-detail-darkgreen-bg;
    background-image: url(~@/assets/svg/pictos/qrcodeheader.svg),
      url(~@/assets/svg/lp-green-bg.svg);
    background-repeat: no-repeat;
    background-position: 70px 60%, -43px;
    background-size: auto auto, 108%;

    p {
      font-size: 23px;
      font-weight: 600;
      color: white;
    }
  }
}
</style>
