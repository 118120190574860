
import store from '@/store'

// Check if CRM is set, else redirect to report page
const CRMGuard = function (to, from, next) {
  if (!store.getters['session/hasCRMDataBase'] && to.matched.some(record => record.meta.requiresCRM)) {
    next('/reports')
  } else {
    next()
  }
}

export default CRMGuard
