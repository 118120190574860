<template>
  <div class="campaign-conversions">
    <el-select
      v-model="conversionType"
      v-loading="loadingConversions"
      placeholder="Select"
      @change="updateConversionFilter"
    >
      <el-option
        :label="$t(`conversions.labels.all`)"
        value="all"
      />
      <el-option
        v-for="(type, index) in conversionTypes"
        :key="index"
        :label="$t(`conversions.labels.${type.name}`)"
        :value="type.name"
      />
    </el-select>
    <CampaignConversions
      channel="push"
      :conversions="conversions"
      :loading-conversions="loadingConversions"
      :display-daily-conversions="campaignType === 'one_shot'"
      :daily-conversions="dailyConversions"
      :loading-daily-conversions="loadingDailyConversions"
      :update-daily-conversions="updateDailyConversions"
      @update-period="updatePeriod"
    />
  </div>
</template>

<script>
import config from '@/config'
import { isoDateString } from '@/helpers/isoDate'
import { CampaignConversions } from '@/components/Common/Campaign'

export default {
  name: 'ViewsPushDetailConversions',
  components: {
    CampaignConversions
  },
  data() {
    return {
      conversionType: 'all',
      currentPeriod: null,
    }
  },
  computed: {
    conversions () {
      return this.$store.getters['conversions/items']
    },
    conversionTypes() {
      return this.$store.getters['conversions/conversionTypes']
    },
    loadingConversions () {
      return this.$store.getters['conversions/loading']
    },
    dailyConversions () {
      return this.$store.getters['conversions/dailyConversions']
    },
    loadingDailyConversions () {
      return this.$store.getters['conversions/loading']
    },
    campaign () {
      return this.$store.getters['pushCampaign/campaign'] || {}
    },
    campaignType () {
      if (this.campaign) {
        if (this.campaign.campaign_type === config.PUSH_CHANNEL_TAGS.automatic) {
          return 'automatic'
        } else if (this.campaign.campaign_type === config.PUSH_CHANNEL_TAGS.transactional) {
          return 'transactional'
        }
        return 'one_shot'
      }
      return ''
    },
    currentSplit () {
      return this.$store.getters['pushCampaign/currentSplit']
    },
    rangeDate () {
      const { start, end } = this.$store.getters['app/rangeDate']
      const tz = this.$store.getters['session/user'].timezone || 'UTC'

      return {
        start: isoDateString(start, tz),
        end: isoDateString(end, tz, true),
      }
    },
  },
  methods: {
    updateDailyConversions(period) {
      const params = {
        campaign_id: this.campaign.id,
        channel_id: config.CHANNELS.PUSH_NOTIFICATION,
        start_date: this.rangeDate.start,
        end_date: this.rangeDate.end,
        sending_date: this.campaign.start_date,
        period: period
      }
      if (this.conversionType !== 'all') {
        const type = this.conversionType.toUpperCase()
        params['conversion_type_id'] = config.CONVERSION_TYPES[type]
      }
      if (this.currentSplit.length && this.currentSplit[0].type) {
        params['split'] = this.currentSplit.map(item => item.id).join('|')
        params['split_type'] = this.currentSplit[0].type
        if ('extra_data_id' in this.currentSplit[0]) {
          params['extra_data_id'] = this.currentSplit[0].extra_data_id
        }
      }
      this.$store.dispatch('conversions/getDailyConversions', params)
      return
    },
    updateConversionFilter(type) {
      this.$store.dispatch('conversions/setCurrentFilter', type)
      this.updateDailyConversions(this.currentPeriod)
    },
    updatePeriod(period) {
      this.currentPeriod = period
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign-conversions {
  display: flex;
  flex-direction: column;
  .el-select {  
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 20px;
  }
}
</style>

