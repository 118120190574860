<template>
  <el-select
    v-model="firstCurrentSplit"
    :placeholder="$t('global.select')"
    :no-data-text="$t('detail.no_data')"
    :collapse-tags="false"
    value-key="type"
    class="segment-selector"
  >
    <el-option
      v-for="(split, index) in splitMenu"
      :key="index"
      :label="split.name"
      :value="split"
      :disabled="split.disabled < 1"
    />
  </el-select>
</template>

<script>
export default {
  name: 'SegmentSelector',
  props: {
    currentSplit: {
      type: Array,
      required: true
    },
    headerSplits: {
      type: Object,
      required: true
    }
  },
  computed: {
    firstCurrentSplit: {
      get () {
        const cs0 = this.currentSplit[0]
        if (cs0 && cs0.from) {
          const fullname = cs0.cat === 'extra_data'
            ? cs0.from
            : `${this.$t('detail.' + cs0.cat)} (${this.headerSplits[cs0.cat].total})`
          const currentSplit = {
            name: fullname,
            type: cs0.type,
            cat: cs0.cat,
            disabled: this.headerSplits[cs0.cat].total
          }
          return currentSplit
        }
        return ''
      },
      set (split) {
        let splits = []
        if (split.cat === 'sendings') {
          splits.push({
            id: split.name,
            from: split.name,
            name: split.name,
            type: 'sending',
            cat: 'sendings'
          })
        } else if (split.cat === 'countries') {
          splits.push({
            id: '',
            from: split.name,
            name: '',
            type: split.type,
            cat: split.cat
          })
        } else if (split.cat === 'extra_data') {
          for (let i in split.values) {
            let splitData = {
              from: split.name,
              name: split.values[i].name,
              id: split.values[i].id,
              type: split.type,
              cat: split.cat,
              extra_data_id: split.extra_data_id
            }
            splits.push(splitData)
          }
        } else {
          for (let i in this.headerSplits[split.cat].objects) {
              splits.push({
                from: split.name,
                name: this.headerSplits[split.cat].objects[i].name,
                id: this.headerSplits[split.cat].objects[i].id,
                type: split.type,
                cat: split.cat
              })
          }
        }
        this.$emit('updated-splits', splits)
      }
    },
    splitMenu () {
      let menu = []

      if (this.headerSplits.segments) {
        menu.push({
          id: this.$tc('detail.segments', 2),
          name: `${this.$tc('detail.segments', 2)} (${this.headerSplits.segments.total})`,
          type: 'segment',
          cat: 'segments',
          disabled: this.headerSplits.segments.total
        })
      }
      if (this.headerSplits.versions) {
        menu.push({
          id: this.$tc('detail.versions', 2),
          name: `${this.$tc('detail.versions', 2)} (${this.headerSplits.versions.total})`,
          type: 'version',
          cat: 'versions',
          disabled: this.headerSplits.versions.total
        })
      }
      if (this.headerSplits.sendings) {
        menu.push({
          id: this.$tc('detail.sendings', 2),
          name: `${this.$t('detail.sendings')} (${this.headerSplits.sendings.total})`,
          type: 'sending',
          cat: 'sendings',
          disabled: this.headerSplits.sendings.total
        })
      }
      if (this.headerSplits.typologies) {
        menu.push({
          id: this.$tc('detail.typologies', 2),
          name: `${this.$tc('detail.typologies', 2)} (${this.headerSplits.typologies.total})`,
          type: 'typology',
          cat: 'typologies',
          disabled: this.headerSplits.typologies.total
        })
      }
      if (this.headerSplits.countries) {
        menu.push({
          name: `${this.$t('detail.by_countries')} (${this.headerSplits.countries.total})`,
          type: 'countries',
          cat: 'countries',
          disabled: this.headerSplits.countries.total
        })
      }
      if (this.headerSplits.extra_data) {
        for (let i in this.headerSplits.extra_data.objects) {
          let extraData = this.headerSplits.extra_data.objects[i]
          let extraDataLabel = this.$tc(`extra_data.${extraData.name}`, 2)
          menu.push({
            id: extraData.id,
            name: `${extraDataLabel} (${extraData.values.length})`,
            values: extraData.values,
            type: 'extra_data',
            cat: 'extra_data', disabled: extraData.values.length,
            'extra_data_id': extraData.id
          })
        }
      }
      return menu
    }
  }
}
</script>

<style lang="scss">
</style>
