<template>
  <div class="table-landing">
    <el-table
      :data="data"
      stripe
      style="width: 100%"
      :default-sort="{prop: 'first_publish_date', order: 'descending'}"
      @sort-change="orderBy"
      @row-click="goToDetail"
      @cell-mouse-enter="showOpen"
      @cell-mouse-leave="currentOverId = ''"
    >
      <!-- NO DATA -->
      <div slot="empty">
        <TableEmptyData :empty-label="emptyLabel" />
      </div>

      <!-- GROUP -->
      <el-table-column
        width="60"
        prop="group"
        :label="$t('table.header.group')"
      >
        <template slot-scope="scopeg">
          <span
            v-if="scopeg.row.group"
            class="pictify"
          >
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              :content="scopeg.row.group"
            >
              <avatar-holder
                :avatar-url="logifier(scopeg.row.group)"
                :display-name="scopeg.row.group"
                :width="27"
                :height="27"
              />
            </el-tooltip>
          </span>
          <span v-else>
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              :content="$t('table.content.no_group')"
            >
              <span class="pictify-0" />
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <!-- STATUS published / unpublished -->
      <el-table-column
        prop="published"
        width="36"
      >
        <template slot-scope="scopep">
          <span
            class="led"
            :class="[scopep.row.published ? 'led_published' : 'led_unpublished', 'led']"
          />
        </template>
      </el-table-column>

      <!-- NAME -->
      <el-table-column
        width="300"
        prop="lp_name"
        :label="$t('table.header.landing_name')"
      >
        <template slot-scope="scopec">
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            :content="scopec.row.lp_name"
          >
            <strong>{{ scopec.row.lp_name | truncate(40) }} </strong>
          </el-tooltip>
        </template>
      </el-table-column>

      <!-- DATE -->
      <el-table-column
        align="center"
        sortable="custom"
        prop="first_publish_date"
        :formatter="formatDate"
        :label="$t('table.header.published_date')"
      />

      <!-- TOTAL VISIT -->
      <el-table-column
        align="right"
        sortable="custom"
        prop="total_visits"
        :formatter="formatVisits"
        :label="$t('table.header.total_visits')"
      />

      <!-- SUBMISSIONS -->
      <el-table-column
        align="right"
        sortable="custom"
        prop="total_submissions"
        :formatter="formatSubmissions"
        :label="$t('table.header.submissions')"
      />

      <!-- LINK -->
      <el-table-column align="right">
        <template slot-scope="scopel">
          <span
            v-if="scopel.row.published"
            class="openpage"
            :class="{'hidden': currentOverId !== scopel.row.id}"
          >
            <i class="material-icons md-16">open_in_new</i>
            {{ $t('landing.open_page') }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Vue from 'vue'
import AvatarHolder from '@/components/Common/AvatarHolder'
import { TableEmptyData } from '@/components/Common/Table'
import { logifier } from '@/mixins'

export default {
  name: 'ListLanding',
  components: {
    AvatarHolder,
    TableEmptyData
  },
  mixins: [logifier],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    emptyLabel: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      currentOverId: ''
    }
  },
  computed: {
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    }
  },
  methods: {
    goToDetail (row, column) {
      // GO TO DETAIL
      if (column.property !== undefined || !row.published) {
        this.$router.push({ name: 'landing-detail-overview', params: { landingId: row.id }})
      }

      // OPEN THE LANDING IN NEW WINDOW BUT NO REDIRECT TO DETAIL
      if (column.property === undefined && row.published) {
        window.open(row.lp_url)
      }
    },
    orderBy (val) {
      let orderBy = null
      if (val.order) {
        orderBy = val.prop
        if (val.order === 'descending') {
          orderBy = `-${orderBy}`
        }
      }
      this.$emit('sort-by', orderBy)
    },
    formatDate (row) {
      return Vue.options.filters.formatDate(row.first_publish_date, this.$i18n.locale, this.userTimezone, this.$t('global.date_format'))
    },
    formatVisits (row) {
      return Vue.options.filters.formatNumber(row.total_visits)
    },
    formatSubmissions (row) {
      return row.total_submissions != null ? row.total_submissions : '-'
    },
    showOpen (row) {
      this.currentOverId = row.id
    }
  }
}
</script>

<style lang='scss'>
.table-landing table th {
  padding-left: 10px;
}
.table-landing {
  .openpage {
    color: #858f96;
    i {
      color: #858f96 !important;
    }
  }
}
</style>
