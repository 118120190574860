import moment from 'moment'
import Vue from 'vue'

import APIException from './exceptions'
import i18n from '@/languages/i18n'


const SET_HEADER_META = 'SET_HEADER_META'
const SET_OVERVIEW = 'SET_OVERVIEW'
const SET_PREVIEWS = 'SET_PREVIEWS'
const SET_LOADING = 'SET_LOADING'
const SET_REACTIONS = 'SET_REACTIONS'
const SET_DEVICES = 'SET_DEVICES'
const SET_HOSTS = 'SET_HOSTS'
const SET_CONTACTLY_URL = 'SET_CONTACTLY_URL'
const SET_DATES = 'SET_DATES'
const SET_DOWNLOAD_LOADING = 'SET_DOWNLOAD_LOADING'
const SET_START_DATE = 'SET_START_DATE'
const SET_END_DATE = 'SET_END_DATE'
const SET_DEFAULT_DATE = 'SET_DEFAULT_DATE'
const SET_LOADING_PREVIEW = 'SET_LOADING_PREVIEW'

function setMidnight(dateStr) {
  let date = new Date(dateStr)
  date.setHours(0, 0, 0, 0)
  return date.toJSON()
}

function exportLandingDetailFilename(landing, client, lang) {
  // NomClient_NomCampagne_AAAAMMJJ / ClientName_CampaignName__YYYYMMDD
  const clientName = client.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  const landingName = landing.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  let dateFmt = 'YYYYMMDD'
  if (lang === 'fr') {
    dateFmt = 'DDMMYYYY'
  }
  return `${clientName}_${landingName}_${moment().format(dateFmt)}.xlsx`
}

const moduleLandingDetail = {
  namespaced: true,
  state: {
    defaultDate: {},
    header_meta: {}, // lp_name, campaign_type, group, sending_date, subject, expeditors []
    overview: {}, // performances {}, campaign_trafic_sources {}, other_trafic_sources {}
    reactions: [], // datasets []
    devices: {}, // clicks [], opens []
    previews: [], // mobile_url, desktop_url
    loading: false,
    hosts: [],
    contactly_url: '',
    start_date: null,
    end_date: null,
    previewLoading: false,
    downloadLoading: false
  },
  mutations: {
    [SET_HEADER_META] (state, val) {
      state.header_meta = val
    },
    [SET_OVERVIEW] (state, val) {
      state.overview = val
    },
    [SET_PREVIEWS] (state, val) {
      state.previews = val
    },
    [SET_LOADING] (state, val) {
      state.loading = val
    },
    [SET_REACTIONS] (state, val) {
      state.reactions = val
    },
    [SET_DEVICES] (state, val) {
      state.devices = val
    },
    [SET_HOSTS] (state, val) {
      state.hosts = val
    },
    [SET_CONTACTLY_URL] (state, val) {
      state.contactly_url = val
    },
    [SET_DATES] (state, { startDate, endDate }) {
      state.start_date = startDate
      state.end_date = endDate
    },
    [SET_DEFAULT_DATE] (state, dates) {
      Vue.set(state, 'defaultDate', {
        start: dates[0],
        end: dates[1]
      })
      Vue.set(state, 'start_date', dates[0])
      Vue.set(state, 'end_date', dates[1])
    },
    [SET_START_DATE] (state, date) {
      state.start_date = date
    },
    [SET_END_DATE] (state, date) {
      state.end_date = date
    },
    [SET_DOWNLOAD_LOADING] (state, loader) {
      Vue.set(state, 'downloadLoading', loader)
    },
    [SET_LOADING_PREVIEW] (state, val) {
      Vue.set(state, 'previewLoading', val)
    }
  },
  actions: {
    reset ({ commit }) {
      commit(SET_PREVIEWS, [])
      commit(SET_REACTIONS, [])
      commit(SET_HOSTS, [])
      commit(SET_HEADER_META, {})
      commit(SET_OVERVIEW, {})
      commit(SET_DEVICES, {})
    },
    async setDates ({ commit }, dates) {
      commit(SET_DATES, {
        startDate: dates[0],
        endDate: dates[1]
      })
    },
    setStartDate ({ commit }, date) {
      commit(SET_START_DATE, date)
    },
    setEndDate ({ commit }, date) {
      commit(SET_END_DATE, date)
    },
    async getHeaderMeta ({ commit }, id) {
      try {
        const response = await Vue.axios.get(`/api/landing_pages/${id}`)
        if (response.status >= 300) { throw response }

        commit(SET_HEADER_META, response.data)
        // Set default date
        let startDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
        if (response.data.first_publish_date) {
          startDate = moment(response.data.first_publish_date).format('YYYY-MM-DD')
        }
        commit(SET_DEFAULT_DATE, [startDate, moment().format('YYYY-MM-DD')])
        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    async getOverview ({ commit }, { id, params }) {
      try {
        const response = await Vue.axios.get(`/api/landing_pages/${id}/overview`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_OVERVIEW, response.data)
      } catch (err) {
        return new APIException(err)
      }
    },
    async getReactions ({ commit }, { id, params }) {
      if (!params.step) {
        params.step = 1
      }
      params['start_date'] = setMidnight(params.start_date)
      params['end_date'] = setMidnight(params.end_date)
      try {
        const response = await Vue.axios.get(`/api/landing_pages/${id}/reactions`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_REACTIONS, response.data.datasets)
      } catch (err) {
        return new APIException(err)
      }
    },
    async getDevices ({ commit }, { id, params }) {
      try {
        const response = await Vue.axios.get(`/api/landing_pages/${id}/devices`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_DEVICES, response.data)
      } catch (err) {
        return new APIException(err)
      }
    },
    async getContactlyURL ({ commit }, { id, params }) {
      try {
        const response = await Vue.axios.get(`/api/landing_pages/${id}/contactly`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_CONTACTLY_URL, response.data.url)
      } catch (err) {
        return new APIException(err)
      }
    },
    async getHosts ({ commit }, params) {
      try {
        const response = await Vue.axios.get(`/api/landing_pages/${params.id}/hosts`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_HOSTS, response.data)
      } catch (err) {
        return new APIException(err)
      }
    },
    async exportLanding ({ commit, state, rootGetters }, { id, params }) {
      const clientName = rootGetters['session/client'].name
      const landingName = state.header_meta.lp_name
      const filename = exportLandingDetailFilename(landingName, clientName, i18n.locale)
      commit(SET_DOWNLOAD_LOADING, true)

      try {
        const xlsx = await Vue.axios({
          url: `/api/landing_pages/${id}/export`,
          method: 'POST',
          responseType: 'blob',
          params: params
        })
        const blob = new Blob([xlsx.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
        })

        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename)
        } else {
          const link = document.createElement('a')
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      } catch (err) {
        return new APIException(err)
      } finally {
        commit(SET_DOWNLOAD_LOADING, false)
      }
    },
    async exportSplits ({ state, rootGetters }, params) {
      const clientName = rootGetters['session/client'].name
      const xlsx = await this.$axios({
        url: `/api/landing_pages/${params.id}/splits/export`,
        method: 'POST',
        responseType: 'blob',
        params: params
      })
      const blob = new Blob([xlsx.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      })

      function exportFilename(lang) {
        const lpName = state.header_meta.lp_name.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
        const clientName = clientName.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
        let dateFmt = 'YYYYMMDD'
        let splitView = 'TableView'

        if (lang === 'fr') {
          dateFmt = 'DDMMYYYY'
          splitView = 'VueTableau'
        }
        // Nom du fichier : NomClient_SplitView_NomCampagne_AAAAMMJJ / ClientName_SplitView_CampaignName__YYYYMMDD
        return `${clientName}_${splitView}_${lpName}_${moment().format(dateFmt)}.xlsx`
      }

      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportFilename(clientName, this.app.i18n.locale))
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', exportFilename(clientName, this.app.i18n.locale))
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    },
    async getPreviews({ commit }, id) {
      commit(SET_LOADING_PREVIEW, true)
      try {
        const response = await Vue.axios.get(`/api/landing_pages/${id}/previews`)
        if (response.status >= 300) { throw response }

        commit(SET_PREVIEWS, response.data)
      } catch (err) {
        return new APIException(err)
      } finally {
        commit(SET_LOADING_PREVIEW, false)
      }
    },
  //   async updateCampaign({ dispatch, state, commit }, params) {
  //     commit(SET_LOADING, true)
  //     dispatch('getPreviews', params)
  //     await dispatch('getOverview', {
  //       id: params.id,
  //       start_date: start,
  //       end_date: end
  //     })
  //     await dispatch('getReactions', {
  //       id: params.id,
  //       start_date: start,
  //       end_date: end
  //     })
  //     await dispatch('getDevices', {
  //       id: params.id,
  //       start_date: start,
  //       end_date: end
  //     })

  //     if (state.header_meta.list_uid) {
  //       await dispatch('getContactlyURL', params)
  //     }
  //     commit(SET_LOADING, false)
  //   }
  },
  getters: {
    chartClicks: state => state.devices.clicks,
    chartOpens: state => state.devices.opens,
    firstPublishedDate: state => state.header_meta.first_publish_date,
    devices: state => state.devices,
    previews: state => state.previews,
    startDate: state => state.start_date,
    reactions: state => state.reactions,
    overview: state => state.overview,
    endDate: state => state.end_date,
    headerMeta: state => state.header_meta,
    contactlyUrl: state => state.contactly_url,
    downloadLoading: state => state.downloadLoading,
    defaultDate: state => state.defaultDate,
    previewLoading: state => state.previewLoading
  }
}

export default moduleLandingDetail
