<template>
  <div class="page_admin">
    <header class="page-header">
      <h1>{{ $t('admin.title') }}</h1>
    </header>
    <main class="page_content">
      <el-tabs
        :value="$route.name"
        @tab-click="handleClickTabs"
      >
        <el-tab-pane
          :label="$t('admin.nav.reports')"
          name="admin-reports"
        >
          <ViewAdminReports />
        </el-tab-pane>
      </el-tabs>
    </main>
  </div>
</template>

<script>

import { ViewAdminReports } from '@/components/Views'

export default {
  name: 'ContainerAdmin',
  components: {
    ViewAdminReports,
  },
  async created () {
    this.$store.dispatch('group/listGroups')
  },
  methods: {
    handleClickTabs (tab) {
      if (tab.name === this.$route.name) {
        return
      }
      this.$router.push({ name: tab.name })
    }
  }
}
</script>

<style lang="scss">

@import "@/assets/styles/_variables.scss";

.page_admin {
  max-width: $--max-width;
  padding: 0 50px;
  .page_content {
    ::v-deep .el-tabs__nav {
      padding: 20px 0;
    }
  }
  .page-header{
    width: 100%;
    margin: auto;
  }
  .el-tabs__nav-scroll {
    margin: auto;
    width: 100%;
  }
  .el-tabs__content {
    margin: auto;
    width: 100%;
  }
  .el-tabs__item {
    height: auto;
    padding-top: 29px;
    padding-bottom: 29px;
    line-height: normal;
  }
}
</style>
