import Vue from 'vue'

const SET_RECIPIENTS = 'SET_RECIPIENTS'
const SET_LOADING = 'SET_LOADING'

const moduleRecipients = {
  namespaced: true,
  state: {
    recipients: [],
    loading: false
  },
  mutations: {
    [SET_RECIPIENTS] (state, recipients) {
      Vue.set(state, 'recipients', recipients)
    },
    [SET_LOADING] (state, loading) {
      Vue.set(state, 'loading', loading)
    }
  },
  actions: {
    getRecipients ({ commit }, { params }) {
      commit(SET_LOADING, true)
      const url = '/api/recipients'
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params: params })
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status != 200) {
              reject(response)
            }
            commit(SET_RECIPIENTS, response.data.objects)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    },
    async deleteRecipient ({ commit }, recipientId) {
      const url = `/api/recipients/${recipientId}`
      commit(SET_LOADING, true)
      return new Promise((resolve, reject) => {
        Vue.axios.delete(url)
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status >= 300) {
              reject(response)
            }
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error.response ? error.response : error)
          })
      })
    }
  },
  getters: {
    recipients: state => state.recipients,
    loading: state => state.loading
  }
}

export default moduleRecipients
