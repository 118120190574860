<template>
  <div class="filters-bar">
    <div class="filters-bar__modifiers">
      <div
        ref="filters"
        class="filters-bar__modifiers__left"
        :class="{ 'show-all': showAll }"
      >
        <slot />
        <el-button
          v-if="!disableMoreFilter"
          type="text"
          class="mr-2 ml-1 link-button"
          @click="showAll = !showAll"
        >
          <span v-if="!showAll">{{ $t("global.more_filters") }}</span>
          <span v-else>{{ $t("global.less_filters") }}</span>
        </el-button>
      </div>
      <div class="filters-bar__modifiers__right">
        <slot name="rightSide" />
        <el-input
          v-if="searchEnabled"
          class="filter search"
          :value="search"
          :placeholder="searchPlaceholder"
          clearable
          @input="(e) => $emit('update-search', e)"
        >
          <i
            slot="prefix"
            class="material-icons md-24"
          >
            search
          </i>
        </el-input>
      </div>
    </div>
    <div
      v-if="selectedFilters.length > 0"
      class="filters-bar__tags"
    >
      <FilterTag
        v-for="(opt, index) in selectedFilters"
        :key="index"
        :label="opt.tag[0].name"
        :color="opt.color"
        @delete="$emit('unselect-filter', opt)"
      />
      <el-button
        type="text"
        class="link-button"
        @click="$emit('unselect-all')"
      >
        {{ $t('global.clear') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { FilterTag } from '@/components/Common/Form'

export default {
  name: 'Filters',
  components: {
    FilterTag
  },
  props: {
    selectedFilters: {
      type: Array,
      default: () => []
    },
    searchEnabled: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
      default: ""
    },
    searchPlaceholder: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      showAll: false,
      disableMoreFilter: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      // Disable expand button when less than 3 filter
      const filters = this.$refs['filters'].querySelectorAll('.filter-cascade')
      this.disableMoreFilter = filters.length <= 2

      // Empty options fall back text for cascader filters (Element UI no slots)
      const allPoppers = document.querySelectorAll('.el-cascader__dropdown')
      allPoppers.forEach(popper => {
        const emptyTextArea = popper.querySelector('.el-cascader-menu__empty-text')
        if (!emptyTextArea) return
        emptyTextArea.setAttribute('style', 'width: 100%;')
        emptyTextArea.innerHTML = this.$t('global.no_matching_data')
      })
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.filters-bar {
  .filters-bar__modifiers {
    display: flex;
    justify-content: space-between;
  }
  .filters-bar__modifiers__left {
    display: flex;
    flex-wrap: wrap;
    .el-button {
      display: none;
    }
    .filter {
      margin: 0 10px 0 0;
      &.date {
        .el-date-editor {
          border-radius: 2px;
        }
      }
    }
  }
  .filters-bar__modifiers__right {
    display: flex;

    .filter {
      margin-left: 10px;
      &.search {
        ::v-deep .el-input__inner {
          width: 230px;
          margin: 0 5px 0 0;
          &::placeholder {
            font-size: 12px;
            font-family: 'Open Sans', sans-serif;
          }
        }
        ::v-deep .el-input__prefix {
          color: $--color-search-icon;
        }
      }
    }
  }
  .filters-bar__tags {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    .tag {
      margin: 0 10px 10px 0;
    }
    .el-button--text {
      padding: 0;
      height: 26px;
    }
  }

  @media only screen and (max-width: 1170px) {
    .filters-bar__modifiers__left {
      &:not(.show-all)  {
        .filter:nth-child(n + 3) {
          display: none;
        }
      }
      .el-button {
        display: block;
        color: $--text-avatar;
        height: 40px;
        padding: 0;
      }
    }
  }

  .el-button.link-button {
    color: $--button-filter-color;
    &:hover {
      text-decoration: underline;
      color: $--button-filter-hover;
    }
  }
}
</style>
