<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item
      v-for="(item, index) in items"
      :key="index"
      :to="{ name: item.state, params: item.stateParams || {} }"
      :replace="true"
    >
      <el-tooltip
        class="item"
        effect="dark"
        placement="top"
        :content="item.name"
        :disabled="index !== items.length - 1"
      >
        <span>{{ item.name }}</span>
      </el-tooltip>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    items: { type: Array, required: true }
  }
}
</script>

<style lang="scss" scoped>
.el-breadcrumb {
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .el-breadcrumb__item {
    float: none;
  }

  .el-breadcrumb__inner.is-link {
    cursor: pointer;
  }
}
</style>
