<template>
  <el-dialog
    custom-class="admin-dialog"
    :visible="showDeleteReportModal"
    :append-to-body="true"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="600px"
  >
    <!-- DIALOG TITLE -->
    <span slot="title">
      {{ $t('reports.delete.title') }}
    </span>

    <!-- DIALOG SUBTITLE -->
    <p>{{ $t('reports.delete.subtitle') }}</p>

    <!-- FOOTER -->
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        :disabled="updating"
        size="small"
        type="default"
        @click="cancelAction"
      >{{ $t('BUTTON.CANCEL') }}</el-button>
      <el-button
        v-loading="updating"
        size="small"
        type="primary"
        @click="deleteReport"
      >{{ $t('BUTTON.CONFIRM') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'ModalDeleteReport',
  props: {
    showDeleteReportModal: {
      type: Boolean,
      default: false
    },
    selectedReport: {
      type: Object,
      default: null
    },
  },
  computed: {
    updating () {
      return this.$store.getters['reports/updating']
    }
  },
  methods: {
    cancelAction () {
      this.$emit('update:showDeleteReportModal', false)
    },
    async deleteReport () {
      try {
        await this.$store.dispatch(`reports/deleteReport`, this.selectedReport.id)
        this.$notify({
          title: this.$t(`reports.delete.success.title`),
          message: this.$t(`reports.delete.success.message`),
          type: 'success'
        })
        this.$emit('report-deleted')
        this.cancelAction()
      }
      catch (err) {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      }
    }
  }
}
</script>
