<template>
  <el-date-picker
    v-model="dates"
    type="daterange"
    :range-separator="$t('global.to')"
    :picker-options="datePickerOptions"
    :format="$t('global.date_format')"
    value-format="yyyy-MM-dd"
    end-placeholder=""
    class="date-picker"
  />
</template>

<script>
export default {
  name: 'DatePickerLanding',
  computed: {
    campaignId () {
      return this.$route.params.id
    },
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    },
    datePickerOptions () {
      const defaultDate = this.$store.getters['landingDetail/defaultDate']
      return {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: this.$t('landing.datepicker.all'),
            onClick(picker) {
              picker.$emit('pick', [
                defaultDate.start,
                defaultDate.end
              ])
            }
          }
        ]
      }
    },
    dates: {
      get () {
        return [
          this.$store.getters['landingDetail/startDate'],
          this.$store.getters['landingDetail/endDate']
        ]
      },
      set (arrDates) {
        if (!arrDates) {
          arrDates = [
            this.$store.getters['landingDetail/defaultDate'].start,
            this.$store.getters['landingDetail/defaultDate'].end
          ]
        }
        this.$store.dispatch('landingDetail/setDates', arrDates)
        this.$emit('updated', arrDates)
      }
    }
  }
}
</script>

<style lang="scss">
.el-date-editor--daterange.el-input__inner {
  width: 250px;
}
.el-picker-panel *[slot="sidebar"],
.el-picker-panel__sidebar {
  width: 150px !important;
}
.el-picker-panel__body {
  margin-left: 150px !important;
}
</style>
