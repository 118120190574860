import Vue from 'vue'

// Load plugins
import '@/plugins/filters'
import '@/plugins/element-ui'
import '@/plugins/holder'
import '@/plugins/axios'
import '@/plugins/numeral'
import '@/plugins/powerbi'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import tzData from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min.js'

// Import theme and styles
import '../theme/index.css'
import '@/assets/styles/main.scss'

// Import local components
import i18n from './languages/i18n'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
window.moment = tzData

/* eslint-disable no-console */
console.info(`Version:%c ${process.env.VERSION}`, 'color: #228786;')
/* eslint-enable no-console */

if (process.env.SENTRY_ENV){
  const currentHost = window.location.hostname.split(".")

  if (currentHost.length === 4 && currentHost[1] === 'dashboardly') {
    currentHost.splice(0, 1)
    Sentry.init({
      Vue,
      dsn: 'https://9e2339c2e15044f68333b80d04607598@sentry.numberly.net/415',
      environment: process.env.SENTRY_ENV,
      release: process.env.VERSION,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [currentHost.join('.')],
        }),
      ],
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      tracesSampleRate: 1.0,
    })
  }
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
