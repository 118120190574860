<template>
  <!-- <div :class="{'hidden': campaignType !== '2' && currentType === 'version'}"> -->
  <div
    class="segment-tag"
    :class="{'hidden': currentType === 'sending'}"
  >
    <el-select
      v-model="modelSplit"
      multiple
      collapse-tags
      style="margin-left: 0px;"
      :placeholder="$t('global.no_segment')"
      :no-data-text="$t('detail.no_data')"
      @change="debounceFilter"
    >
      <el-option
        v-for="seg in menu"
        :key="seg.id"
        :label="seg.name ? seg.name : null"
        :value="seg.id"
      >
        {{ seg.name }}
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'SegmentTags',
  props: {
    currentSplits: {
      type: Array,
      default: () => [],
      required: true
    },
    headerSplits: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentLocalType: null // used to avoid desync of modelSplit due to watch the watch on currentSplits
    }
  },
  computed: {
    menu () {
      let returnValues = []

      if (this.currentCat && this.currentCat === 'extra_data') {
        let currentExtraData = this.headerSplits[this.currentCat].objects.filter(x => x.id === this.currentExtraDataId)
        if (currentExtraData.length > 0) {
          returnValues = currentExtraData[0].values
        }
      } else if (this.currentCat && ['segments', 'versions', 'typologies'].includes(this.currentCat)) {
        returnValues = this.headerSplits[this.currentCat].objects
      }
      return returnValues
    },
    firstSplit () {
      return this.currentSplits && this.currentSplits.length > 0 ? this.currentSplits[0] : null
    },
    currentCat () {
      return this.firstSplit && this.firstSplit.cat ? this.firstSplit.cat : 'sendings'
    },
    currentType () {
      return this.firstSplit && this.firstSplit.type ? this.firstSplit.type : ''
    },
    currentFrom () {
      return this.firstSplit && this.firstSplit.from ? this.firstSplit.from : ''
    },
    currentExtraDataId () {
      return this.firstSplit && this.firstSplit.extra_data_id ? this.firstSplit.extra_data_id : ''
    },
    modelSplit: {
      get () {
        return this.currentSplits.map(item => item.id)
      },
      set (listTagsId) {
        this.$emit('update-tag', listTagsId)
      }
    }
  },
  watch: {
    currentSplits (newVal) {
      if (newVal.length > 0 && this.currentLocalType !== newVal[0].type) {
        this.currentLocalType = newVal[0].type
      }
    }
  },
  methods: {
    buildNewSplit () {
      let newsplits = []
      if (this.currentCat && ['segments', 'versions', 'typologies'].includes(this.currentCat)) {
        newsplits = this.headerSplits[this.currentCat].objects.filter(item => this.modelSplit.includes(item.id)).map(item => ({ ...item, from: this.currentFrom, cat: this.currentCat, type: this.currentType }))
      } else if (this.currentCat && this.currentCat === 'extra_data') {
        for (let value in this.modelSplit) {
          let splitData = {
            from: this.currentFrom,
            id: value,
            type: 'extra_data',
            cat: 'extra_data',
            extra_data_id: this.currentExtraDataId
          }
          newsplits.push(splitData)
        }
      } else {
        newsplits = [{ from: this.currentFrom, cat: 'sendings', type: 'sending' }]
      }
      return newsplits
    },
    debounceFilter () {
      if (this.timeout) { clearTimeout(this.timeout) }
    }
  }
}
</script>

<style lang="scss">
.segment-tag {
  .el-input__inner {
    width: 450px;
  }
}
</style>
