<template>
  <div class="download-button">
    <el-popover
      v-model="popoverOpen"
      trigger="click"
      placement="bottom"
      popper-class="no-padding-right"
      :disabled="isTagFamiliesEmpty || !showPopover"
      :width="400"
    >
      <div
        slot="reference"
        @click="handleClick"
      >
        <slot name="button">
          <el-button
            type="secondary"
            :disabled="disabled"
            :loading="loading"
          >
            <i
              v-if="!loading"
              class="material-icons"
            >
              get_app
            </i>
            {{ isList ? $t('detail.download_list') : $t('detail.download_report') }}
          </el-button>
        </slot>
      </div>
      <div>
        <div class="separator">
          <div>
            {{ $t('detail.family_tags_export') }}
          </div>
          <el-divider />
        </div>
        <el-form :model="form">
          <el-form-item class="list-container">
            <el-checkbox
              v-for="family in tagFamilies"
              :key="family.id"
              :value="isChecked(family.id)"
              @change="add => { updateSelectedFamilies({add, name: family.name, id: family.id}) }"
            >
              {{ family.name }}
            </el-checkbox>
          </el-form-item>
        </el-form>
        <div class="submit">
          <el-button
            type="primary"
            size="small"
            @click="handleSubmit"
          >
            {{ $t('detail.export') }}
          </el-button>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'DownloadButton',
  props: {
    showPopover: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isList: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      popoverOpen: false,
      form: {
        selectedFamilies: []
      }
    }
  },
  computed: {
    tagFamilies () {
      return this.tags.map(family => {
        return { name: family.name, id: family.id }
      })
    },
    isTagFamiliesEmpty () {
      return !this.tagFamilies.length > 0
    }
  },
  methods: {
    handleClick () {
      if (this.isTagFamiliesEmpty || !this.showPopover) {
        this.$emit('click-download')
      }
      this.popoverOpen = false
    },
    handleSubmit () {
      this.popoverOpen = false
      this.$emit('click-download', this.form.selectedFamilies)
    },
    isChecked (id) {
      return this.form.selectedFamilies.map(family => family.id).includes(id)
    },
    updateSelectedFamilies ({ add, name, id }) {
      if (add) {
        this.form.selectedFamilies.push({ name, id })
        return
      }
      this.form.selectedFamilies.splice(
        this.form.selectedFamilies.findIndex(family => family.id === id), 1
      )
    }
  }
}
</script>

<style lang="scss">
.download-button {
  .dbt {
    width: 40px;
    height: 40px;
    background-color: #dcdfe6;
  }
}
.el-divider--horizontal {
  margin: 12px 0;
}
.submit {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}
.list-container {
  margin-bottom: 12px;
  max-height: 320px;
  overflow-y: auto;

  .el-checkbox {
    width: calc(50% - 30px);
    &__label {
      width: calc(100% - 14px);
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__input {
      padding-bottom: 6px;
    }
  }
}
.separator {
  padding-right: 12px;
}
.tooltip-popper {
  z-index: 5000 !important;
}
</style>
