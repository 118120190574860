<template>
  <div class="table-reports">
    <el-table
      :data="data"
      style="width: 100%"
      @cell-mouse-enter="(row) => currentRow = row"
      @cell-mouse-leave="currentRow = null"
    >
      <!-- NO DATA -->
      <div slot="empty">
        <TableEmptyData :empty-label="emptyLabel" />
      </div>

      <!-- NAME -->
      <el-table-column
        width="600"
        prop="name"
        :label="$t('table.header.report_name')"
      />

      <!-- ACTIONS -->
      <el-table-column
        align="right"
        label=""
      >
        <template slot-scope="scope">
          <span v-show="currentRow && currentRow.id === scope.row.id">
            <el-button
              size="mini"
              type="primary"
              class="table-button"
              :loading="loading"
              @click="handleAction('edit', scope.row)"
            >
              <i class="material-icons md-18">edit</i>
            </el-button>
            <el-button
              size="mini"
              type="danger"
              class="table-button"
              @click="handleAction('delete', scope.row)"
            >
              <i class="material-icons md-18">delete</i>
            </el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { TableEmptyData } from '@/components/Common/Table'

export default {
  name: 'ListReport',
  components: { TableEmptyData },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    emptyLabel: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      currentRow: null
    }
  },
  computed: {
    loading () {
      return this.$store.getters['reports/loading'] ||
        this.$store.getters['reports/updating']
    }
  },
  methods: {
    handleAction (action, row) {
      this.$emit('triggered-action', {'action': action, 'report': row})
    }
  }
}
</script>

<style lang="scss" scoped>
.table-reports {
  ::v-deep .el-table__row {
    cursor: default;
  }

  ::v-deep .el-table__row {
    height: 55px;
  }
}
</style>
