<template>
  <CampaignHostsDevices
    :hosts="hosts"
    :devices-data="devices"
  />
</template>

<script>
import { CampaignHostsDevices } from '@/components/Common/Campaign'

export default {
  name: 'ViewsEmailDetailHosts',
  components: {
    CampaignHostsDevices
  },
  computed: {
    hosts () {
      return this.$store.getters['emailCampaign/hosts']
    },
    devices () {
      return this.$store.getters['emailCampaign/devices']
    }
  }
}
</script>

<style>

</style>
