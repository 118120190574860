import Vue from 'vue'

import APIException from './exceptions'


const SET_STATS_CLICKMAP = 'SET_STATS_CLICKMAP'
const SET_FETCHING_HTML = 'SET_FETCHING_HTML'
const SET_LOADING_CLICKMAP = 'SET_LOADING_CLICKMAP'

const moduleMap = {
  namespaced: true,
  state: {
    isFetchingHtml: false,
    isLoadingClickMapStats: false,
    statsClickMap: {}
  },
  mutations: {
    [SET_STATS_CLICKMAP] (state, val) {
      state.statsClickMap = val
    },
    [SET_FETCHING_HTML] (state, val) {
      state.isFetchingHtml = val
    },
    [SET_LOADING_CLICKMAP] (state, val) {
      state.isLoadingClickMapStats = val
    }
  },
  actions: {
    async reset ({ commit }) {
      commit(SET_STATS_CLICKMAP, {})
    },
    async getHtml ({ commit }, pageId) {
      commit(SET_FETCHING_HTML, true)
      try {
        const response = await Vue.axios.get(`/api/emails/pages/${pageId}/preview`)
        if (response.status >= 300) { throw response }

        return response
      }
      catch (err) {
        return new APIException(err)
      }
      finally {
        commit(SET_FETCHING_HTML, false)
      }
    },
    async getStatsClickMap ({ commit }, { id, channel, params }) {
      commit(SET_LOADING_CLICKMAP, true)
      try {
        const response = await Vue.axios.get(`/api/${channel}/${id}/clickmap`, { params })
        if (response.status >= 300) { throw response }

        commit(SET_STATS_CLICKMAP, response.data)
        return response
      }
      catch (err) {
        return new APIException(err)
      }
      finally {
        commit(SET_LOADING_CLICKMAP, false)
      }
    }
  },
  getters: {
    isFetchingHtml: state => state.isFetchingHtml,
    clickMap: state => state.statsClickMap
  }
}

export default moduleMap
