<template>
  <header class="header-sms-detail page-header">
    <div class="row">
      <Breadcrumb :items="breadcrumbs" />
    </div>
    <HeaderSelectIdm
      v-if="relatedCampaigns.length > 1"
      :campaigns="relatedCampaigns"
      :campaign-type="campaignType"
      @change-campaign="handleUpdateRoute"
    />
    <div class="entete block-meta">
      <div class="left">
        <div class="er sending-date">
          <span class="campaign-type">
            <i class="material-icons md-22">schedule</i>
            {{ $t(`detail.header.${campaignType}`) }}
          </span>
          <span class="date-information">
            {{ getDateInformation() }}
          </span>
        </div>
      </div>

      <div class="right">
        <DatePicker
          v-if="[1, 5, 7003, 7005].includes(campaign.campaign_type)"
          @updated="() => $emit('updated-range-date')"
        />
        <SplitSelector
          :header-splits="headerSplits"
          :current-split="firstCurrentSplit"
          channel="sms"
          @select-split="(s) => $emit('split-updated', s)"
        />
        <TableSelector
          channel="sms"
          :header-splits="headerSplits"
          @select-split="(s) => $emit('select-table-split', s)"
        />
        <CampaignDataExportButton
          v-if="hasExportSendingDataPerm && hasModuleDataExport"
          :campaign="campaign"
          :campaign-id="campaignId"
          :channel-id="smsChannelId"
          :campaign-type="campaignType"
          @click-download="(p) => $emit('generate-export', p)"
          @click-view-exports="() => showDataExportDrawer = true"
        />
        <DownloadButton
          v-else
          :loading="downloadLoading"
          @click-download="(p) => $emit('generate-export', p)"
        />
        <CampaignDataExportDrawer
          v-if="hasModuleDataExport"
          :show-drawer="showDataExportDrawer && campaign.id != undefined && hasExportSendingDataPerm"
          :campaign="campaign"
          :channel-id="smsChannelId"
          @update-show-drawer="() => showDataExportDrawer = false"
        />
      </div>
    </div>
  </header>
</template>

<script>
import config from '@/config'
import {
  DatePicker,
  DownloadButton,
  SplitSelector,
  TableSelector
} from '@/components/Common/Form'
import {CampaignDataExportButton, CampaignDataExportDrawer} from '@/components/Common/Campaign'
import Breadcrumb from '@/components/Common/Breadcrumb'
import HeaderSelectIdm from '@/components/Common/SelectIdm'

export default {
  name: 'PartsHeaderSMSDetail',
  components: {
    SplitSelector,
    TableSelector,
    DatePicker,
    DownloadButton,
    Breadcrumb,
    HeaderSelectIdm,
    CampaignDataExportButton,
    CampaignDataExportDrawer
  },
  props: {
    currentSplit: {
      type: Array,
      required: true
    },
    downloadLoading: {
      type: Boolean,
      default: false
    },
    headerSplits: {
      type: Object,
      default: () => {}
    },
    campaign: {
      type: Object,
      default: () => {}
    },
    campaignMeta: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      expeditor: '',
      arrowup: false,
      smsChannelId: config.CHANNELS.SMS,
      showDataExportDrawer: false
    }
  },
  computed: {
    campaignId () {
      return this.$route.params.campaignId
    },
    campaignType () {
      if (this.campaign.campaign_type === config.SMS_CHANNEL_TAGS.automatic) {
        return 'automatic'
      } else if (this.campaign.campaign_type === config.SMS_CHANNEL_TAGS.transactional) {
        return 'transactional'
      }
      return 'one_shot'
    },
    sendingDate () {
      return this.campaignType === 'one_shot' ? this.campaign.start_date : this.campaign.end_date
    },
    firstCurrentSplit () {
      return this.currentSplit.length > 0 ? this.currentSplit[0] : null
    },
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    },
    groupName () {
      return this.campaign.group ? `(${this.campaign.group})` : ''
    },
    relatedCampaigns () {
      if (!Object.keys(this.campaignMeta).length || !this.campaignMeta["related_campaigns"]) {
        return []
      }
      return [this.campaignId, ...this.campaignMeta["related_campaigns"]]
    },
    breadcrumbs () {
      const capitalize = this.$options.filters.capitalize
      return [
        {
          state: 'sms-list',
          name: `${capitalize(this.$tc('global.sms_campaign', 2))} ${this.groupName}`
        },
        {
          state: null,
          name: `${this.$route.params.campaignId} - ${this.campaign.campaign_name}`
        }
      ]
    },
    hasExportSendingDataPerm () {
      return this.$store.getters['session/hasPermission']('dashboardly:user:export-sending-data')
    },
    hasModuleDataExport () {
      return this.$store.getters['session/hasModuleDataExport']
    }
  },
  mounted() {
    // init on mounted to make sure component is correctly mounted
    this.showDataExportDrawer = this.$route.query.drawer == "true" ? true : false
  },
  methods: {
    isOpen(open) {
      this.arrowup = open
      return open
    },
    downloadCampaign () {
      this.$store.dispatch('getExportCampaign', { channel: 'sms', id: this.campaignId })
    },
    formatExpeditorInfo (expeditor) {
      return `${expeditor.name} <${expeditor.email}>`
    },
    handleUpdateRoute(campaignId) {
      this.$emit('change-campaign', campaignId)
    },
    formatDate(date) {
      return this.$options.filters.formatDate(date, this.$i18n.locale, this.userTimezone, this.$t('global.date_format'))
    },
    getDateInformation() {
      if (this.sendingDate) {
        switch (this.campaignType) {
          case 'automatic':
          case 'transactional':
            return `${this.$t('detail.header.campaign_first_sent')} : ${this.formatDate(this.campaign.start_date)} | ${this.$t('detail.header.campaign_last_sent')} : ${this.formatDate(this.campaign.end_date)}`
          case 'one_shot':
            return `${this.$t('detail.header.campaign_sent')} : ${this.formatDate(this.campaign.start_date)}`
        }
      } else {
        return this.$t('detail.header.campaign_not_sent')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.header-sms-detail {
  padding-bottom: 1.5rem;

  .block-meta {
    border-radius: 3px;
    padding: 1rem;
    background-color: $--color-header-detail-dark-bg;
    background: repeating-linear-gradient(
      -45deg,
      $--color-header-detail-dark-bg 0%,
      $--color-header-detail-dark-bg 37%,
      $--color-header-detail-bg 37%,
      $--color-header-detail-bg 100%
    );
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    @media only screen and (max-width: $--mobile-width) {
      flex-direction: column;
    }
  }
  .entete {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .er {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 4px;
        line-height: 24px;
        // height: 24px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      .subject {
        .custom_icon {
          display: inline-block;
        }
      }
      i {
        text-align: center;
        font-size: 24px;
        width: 24px;
        height: 24px;
        margin-right: 10px;
        color: $--color-primary;
      }

      .sending-date {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .campaign-type {
          display: flex;
          flex-direction: row;
          line-height: 24px;
          font-weight: bold;
        }
        .date-information {
          margin-left: 34px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      button,
      .el-dropdown {
        margin-left: 0.3rem;
      }
    }
  }
}
.expeditor-name {
  color: $--text-light;
}
.export-download-dropdown {
  .el-dropdown-menu__item {
    i {
      vertical-align: text-bottom;
      font-size: 18px;
      text-align: center;
    }
  }
}
</style>
