function isoDate (date, timezone, format, end=false) {
  const mm = window.moment.tz(date, format, timezone)
  return end ? mm.endOf('day').utc() : mm.startOf('day').utc()
}

function isoDateString (date, timezone, end=false) {
  return isoDate(date, timezone, 'YYYY-MM-DD', end).toISOString()
}

export { isoDate, isoDateString }
