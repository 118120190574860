export default {
  methods: {
    logifier_id(groupid) {
      if (!groupid) { return null }
      const group = this.$store.getters['session/groups'].filter(function (item) {
        if (item.id === groupid) {
          return item
        }
      })[0]
      if (!group) { return null }
      return group
    }
  }
}
