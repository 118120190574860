<template>
  <el-dropdown
    trigger="click"
    class="split-selector"
    @command="(s) => $emit('select-split', s)"
    @visible-change="isOpen"
  >
    <el-button
      class="custom-select"
      type="secondary"
    >
      {{ currentDisplayedName }}
      <i
        class="el-icon--right"
        :class="arrowup ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
      />
    </el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="(split, index) in splitMenu"
        :key="index"
        :command="split"
        :disabled="split.attr.disabled"
        :divided="split.attr.divided"
      >
        <span :class="{'selected': currentSplit && currentSplit.id !== '' && currentSplit.id === split.id && currentSplit.cat === split.cat}">
          {{ split.name }}
        </span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'SplitSelector',
  props: {
    channel: {
      type: String,
      required: true
    },
    headerSplits: {
      type: Object,
      required: true
    },
    currentSplit: {
      type: [Object, null],
      default: null
    }
  },
  data () {
    return {
      arrowup: false
    }
  },
  computed: {
    campaignId () {
      return this.$route.params.id
    },
    currentDisplayedName () {
      if (!this.currentSplit || !this.currentSplit.name) {
        return this.$t('global.all_data')
      }
      return this.currentSplit.name
    },
    splitMenu () {
      let menu = []
      menu.push({
        id: '',
        name: this.$t('global.all_data'),
        attr: { disabled: null, divided: null }
      })

      if (this.headerSplits.segments) {
        menu.push({
          id: '',
          name: `${this.$tc('detail.segments', 1)} (${this.headerSplits.segments.total})`,
          attr: { disabled: 'disabled', divided: 'divided' }
        })
        for (let i in this.headerSplits.segments.objects) {
          menu.push({
            id: this.headerSplits.segments.objects[i].id,
            name: this.headerSplits.segments.objects[i].name,
            type: 'segment',
            cat: 'segments',
            attr: { disabled: null, divided: null }
          })
        }
      }
      if (this.headerSplits.versions) {
        menu.push({
          id: '',
          name: `${this.$tc('detail.versions', 1)} (${this.headerSplits.versions.total})`,
          attr: { disabled: 'disabled', divided: 'divided' }
        })
        for (let i in this.headerSplits.versions.objects) {
          menu.push({
            id: this.headerSplits.versions.objects[i].id,
            name: this.headerSplits.versions.objects[i].name,
            type: 'version',
            cat: 'versions',
            attr: { disabled: null, divided: null }
          })
        }
      }
      if (this.headerSplits.typologies) {
        menu.push({
          id: '',
          name: `${this.$tc('detail.typologies', 1)} (${this.headerSplits.typologies.total})`,
          attr: { disabled: 'disabled', divided: 'divided' }
        })
        for (let i in this.headerSplits.typologies.objects) {
          menu.push({
            id: this.headerSplits.typologies.objects[i].id,
            name: this.headerSplits.typologies.objects[i].name,
            type: 'typology',
            cat: 'typologies',
            attr: { disabled: null, divided: null }
          })
        }
      }
      if (this.headerSplits.extra_data) {
        for (let i in this.headerSplits.extra_data.objects) {
          let extraData = this.headerSplits.extra_data.objects[i]
          let extraDataLabel = this.$tc(`extra_data.${extraData.name}`, 1) // ex Senders
          menu.push({
            id: '',
            name: `${extraDataLabel} (${extraData.values.length})`,
            attr: { disabled: 'disabled', divided: 'divided' }
          })
          for (let j in extraData.values) {
            let valueLabel = extraData.values[j].name // domain
            menu.push({
              id: extraData.values[j].id,
              name: valueLabel,
              extra_data_id: extraData.id,
              type: 'extra_data',
              attr: { disabled: null, divided: null }
            })
          }
        }
      }
      return menu
    }
  },
  methods: {
    isOpen (open) {
      this.arrowup = open
      return open
    },
    setCurrentSplit (split) {
      this.$emit('select-split', split.id ? split : null)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
.selected {
  color: $--color-primary;
}
</style>
