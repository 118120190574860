<template>
  <div class="block-param bp-in-qr bp-qr">
    <div class="row">
      <h2>{{ $t(`kpi.qr.${kpiskey}`) }}</h2>
    </div>
    <div class="infos-container">
      <div class="kpis">
        <ChartKPI
          v-for="(value, key) in kpisvalue"
          :key="key"
          :channel="'qr'"
          :data="{
            name: key,
            kpi_value: value
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ChartKPI } from '@/components/Common/Chart'

export default {
  name: 'BlockInQR',
  components: { ChartKPI },
  props: {
    kpiskey: {
      type: String,
      required: true
    },
    kpisvalue: {
      type: Object,
      default: () => {}
    },
    kpis: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.bp-in-qr {
  .infos-container {
    display: flex;

    .kpis {
      flex: 1;
      display: flex;
      .kpi {
        margin-right: 1rem;
      }
    }
  }
}
</style>
