<template>
  <span>
    <el-date-picker
      v-model="dates"
      type="daterange"
      :range-separator="$t('global.to')"
      :picker-options="datePickerOptions"
      :format="$t('global.date_format')"
      value-format="yyyy-MM-dd"
      end-placeholder=""
      class="date-picker"
    />
  </span>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DatPicker',
  data () {
    return {
      momentFormat: this.$t('global.moment_format'),
      datePickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: this.$t('global.today'),
            onClick(picker) {
              const start = moment().startOf('day').format('YYYY-MM-DD')
              const end = moment().format('YYYY-MM-DD')
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: this.$t('global.yesterday'),
            onClick(picker) {
              const start = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD')
              const end = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD')
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: this.$t('global.last_7_days'),
            onClick(picker) {
              const start = moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD')
              const end = moment().format('YYYY-MM-DD')
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: this.$t('global.this_week'),
            onClick(picker) {
              const start = moment().startOf('isoWeek').format('YYYY-MM-DD')
              const end = moment().format('YYYY-MM-DD')
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: this.$t('global.last_30_days'),
            onClick(picker) {
              const start = moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD')
              const end = moment().format('YYYY-MM-DD')
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: this.$t('global.this_month'),
            onClick(picker) {
              const start = moment().startOf('month').format('YYYY-MM-DD')
              const end = moment().format('YYYY-MM-DD')
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: this.$t('global.last_90_days'),
            onClick(picker) {
              const start = moment().subtract(90, 'days').startOf('day').format('YYYY-MM-DD')
              const end = moment().format('YYYY-MM-DD')
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: this.$t('global.year_to_date'),
            onClick(picker) {
              const start = moment().startOf('year').format('YYYY-MM-DD')
              const end = moment().format('YYYY-MM-DD')
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  computed: {
    rangeDate () {
      return this.$store.getters['app/rangeDate']
    },
    userTimezone () {
      return this.$store.getters['session/user'].timezone || 'UTC'
    },
    dates: {
      get () {
        return [
          this.rangeDate.start,
          this.rangeDate.end
        ]
      },
      set (newVal) {
        if (!newVal) {
          newVal = [
            moment().subtract(1, 'month').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
          ]
        }
        const rangeDate = {
          start: newVal[0],
          end: newVal[1]
        }
        this.$store.dispatch('app/setRangeDate', rangeDate)
        this.$emit('updated', rangeDate)
      }
    }
  }
}
</script>

<style lang="scss">
.el-date-editor--daterange.el-input__inner {
  width: 250px;
}
.el-picker-panel *[slot="sidebar"],
.el-picker-panel__sidebar {
  width: 150px !important;
}
.el-picker-panel__body {
  margin-left: 150px !important;
}
</style>
