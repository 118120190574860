import Vue from 'vue'

import APIException from './exceptions'

const SET_LOADING_CONVERSIONS = 'SET_LOADING_CONVERSIONS'
const SET_LOADING_DAILY_CONVERSIONS = 'SET_LOADING_DAILY_CONVERSIONS'
const SET_LOADING_CONVERSION_TYPES = 'SET_LOADING_CONVERSION_TYPES'
const SET_CONVERSIONS = 'SET_CONVERSIONS'
const SET_DAILY_CONVERSIONS = 'SET_DAILY_CONVERSIONS'
const SET_CONVERSION_TYPES = 'SET_CONVERSION_TYPES'
const SET_CURRENT_FILTER = 'SET_CURRENT_FILTER'

const moduleCampaignConversions = {
  namespaced: true,
  state: {
    isLoadingConversions: false,
    conversions: {
      "total_conversions": 0,
      "total_revenue": 0,
      "conversion_rate": 0,
      "articles": 0,
      "buyers": 0,
      "average_revenue_per_send": 0,
      "average_basket": 0,
      "currency": ""
    },
    isLoadingDailyConversions: false,
    dailyConversions: null,
    isLoadingConversionTypes: false,
    conversionTypes: [],
    currentFilter: 'all'
  },
  mutations: {
    [SET_CONVERSIONS] (state, val) {
      state.conversions = val
    },
    [SET_LOADING_CONVERSIONS] (state, val) {
      state.isLoadingConversions = val
    },
    [SET_DAILY_CONVERSIONS] (state, val) {
      state.dailyConversions = val
    },
    [SET_LOADING_DAILY_CONVERSIONS] (state, val) {
      state.isLoadingDailyConversions = val
    },
    [SET_LOADING_CONVERSION_TYPES] (state, val) {
      state.isLoadingConversionTypes = val
    },
    [SET_CONVERSION_TYPES] (state, val) {
      state.conversionTypes = val
    },
    [SET_CURRENT_FILTER] (state, val) {
      state.currentFilter = val
    }
  },
  actions: {
    async reset ({ commit }) {
      commit(SET_CONVERSIONS, {})
    },
    async getConversions ({ commit }, params) {
      commit(SET_LOADING_CONVERSIONS, true)
      try {
        const response = await Vue.axios.get("/api/conversions/kpis", { params })
        if (response.status >= 300) { throw response }
        commit(SET_CONVERSIONS, response.data)
        return response
      }
      catch (err) {
        return new APIException(err)
      }
      finally {
        commit(SET_LOADING_CONVERSIONS, false)
      }
    },
    async getDailyConversions ({ commit }, params) {
      commit(SET_LOADING_DAILY_CONVERSIONS, true)
      try {
        const response = await Vue.axios.get("/api/conversions/daily_repartitions", { params })
        if (response.status >= 300) { throw response }
        commit(SET_DAILY_CONVERSIONS, response.data)
        return response
      }
      catch (err) {
        return new APIException(err)
      }
      finally {
        commit(SET_LOADING_DAILY_CONVERSIONS, false)
      }
    },
    async getConversionTypes ({ commit }) {
      commit(SET_LOADING_CONVERSION_TYPES, true)
      try {
        const response = await Vue.axios.get(`/api/conversions/conversion_types`)
        if (response.status >= 300) { throw response }
        commit(SET_CONVERSION_TYPES, response.data)
        return response
      }
      catch (err) {
        return new APIException(err)
      }
      finally {
        commit(SET_LOADING_CONVERSION_TYPES, false)
      }
    },
    setCurrentFilter({ commit}, filter) {
      commit(SET_CURRENT_FILTER, filter)
    }
  },
  getters: {
    items: state => state.conversions,
    loading: state => state.isLoadingConversions,
    dailyConversions: state => state.dailyConversions,
    loadingDailyConversions: state => state.isLoadingDailyConversions,
    conversionTypes: state => state.conversionTypes,
    currentFilter: state => state.currentFilter
  }
}

export default moduleCampaignConversions
