import { orderBy as _orderBy } from 'lodash-es'
import {
  filterTagsColor1,
  filterTagsColor2,
  filterTagsColor3,
  filterTagsColor4,
  filterTagsColor5
} from '@/assets/styles/numberly-filters.scss'

function formatTagToOption(tag) {
  return {
    label: tag.name,
    value: {
      id: tag.id,
      name: tag.name
    },
    count: tag.tags.length,
    children: tag.tags.sort((a, b) => a.name.localeCompare(b.name)).map(childrenTag => ({
      label: childrenTag.name,
      value: {
        family: childrenTag.family,
        id: `${tag.id}:${childrenTag.id}`,
        name: childrenTag.name
      }
    }))
  }
}

const filterTags = {
  data () {
    return {
      filterTagsColors: [
        filterTagsColor1,
        filterTagsColor2,
        filterTagsColor3,
        filterTagsColor4,
        filterTagsColor5
      ]
    }
  },
  methods: {
    /**
     * Get selected groups ids and init the group filter cascade
     */
    initSelectedTags (allTags, defaultSelectedTags = []) {
      const mappedTags = allTags.map(formatTagToOption)
      const optionTags = _orderBy(mappedTags, [tag => tag.label.toLowerCase()])
      let selectedTags = []
      let tagIds = defaultSelectedTags.map(g => g.id)
      if (this.$route.query.tags) {
        tagIds = this.$route.query.tags.split(',')
      }
      if (tagIds.length) {
        selectedTags = optionTags
          .map(tag => tag.children).flat()
          .filter(opt => tagIds.includes(opt.value.id))
          .map(t => [t.value.family, t.value])
      }
      return { options: optionTags, selected: selectedTags }
    },

    /**
     * Format generated value from the cascader selection to be used externally of the Filter mechanism
     */
    formatTagsCascader (cascaderTagsSelection) {
      return cascaderTagsSelection.flat().filter(tag => Boolean(tag.family))
    }
  }
}

export default filterTags
