<template>
  <el-dialog
    custom-class="export-sending-data-modal"
    :visible="showModal"
    :append-to-body="true"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="700px"
    center
  >
    <!-- DIALOG TITLE -->
    <div
      slot="title"
    >
      <h2 class="title">
        {{ $t('exports.sending_data.title') }}
      </h2>
      <div class="subtitle">
        {{ campaign.campaign_name }}
      </div>
    </div>
    <div class="description">
      {{ $t('exports.sending_data.description') }}
    </div>

    <el-alert
      class="alert-info"
      type="info"
      :title="$t('exports.sending_data.alert_info')"
      :closable="false"
      show-icon
    />
    <el-form
      ref="exportForm"
      :model="form"
      :disabled="exporting"
      label-width="0"
      class="form"
      :rules="rules"
    >
      <!-- DELIMITER -->
      <el-form-item
        class="delimiter-radio-groups"
        prop="delimiter"
        required
      >
        <p>{{ $t('exports.sending_data.delimiter') }}</p>
        <el-radio-group v-model="form.delimiter">
          <el-radio :label="'\t'">
            {{ $t('exports.sending_data.delimiter_2') }}
          </el-radio>
          <el-radio label=",">
            {{ $t('exports.sending_data.delimiter_3') }}
          </el-radio>
          <el-radio label=";">
            {{ $t('exports.sending_data.delimiter_4') }}
          </el-radio>
          <el-radio label="1">
            {{ $t('exports.sending_data.delimiter_5') }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="isOtherDelimiter"
        prop="otherDelimiter"
      >
        <el-input
          v-model="form.otherDelimiter"
          maxlength="1"
        />
      </el-form-item>

      <!-- INPUT TEXT QUALIFIER -->
      <el-form-item
        prop="textQualifier"
      >
        <p>{{ $t('exports.sending_data.text_qualifier') }}</p>
        <el-input
          v-model="form.textQualifier"
          maxlength="1"
        />
      </el-form-item>

      <!-- EMAIL -->
      <el-form-item
        prop="recipients"
      >
        <p>{{ $t('exports.sending_data.recipients') }}</p>
        <el-select
          v-model="form.recipients"
          popper-class="recipientsList"
          multiple
          filterable
          allow-create
          autocomplete="no"
          default-first-option
          placeholder=""
          style="width:100%"
          :no-data-text="$t('exports.sending_data.no_recipients')"
          :remote-method="getRecipients"
          :loading="recipientsLoading"
          @change="selectRecipient"
          @focus="getRecipients"
        >
          <el-option
            v-for="recipient in recipientOptions"
            :key="recipient.id"
            :label="recipient.recipient"
            :value="recipient.recipient"
          >
            {{ recipient.recipient }}
            <el-button
              class="delete-recipient-btn"
              type="text"
              @click="(event) => deleteRecipient(recipient.id, event)"
            >
              <i class="material-icons text-medium">
                close
              </i>
            </el-button>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!-- FOOTER -->
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        :disabled="exporting"
        size="small"
        type="default"
        @click="cancelAction"
      >{{ $t('BUTTON.CANCEL') }}</el-button>
      <el-button
        v-loading="exporting"
        size="small"
        type="primary"
        @click="confirmAction"
      >{{ $t('exports.sending_data.confirm_btn') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import config from '@/config'
import { isoDateString } from '@/helpers/isoDate'
export default {
  name: 'ModalExportSendingData',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    campaign: {
      type: Object,
      default: () => {}
    },
    campaignId: {
      type: Number,
      required: true
    },
    channelId: {
      type: Number,
      required: true
    },
  },
  data: function () {
    var validateEmails = (rule, value, callback) => {
      // Check if all emails are correct
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      let checkMsg = ''
      let incorrectEmails = []
      for (let i = 0; i < this.form.recipients.length; i++) {
        let email = this.form.recipients[i]
        if (!re.test(String(email).toLowerCase())) {
          incorrectEmails.push(email)
        }
      }

      if (incorrectEmails.length >= 1) {
        if (incorrectEmails.length === 1) {
          checkMsg += incorrectEmails[0] + ' ' + this.$t('exports.sending_data.error_recipient')
        } else {
          checkMsg = incorrectEmails.join(', ')
          checkMsg += ' ' + this.$t('exports.sending_data.error_recipients')
        }
        callback(new Error(checkMsg))
      } else {
        callback()
      }
    }
    return {
      form: {
        delimiter: '\t',
        textQualifier: '',
        recipients: []
      },
      rules: {
        delimiter: [
          { required: true, message: this.$t('exports.sending_data.error_delimiter'), trigger: 'blur' },
        ],
        recipients: [
          { required: false, validator: validateEmails, trigger: 'blur' }
        ]
      },
      recipientOptions: [],
      selectedRecipients: [],
      exporting: false
    }
  },
  computed: {
    isOtherDelimiter() {
      if (
        this.form.delimiter != ";" &&
        this.form.delimiter != "," &&
        this.form.delimiter != "\t"
      ) {
        return true
      }
      return false
    },
    recipientsLoading () {
      return this.$store.getters['recipients/loading']
    },
    campaignType () {
      const automaticTypes = [config.EMAIL_CHANNEL_TAGS.automatic, config.SMS_CHANNEL_TAGS.automatic, config.PUSH_CHANNEL_TAGS.automatic]
      const transactionalTypes = [config.EMAIL_CHANNEL_TAGS.transactional, config.SMS_CHANNEL_TAGS.transactional, config.PUSH_CHANNEL_TAGS.transactional]
      if (automaticTypes.indexOf(this.campaign.campaign_type) > -1){
        return 'automatic'
      }
      if (transactionalTypes.indexOf(this.campaign.campaign_type) > -1) {
        return 'transactional'
      }
      return 'one_shot'
    },
    rangeDate () {
      const { start, end } = this.$store.getters['app/rangeDate']
      const tz = this.$store.getters['session/user'].timezone || 'UTC'

      return {
        start: isoDateString(start, tz),
        end: isoDateString(end, tz, true),
      }
    }
  },
  methods: {
    cancelAction () {
      this.$emit('update:showModal', false)
      this.form = {
        delimiter: '\t',
        textQualifier: '',
        recipients: []
      }
    },
    validateEmailList () {
      this.$refs['exportForm'].validateField('emails')
    },
    getRecipients () {
      let params = {}
      this.$store.dispatch('recipients/getRecipients', params)
      .then(data => {
          this.recipientOptions = data
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
    },
    selectRecipient (selected) {
      // check if remove case or add case
      if (selected.length <= this.form.recipients.length) {
        this.form.recipients = selected
      } else {
        // if add case check if recipientList selected or recipient
        let selectedValue = selected.pop()
        if (selectedValue instanceof Object) {
          // check duplicate
          let recipientsTmp = this.form.recipients.concat(selectedValue)
          this.form.recipients = [...new Set(recipientsTmp)]
        } else if (this.form.recipients.indexOf(selectedValue) === -1) {
          this.form.recipients.push(selectedValue)
        }
      }
      this.$refs['exportForm'].validate((valid) => {
        if (! valid) { return false }
      })
    },
    buildRecipientsList () {
      let existingEmails = this.recipientOptions.map(r => r.recipient)

      // separate existing emails and new ones
      let selectedExistingEmails = this.form.recipients.filter(r => existingEmails.includes(r))
      let selectedNewEmails = this.form.recipients.filter(r => !existingEmails.includes(r))

      // build recipients array
      let recipients = this.recipientOptions.filter(r => selectedExistingEmails.includes(r.recipient))
      for (let i = 0; i < selectedNewEmails.length; i++) {
        recipients.push({id: null, recipient: selectedNewEmails[i]})
      }
      return recipients
    },
    async deleteRecipient (recipientId, event) {
      // block element ui from selecting option on click
      event.stopPropagation()
      // delete recipient
      this.$store.dispatch('recipients/deleteRecipient', recipientId)
      .then(() => {
        this.recipientOptions= this.recipientOptions.filter(o => o.id != recipientId)
      })
      .catch(() => {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      })
    },
    async confirmAction () {
      try {
        await this.$refs['exportForm'].validate()
      }
      catch (_) {
        return
      }
      this.exporting = true

      try {
        let data = {
            delimiter: this.isOtherDelimiter
              ? this.form.otherDelimiter
              : this.form.delimiter,
            text_qualifier: this.form.textQualifier,
            campaign_id: this.campaignId,
            channel_id: this.channelId
        }
        // for automatic and applicative campaign take into date into account
        let minValidDate = new Date()
        minValidDate.setFullYear(minValidDate.getFullYear() -1)
        if (["automatic", "transactional"].indexOf(this.campaignType) > -1) {
          data["start_date"] = minValidDate < new Date(this.rangeDate.start) ? this.rangeDate.start : isoDateString(minValidDate)
          data["end_date"] = minValidDate < new Date(this.rangeDate.end) ? this.rangeDate.end : isoDateString(minValidDate)
        } else {
          data["start_date"] = this.campaign.start_date
        }

        if (this.form.recipients.length ) {
          data.recipients = this.buildRecipientsList()
        }
        await this.$store.dispatch('dataExport/createDataExport', data)
        this.$notify({
          title: this.$t('exports.sending_data.success_title'),
          message: this.$t('exports.sending_data.success_message'),
          type: 'success'
        })
        this.cancelAction()
      }
      catch (err) {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      }
      finally {
        this.exporting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.el-select-dropdown__item {
  // width: 440px;
  .delete-recipient-btn {
    display: none;
  }
}
.el-select-dropdown__item:not(.selected):hover {
  .delete-recipient-btn {
    display: inline;
    position: absolute;
    right: 14px;
    top: -5px;
    font-size: 12px;
  }
}

.export-sending-data-modal {
  .el-dialog__header {
    .title {
      color: $--text-dark;
      font-size: 28px;
      font-weight: 300;
    }
    .subtitle {
      color: $--text-dark;
      margin: 10px;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .el-dialog__body {
    .description {
      margin-bottom: 10px;
    }
    .alert-info {
      margin: 10px 0px;
    }
    .delimiter-radio-groups {
      .el-radio {
        display: block;
        margin: 0 0 1rem 0;
        color: $--text-dark;
      }
    }
  }
}
</style>
