import moment from 'moment'
import Vue from 'vue'

import APIException from './exceptions'
import i18n from '@/languages/i18n'


const SET_CAMPAIGN = 'SET_CAMPAIGN'
const SET_HEADER_META = 'SET_HEADER_META'
const SET_HEADER_SPLITS = 'SET_HEADER_SPLITS'
const SET_OVERVIEW = 'SET_OVERVIEW'
const SET_REACTION = 'SET_REACTION'
const SET_DEVICES = 'SET_DEVICES'
const SET_PREVIEWS = 'SET_PREVIEWS'
const SET_LOADING = 'SET_LOADING'
const SET_HOSTS = 'SET_HOSTS'
const SET_SPLITS = 'SET_SPLITS'
const SET_CURRENT_SPLITS = 'SET_CURRENT_SPLITS'
const SET_DOWNLOAD_LOADING = 'SET_DOWNLOAD_LOADING'
const RESET_STATE = 'RESET_STATE'

function buildRequest(params, state) {
  if (state.currentSplit.length && state.currentSplit[0].type) {
    params = { ...params }
    if (!params['split'] || !params['split'].length) {
      params['split'] = state.currentSplit.map(item => item.id).join('|')
    }
    params['split_type'] = state.currentSplit[0].type
    if ('extra_data_id' in state.currentSplit[0]) {
      params['extra_data_id'] = state.currentSplit[0].extra_data_id
    }
  }
  return params
}

function exportFilename(campaign, client, lang) {
  // NomClient_NomCampagne_AAAAMMJJ / ClientName_CampaignName__YYYYMMDD
  const clientName = client.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  const campaignName = campaign.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  let dateFmt = 'YYYYMMDD'
  if (lang === 'fr') {
    dateFmt = 'DDMMYYYY'
  }
  return `${clientName}_${campaignName}_${moment().format(dateFmt)}.xlsx`
}

function exportFilenameSplits(campaign, client, lang) {
  // Nom du fichier : NomClient_SplitView_NomCampagne_AAAAMMJJ / ClientName_SplitView_CampaignName__YYYYMMDD
  const campaignName = campaign.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  const clientName = client.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  let splitView = 'TableView'
  let dateFmt = 'YYYYMMDD'

  if (lang === 'fr') {
    splitView = 'VueTableau'
    dateFmt = 'DDMMYYYY'
  }
  return `${clientName}_${splitView}_${campaignName}_${moment().format(dateFmt)}.xlsx`
}

const moduleEmailCampaign = {
  namespaced: true,
  state: {
    campaign: null,
    downloadLoading: false,
    header_splits: {},
    header_meta: {}, // campaign_name, campaign_type, group, sending_date, subject, expeditors []
    overview: {}, // reactions {}, delivery {}, unsubscriptions {}, amplifier {}, html_src 'html'
    reactions: [], // datasets []
    devices: {}, // clicks [], opens []
    previews: [], // desktop_url , mobile_url
    loading: false,
    hosts: [],
    splits: [],
    currentSplit: []
  },
  mutations: {
    [SET_CAMPAIGN] (state, { campaignId, data }) {
      data.id = campaignId
      Vue.set(state, 'campaign', data)
    },
    [SET_HEADER_META] (state, val) {
      state.header_meta = val
    },
    [SET_HEADER_SPLITS] (state, val) {
      Vue.set(state, 'header_splits', val)
    },
    [SET_OVERVIEW] (state, val) {
      state.overview = val
    },
    [SET_REACTION] (state, val) {
      state.reactions = val
    },
    [SET_DEVICES] (state, val) {
      state.devices = val
    },
    [SET_PREVIEWS] (state, val) {
      state.previews = val
    },
    [SET_LOADING] (state, val) {
      state.loading = val
    },
    [SET_HOSTS] (state, val) {
      state.hosts = val
    },
    [SET_SPLITS] (state, val) {
      Vue.set(state, 'splits', val)
    },
    [SET_CURRENT_SPLITS] (state, val) {
      state.currentSplit = val
    },
    [SET_DOWNLOAD_LOADING] (state, loader) {
      Vue.set(state, 'downloadLoading', loader)
    },
    [RESET_STATE] (state, campaignId = null) {
      if (state.campaign && campaignId !== state.campaign.campaign_id) {
        Vue.set(state, 'campaign', null)
      }
      state.campaign = null
      state.header_meta = {}
      state.header_splits = {}
      state.overview = {}
      state.reactions = []
      state.devices = {}
      state.hosts = []
      state.previews = []
      state.splits = []
      state.export_data = null
      state.export_splits = null
      state.currentSplit = []
    }
  },
  actions: {
    selectSplit ({ commit }, split) {
      commit(SET_CURRENT_SPLITS, split)
    },
    resetState ({ commit }, campaignId) {
      commit(RESET_STATE, campaignId)
    },
    async getCampaign ({ commit }, campaignId) {
      try {
        const response = await Vue.axios.get(`/api/emails/${campaignId}`)
        if (response.status >= 300) { throw response }

        const data = response.data
        commit(SET_CAMPAIGN, { campaignId, data })
        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    async getHeaderMeta({ commit }, { id, params }) {
      try {
        const response = await Vue.axios.get(`/api/emails/${id}/metadata`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_HEADER_META, response.data)
        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    async getHeaderSplits({ commit }, { id, params }) {
      try {
        const response = await Vue.axios.get(`/api/emails/${id}/splits`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_HEADER_SPLITS, response.data)
        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    async getPreviews({ commit }, id) {
      try {
        const response = await Vue.axios.get(`/api/emails/${id}/previews`)
        if (response.status >= 300) { throw response }

        commit(SET_PREVIEWS, response.data)
      } catch (err) {
        return new APIException(err)
      }
    },

    async getOverview({ state, commit }, { id, params }) {
      params = buildRequest(params, state)
      try {
        const response = await Vue.axios.get(`/api/emails/${id}/overview`, {
          params: params
        })
        if (response.status >= 300) { throw response }

        commit(SET_OVERVIEW, response.data)
      } catch (err) {
        return new APIException(err)
      }
    },
    async getReactions({ state, commit }, { id, params }) {
      params = buildRequest(params, state)
      if (!params['period']) {
        params['period'] = 24
      }
      try {
        const response = await Vue.axios.get(`/api/emails/${id}/reactions`, {
          params: params
        })
        if (response.status >= 300) { throw response }

        commit(SET_REACTION, response.data.datasets)
      } catch (err) {
        return new APIException(err)
      }
    },
    async getDevices({ state, commit }, { id, params }) {
      params = buildRequest(params, state)
      try {
        const response = await Vue.axios.get(`/api/emails/${id}/devices`, {
          params: params
        })
        if (response.status >= 300) { throw response }

        commit(SET_DEVICES, response.data)
      } catch (err) {
        return new APIException(err)
      }
    },
    async getHosts({ state, commit }, { id, params }) {
      params = buildRequest(params, state)
      try {
        const response = await Vue.axios.get(`/api/emails/${id}/hosts`, {
          params: params
        })
        if (response.status >= 300) { throw response }

        commit(SET_HOSTS, response.data)
      } catch (err) {
        return new APIException(err)
      }
    },
    resetSplits ({ commit }) {
      commit(SET_SPLITS, [])
    },
    async getSplits ({ state, commit }, { id, params }) {
      params = buildRequest(params, state)
      try {
        const response = await Vue.axios.get(`/api/emails/${id}/splits/data`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_SPLITS, response.data)
        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    async exportCampaign({ state, rootGetters, commit }, { id, params }) {
      const clientName = rootGetters['session/client'].name
      const campaignName = state.campaign.campaign_name
      const fileName = exportFilename(campaignName, clientName, i18n.locale)
      params = buildRequest(params, state)

      commit(SET_DOWNLOAD_LOADING, true)
      try {
        const xlsx = await Vue.axios({
          url: `/api/emails/${id}/export`,
          method: 'POST',
          responseType: 'blob',
          params: params
        })
        const blob = new Blob([xlsx.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
        })

        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, fileName)
        } else {
          const link = document.createElement('a')
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', fileName)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      } catch (err) {
        return new APIException(err)
      } finally {
        commit(SET_DOWNLOAD_LOADING, false)
      }
    },
    async exportCampaignSplits({ state, rootGetters, commit }, { id, params }) {
      const clientName = rootGetters['session/client'].name
      const campaignName = state.campaign.campaign_name
      const fileName = exportFilenameSplits(campaignName, clientName, i18n.locale)
      params = buildRequest(params, state)
      commit(SET_DOWNLOAD_LOADING, true)

      try {
        const xlsx = await Vue.axios({
          url: `/api/emails/${id}/splits/export`,
          method: 'POST',
          responseType: 'blob',
          params: params
        })
        const blob = new Blob([xlsx.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
        })

        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, fileName)
        } else {
          const link = document.createElement('a')
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', fileName)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      } catch (err) {
        return new APIException(err)
      } finally {
        commit(SET_DOWNLOAD_LOADING, false)
      }
    },
    async updateCampaign({ dispatch, commit }, query) {
      commit(SET_LOADING, true)
      await dispatch('getOverview', query)
      await dispatch('getDevices', query)
      await dispatch('getHosts', query)
      await dispatch('getReactions', query)
      commit(SET_LOADING, false)
    }
  },
  getters: {
    campaign: state => state.campaign,
    splits: state => state.splits,
    chartClicks: state => state.devices.clicks,
    chartOpens: state => state.devices.opens,
    headerMeta: state => state.header_meta,
    headerSplits: state => state.header_splits,
    currentSplit: state => state.currentSplit,
    overview: state => state.overview,
    reactions: state => state.reactions,
    previews: state => state.previews,
    loading: state => state.loading,
    devices: state => state.devices,
    hosts: state => state.hosts,
    downloadLoading: state => state.downloadLoading
  }
}

export default moduleEmailCampaign
