import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import store from '@/store'

Vue.use(VueAxios, axios)

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response && error.response.status === 401) {
    const redirectUrl = error.response.headers.redirect
    if (redirectUrl) {
      store.dispatch('session/clearSession')
      window.location = redirectUrl
    }
  }
  return Promise.reject(error.response ? error.response : error)
})
