<template>
  <div
    class="block-param bp-graph"
    :class="{'bp-email': channel === 'email', 'bp-sms': channel === 'sms', 'bp-landing': channel === 'landing'}"
  >
    <div class="row">
      <h2>{{ $t(`kpi.${channel}.${kpiskey}`) }}</h2>
      <el-select
        v-if="dataselect"
        :value="dataselect"
      />
    </div>

    <div class="block-undelivered">
      <div
        v-if="undelivered"
        class="chart-container"
      >
        <ChartDonut
          v-if="ifChartData"
          :chart-data="chartUndelivered"
          :options="chartOptions"
        />
        <div
          v-else
          class="no-data"
        >
          <strong>{{ $t('detail.no_data') }}</strong>
        </div>
      </div>
      <div class="legende-container">
        <GraphAutoLegend
          :labels="chartUndelivered.labels"
          :tooltips="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ChartDonut } from '@/components/Common/Chart'
import { GraphAutoLegend } from '@/components/Common/Graph'

export default {
  name: 'BlockGraph',
  components: { ChartDonut, GraphAutoLegend },
  props: {
    channel: {
      type: String,
      required: true
    },
    kpiskey: {
      type: String,
      required: true
    },
    kpisvalue: {
      type: Array,
      default: () => []
    },
    kpis: {
      type: Object,
      default: () => {}
    },
    overview : {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dataselect: '',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex]
              const currentValue = dataset.data[tooltipItem.index]
              return currentValue
            },
            title (tooltipItem, data) {
              return data.labels[tooltipItem[0].index].label
            }
          }
        }
      }
    }
  },
  computed: {
    undelivered () {
      return this.overview.undelivered || []
    },
    chartUndelivered () {
      return this.customizer(this.undelivered, 'Undelivered')
    },
    ifChartData () {
      for (let i in this.chartUndelivered.datasets[0].data) {
        if (this.chartUndelivered.datasets[0].data[i] > 0) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    customizer (original, label) {
      let bgc = []
      let labelTrad = []
      let datasets = []

      if (original && original.labels) {
        for (let i in original.labels) {
          bgc.push(this.$options.filters.autocolorize(i))
          labelTrad.push({
            label: this.$t(`labels.${original.labels[i]}`),
            tooltip: this.$t(`labels.${original.labels[i]}_tip`)
          })
        }
      }
      if (original && original.datasets) {
        datasets = original.datasets[0].data
      }
      return { labels: labelTrad, datasets: [{ label: label, data: datasets, backgroundColor: bgc }] }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.bp-graph {
  .row {
    margin-bottom: 1rem;
  }
}
.block-undelivered {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .chart-container {
    position: relative;
    width: 250px;
    height: 250px;
    padding: 0;
    margin: 0 2rem;
    border: none;
  }
  .no-data {
    position: relative;
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $--text-light;
    border-radius: 50%;

    strong {
      text-align: center;
      line-height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 30px;
      margin-left: -50%;
      margin-top: -15px;
    }
    &::before {
      content: "";
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 50%;
      background-color: white;
      border-radius: 50%;
    }
  }
  .legende-container {
    flex: 0.8;
    .legende {
      justify-content: start;
    }
  }
}
</style>
