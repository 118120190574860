<template>
  <section class="home">
    <div
      v-if="showAlert"
      class="alert_no_campaign"
    >
      <el-alert
        :title="$t('global.alert_no_campaign')"
        type="info"
        show-icon
      />
    </div>
    <header class="page-header">
      <div class="welcome">
        <h1>{{ $t('dashboard.title') }}</h1>
        <p>{{ $t('dashboard.subtitle') }}</p>
        <p :class="{'invisible': loading}">
          <span v-if="dashboard">
            {{ $tc('dashboard.campaign_count', dashboard.info.last_30_days_campaigns_nb) }}
          </span>
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="$tc('dashboard.range', 30)" />
          <!-- eslint-enable vue/no-v-html -->
        </p>
      </div>

      <GroupSelector @updated="loadBoard" />
    </header>
    <main class="page_content">
      <div class="blocks-container multi_chan">
        <CampaignSummary
          channel-name="email"
          :loading="loading"
          :campaigns="getCampaigns('email')"
        />
        <CampaignSummary
          channel-name="sms"
          class="block-middle"
          :loading="loading"
          :campaigns="getCampaigns('sms')"
        />
        <CampaignSummary
          channel-name="push"
          :loading="loading"
          :campaigns="getCampaigns('push')"
        />
        <div class="chart-container">
          <h2>{{ $t('dashboard.graph_title') }}</h2>
          <ChartBar
            :chart-data="customizedData"
            :options="chartOptions"
          />
          <div class="legende-container">
            <GraphLegend :labels="labels" />
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import { CampaignSummary } from '@/components/Common/Campaign'
import { ChartBar } from '@/components/Common/Chart'
import { GroupSelector } from '@/components/Common/Form'
import { GraphLegend } from '@/components/Common/Graph'
import { get as _get } from 'lodash-es'


export default {
  name: 'ViewsHome',
  components: {
    CampaignSummary, ChartBar, GroupSelector, GraphLegend
  },
  data () {
    return {
      channels: ['email', 'sms', 'push'],
      labels: ['emails', 'sms', 'push'],
      labelsMap: {'emails': 'Emails', 'sms': 'SMS', 'push': 'Push notifications'},
      empty_chan: { name: 'email', campaigns: [] },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    }
  },
  computed: {
    showAlert () {
      return this.$store.getters['main/alerts']
    },
    channelCount () {
      return this.dashboard ? Object.keys(this.dashboard.campaigns).length : 0
    },
    currentGroup () {
      const groups = this.$store.getters['app/selectedGroups']
      const hasMultiSelectedGroup = _get(groups, 'length') > 1

      // If more than one group selected reset the filters
      if (hasMultiSelectedGroup) {
        this.$store.dispatch('app/selectGroups', [])
        return null
      }

      return _get(groups, '0') || null
    },
    hasCampaignPerm () {
      return this.$store.getters['session/hasPermission']('dashboardly:role:campaigns:r')
    },
    loading () {
      return this.$store.getters['board/loading']
    },
    dashboard () {
      let groupName = '_'
      if (this.currentGroup) {
        groupName = this.currentGroup.name
      }
      return this.$store.getters['board/board'](groupName)
    },
    isGraphData () {
      if (this.dashboard) {
        const chansData = this.dashboard.info.sent_campaigns_6_months.datasets
        for (let i in chansData) {
          if (chansData[i].data.length) {
            return true
          }
        }
      }
      return false
    },
    customizedData () {
      const months = this.$t('global.months')
      const custom = { labels: [], datasets: [] }
      if (!this.dashboard || !this.dashboard.info) { return custom }
      const chartData = this.dashboard.info.sent_campaigns_6_months

      // Custom months
      for (let i in chartData.labels) {
        let monthIndex = new Date(chartData.labels[i]).getMonth()
        custom.labels.push(months[monthIndex])
      }

      // Colorize

      for (let i in chartData.datasets) {
        const _set = chartData.datasets[i]
        custom.datasets.push({
          backgroundColor: this.$options.filters.colorize(_set.label),
          label: this.labelsMap[_set.label],
          data: _set.data
        })
      }
      return custom
    }
  },
  async mounted () {
    this.loadBoard()
    if (this.showAlert) {
      setTimeout(() => {
        this.$store.commit('main/setAlertNoCampaign', false)
      }, 10000)
    }
  },
  methods: {
    getCampaigns (channel) {
      if (!this.dashboard || !this.dashboard.campaigns ||
        !this.dashboard.campaigns[channel]) {
        return []
      }
      return this.dashboard.campaigns[channel]
    },
    async loadBoard () {
      this.urlParams()
      // If data has already been loaded dashboard will not be empty
      if (this.dashboard || !this.hasCampaignPerm) {
        return
      }

      let params = {}
      if (this.currentGroup) { params.groups = [this.currentGroup] }
      await this.$store.dispatch('board/getBoard', params)
    },
    urlParams () {
      let queryParams = {}
      if (this.currentGroup) { queryParams['group_ids'] = this.currentGroup.id }
      this.$router.replace({ query: queryParams }).catch(() => {})
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.home {
  max-width: $--max-width;
  padding: 0 50px;
  margin: auto;

  .alert_no_campaign {
    padding-top: 3rem;
    .el-alert {
      padding: 1rem 1.5rem;
      color: #666;
      .el-alert__closebtn {
        padding-top: 0.4rem;
      }
    }
  }
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 4rem;

    .welcome {
      p {
        font-size: 16px;
      }
    }

    @media only screen and (max-width: $--mobile-width) {
      flex-direction: column-reverse;
      .el-dropdown {
        align-self: flex-end;
        margin-bottom: 1rem;
      }
    }
  }
  .page_content {
    .blocks-container {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .block-middle {
        margin-left: 3rem;
        margin-right: 3rem;
      }
      .card {
        flex: 1;
      }
    }
    .chart-container {
      h2 {
        margin-bottom: 2rem;
      }
      .legende-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .legende {
          ul {
            display: flex;
            li {
              margin: 1rem;
            }
          }
        }
      }
    }
    .single_chan {
      .chart-container {
        flex: 2;
        margin-left: 3rem;
      }
    }
    .multi_chan {
      flex-flow: row wrap;
      align-items: stretch;
      margin: auto;

      .card {
        margin-bottom: 3rem;
      }
      .chart-container {
        flex: 1 100%;
        width: 100%;
      }
    }
    .single_chan,
    .multi_chan {
      @media only screen and (max-width: $--mobile-width) {
        flex-direction: column;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        .chart-container {
          flex: 1;
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}
</style>
