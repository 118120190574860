import Vue from 'vue'
import VueRouter from 'vue-router'

import CRMGuard from './guards/CRMGuard'
import scrollGuard from './guards/scrollGuard'
import authGuard from './guards/authGuard'

import routes from './routes'

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => authGuard(to, from, next))
router.beforeEach((to, from, next) => CRMGuard(to, from, next))
router.beforeEach((to, from, next) => scrollGuard(to, from, next))

export default router
