<template>
  <div class="page_admin_reports">
    <header class="page-header">
      <p>{{ $t('admin.reports.subtitle') }}</p>
    </header>
    <main class="page_content">
      <TableListing
        type="reports"
        :items="reports"
        :search="filters.search"
        :per-page-limit="filters.limit"
        :page="filters.page"
        :total="total"
        :total-idms="total"
        :create-button-label="$t('BUTTON.CREATE_REPORT')"
        @update-search="updateSearch"
        @update-page-limit="updatePageLimit"
        @update-current-page="updateCurrentPage"
        @handle-action="handleAction"
      />
    </main>

    <!-- Action modals -->
    <ModalReportForm
      :selected-report.sync="selectedReport"
      :show-report-form-modal.sync="showReportFormModal"
      @report-created="debouncedLoad"
    />
    <ModalDeleteReport
      :selected-report.sync="selectedReport"
      :show-delete-report-modal.sync="showDeleteReportModal"
      @report-deleted="debouncedLoad"
    />
  </div>
</template>

<script>
import { debounce as _debounce } from 'lodash-es'
import { TableListing } from '@/components/Common/Table'
import { ModalReportForm, ModalDeleteReport } from '@/components/Parts/Reports'

export default {
  name: 'ViewsAdminReports',
  components: { TableListing, ModalReportForm, ModalDeleteReport },
  data () {
    return {
      selectedReport: null,
      showReportFormModal: false,
      showDeleteReportModal: false,
      filters: {
        page: 1,
        limit: 20,
        search: ''
      }
    }
  },
  computed: {
    reports () {
      return this.$store.getters['reports/items']
    },
    total () {
      return this.$store.getters['reports/total']
    },
    reportsConfig () {
      return this.$store.getters['session/reportsConfig']
    }
  },
  mounted () {
    this.debouncedLoad()
    if (this.reportsConfig.ssrs_enabled) {
      this.$store.dispatch('reports/getReportServerItems')
    }
  },
  methods: {
    debouncedLoad: _debounce(function () {
      this.loadData()
    }, 250),
    handleEdit (index, row) {
      this.selectedReport = row
      this.showReportFormModal = true
    },
    handleDelete (index, row) {
      this.selectedReport = row
      this.showDeleteReportModal = true
    },
    updateSearch (search) {
      this.filters.search = search
      this.filters.page = 1
      this.debouncedLoad()
    },
    updateCurrentPage (page) {
      this.filters.page = page
      this.debouncedLoad()
    },
    updatePageLimit (limit) {
      this.filters.limit = limit
      this.filters.page = 1
      this.debouncedLoad()
    },
    loadData () {
      const params = {
        l: this.filters.limit,
        p: this.filters.page,
        bypass_groups: true
      }
      if (this.filters.search) {
        params.s = this.filters.search
      }
      this.$store.dispatch('reports/listReports', params)
    },
    handleAction (e) {
      this.selectedReport = e.report
      if (e.action === 'delete') {
        this.showDeleteReportModal = true
      } else {
        this.showReportFormModal = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page_admin_reports {
  .page-header, .page_content {
    padding-top: 20px;
  }
}
</style>
