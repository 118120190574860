import Vue from 'vue'
import axios from 'axios'

import APIException from './exceptions'

const SET_LOADING = 'SET_LOADING'
const SET_REPORT_LOADING = 'SET_REPORT_LOADING'
const SET_ITEMS = 'SET_ITEMS'
const SET_CURRENT_REPORT = 'SET_CURRENT_REPORT'
const SET_EMBED_INFO = 'SET_EMBED_INFO'
const SET_EMBED_TOKEN = 'SET_EMBED_TOKEN'
const SET_UPDATING = 'SET_UPDATING'
const SET_REPORT_SERVER_ITEMS = 'SET_REPORT_SERVER_ITEMS'

const CancelToken = axios.CancelToken
let reportsListCancel

const moduleReports = {
  namespaced: true,
  state: {
    loading: false,
    reportLoading: false,
    items: [],
    total: null,
    currentReport: null,
    embedInfo: null,
    updating: false,
    reportServerItems: []
  },
  mutations: {
    [SET_LOADING] (state, val) {
      state.loading = val
    },
    [SET_REPORT_LOADING] (state, val) {
      state.reportLoading = val
    },
    [SET_ITEMS] (state, val) {
      state.items = val.objects
      state.total = val.meta.total
    },
    [SET_CURRENT_REPORT] (state, val) {
      state.currentReport = val
    },
    [SET_EMBED_INFO] (state, val) {
      state.embedInfo = val
    },
    [SET_EMBED_TOKEN] (state, val) {
      val.embed_url = state.embedInfo.embed_url
      state.embedInfo = val
    },
    [SET_UPDATING] (state, val) {
      state.updating = val
    },
    [SET_REPORT_SERVER_ITEMS] (state, val) {
      state.reportServerItems = val
    }
  },
  actions: {
    async listReports ({ commit }, params) {
      commit(SET_LOADING, true)
      try {
        if (reportsListCancel) { reportsListCancel() }
        const response = await Vue.axios.get('/api/reports', {
          params: params,
          cancelToken: new CancelToken(function executor(c) {
            reportsListCancel = c
          })
        })
        if (response.status >= 300) { throw response }
        commit(SET_ITEMS, response.data)
        return response
      } catch (err) {
        throw new APIException(err)
      } finally {
        commit(SET_LOADING, false)
      }
    },
    async getReport ({ commit }, { reportId, bypassGroups }) {
      commit(SET_REPORT_LOADING, true)
      try {
        const url = `/api/reports/${reportId}`
        let params = {}
        if (bypassGroups) {
          params.bypass_groups = true
        }
        const response = await Vue.axios.get(url, { params: params })
        if (response.status >= 300) { throw response }
        commit(SET_CURRENT_REPORT, response.data)
        return response
      } catch (err) {
        commit(SET_CURRENT_REPORT, null)
        throw new APIException(err)
      } finally {
        commit(SET_REPORT_LOADING, false)
      }
    },
    async embed ({ commit }, reportId) {
      commit(SET_LOADING, true)
      commit(SET_EMBED_INFO, null)
      try {
        const url = `/api/reports/${reportId}/embed`
        const response = await Vue.axios.get(url)
        if (response.status >= 300) { throw response }
        commit(SET_EMBED_INFO, response.data)
      } catch (err) {
        commit(SET_EMBED_INFO, null)
        throw new APIException(err)
      } finally {
        commit(SET_LOADING, false)
      }
    },
    async refreshToken ({ commit }, reportId) {
      try {
        const url = `/api/reports/${reportId}/token`
        const response = await Vue.axios.get(url)
        if (response.status >= 300) { throw response }
        commit(SET_EMBED_TOKEN, response.data)
        return response.data
      } catch (err) {
        throw new APIException(err)
      }
    },
    async createReport ({ commit }, data) {
      commit(SET_UPDATING, true)
      try {
        const response = await Vue.axios.post('/api/reports', data)
        if (response.status >= 300) { throw response }
        return response
      } catch (err) {
        throw new APIException(err)
      } finally {
        commit(SET_UPDATING, false)
      }
    },
    async updateReport ({ commit }, { reportId, data }) {
      commit(SET_UPDATING, true)
      try {
        const url = `/api/reports/${reportId}`
        const response = await Vue.axios.put(url, data)
        if (response.status >= 300) { throw response }
        return response
      } catch (err) {
        throw new APIException(err)
      } finally {
        commit(SET_UPDATING, false)
      }
    },
    async deleteReport ({ commit }, reportId) {
      commit(SET_UPDATING, true)
      try {
        const url = `/api/reports/${reportId}`
        const response = await Vue.axios.delete(url)
        if (response.status >= 300) { throw response }
        return response
      } catch (err) {
        throw new APIException(err)
      } finally {
        commit(SET_UPDATING, false)
      }
    },
    async validatePowerBIParams ({ commit }, data) {
      commit(SET_LOADING, true)
      try {
        const response = await Vue.axios.post('/api/powerbi/validate', data)
        if (response.status >= 300) { throw response }
        return response
      } catch (err) {
        throw new APIException(err)
      } finally {
        commit(SET_LOADING, false)
      }
    },
    async getReportServerItems ({ commit }) {
      commit(SET_LOADING, true)
      try {
        const url = `/api/ssrs/reports`
        const response = await Vue.axios.get(url)
        if (response.status >= 300) { throw response }
        commit(SET_REPORT_SERVER_ITEMS, response.data.objects)
      } catch (err) {
        commit(SET_REPORT_SERVER_ITEMS, null)
        throw new APIException(err)
      } finally {
        commit(SET_LOADING, false)
      }
    }
  },
  getters: {
    loading: state => state.loading,
    items: state => state.items,
    total: state => state.total,
    currentReport: state => state.currentReport,
    reportLoading: state => state.reportLoading,
    embedInfo: state => state.embedInfo,
    updating: state => state.updating,
    reportServerItems: state => state.reportServerItems
  }
}

export default moduleReports
