<template>
  <section class="section_listing">
    <div
      v-if="isAdmin"
      class="listing__header"
    >
      <h1>{{ $t("admin.reports.title") }}</h1>
      <div class="create_report">
        <el-input
          v-model="formSearch"
          :placeholder="searchPlaceholder"
          clearable
        >
          <i
            slot="prefix"
            class="material-icons md-24"
          >search</i>
        </el-input>
        <el-button
          v-if="createButtonLabel"
          class="card-item-view-btn"
          type="primary"
          @click.native="$emit('handle-action', {'action': 'create'})"
        >
          <i
            class="el-icon--left el-icon-plus"
          />
          {{ createButtonLabel }}
        </el-button>
      </div>
    </div>

    <div v-show="isLoading">
      <LoaderListing class="campaign-placeholder" />
      <LoaderListing class="campaign-placeholder" />
      <LoaderListing class="campaign-placeholder" />
      <LoaderListing class="campaign-placeholder" />
      <LoaderListing class="campaign-placeholder" />
    </div>

    <component
      :is="tableType"
      v-show="!isLoading"
      :data="items"
      :empty-label="emptyLabelPlaceholder"
      :display-all-kpis="displayAllKpis"
      :total="totalIdms"
      @sort-by="e => $emit('updated-sort-by', e)"
      @triggered-action="(e) => $emit('handle-action', e)"
      @update-display-all-kpis="() => $emit('update-display-all-kpis')"
    />

    <div class="pagination">
      <el-pagination
        small
        layout="prev, pager, next, sizes"
        :current-page.sync="currentPage"
        :page-sizes="[20, 50, 100]"
        :page-size="perPageLimit"
        :hide-on-single-page="false"
        :pager-count="7"
        :total="total"
        @size-change="(limit) => $emit('update-page-limit', limit)"
      />
    </div>
  </section>
</template>

<script>
import { LoaderListing } from '@/components/Common/Loader'
import ListEmail from './ListEmail'
import ListSMS from './ListSMS'
import ListLanding from './ListLanding'
import ListReport from './ListReport'
import ListPush from './ListPush'
import ListQRCodes from './ListQRCodes'

export default {
  name: 'TableListing',
  components: {
    ListEmail,
    ListSMS,
    ListLanding,
    ListReport,
    LoaderListing,
    ListPush,
    ListQRCodes
  },
  props: {
    type: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    perPageLimit: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    search: {
      type: String,
      required: true
    },
    total: {
      type: Number,
      default: 0
    },
    totalIdms: {
      type: Number,
      default: 0
    },
    createButtonLabel: {
      type: String,
      default: ''
    },
    displayAllKpis: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isAdmin () {
      return this.type === 'reports'
    },
    currentPage: {
      get () {
        return this.page
      },
      set (newPage) {
        if (newPage === this.page) { return }
        this.$emit('update-current-page', newPage)
      }
    },
    formSearch: {
      get () {
        return this.search
      },
      set (newVal) {
        if (newVal === this.search) { return }
        this.$emit('update-search', newVal)
      }
    },
    searchPlaceholder () {
      const ns = ['landing', 'reports'].indexOf(this.type) > -1 ? this.type : 'global'
      return this.$t(`${ns}.search`)
    },
    emptyLabelPlaceholder () {
      if (this.type === 'landing') {
        return this.search ? this.$t('table.content.empty_filtered_landings') : this.$t('table.content.empty_landings')
      } else if (this.type === 'reports') {
        return this.search ? this.$t('table.content.empty_filtered_reports') : this.$t('table.content.empty_reports')
      } else {
        return this.search ? this.$t('table.content.empty_filtered_campaigns') : this.$t('table.content.empty_campaigns')
      }
    },
    isLoading () {
      return this.$store.getters['email/isLoadingItems'] ||
        this.$store.getters['sms/isLoadingItems'] ||
        this.$store.getters['push/isLoadingItems'] ||
        this.$store.getters['landing/isLoadingItems'] ||
        this.$store.getters['reports/loading'] ||
        this.$store.getters['qrCodes/isLoadingItems']
    },
    tableType () {
      switch (this.type) {
        case 'sms':
          return ListSMS
        case 'landing':
          return ListLanding
        case 'reports':
          return ListReport
        case 'push':
          return ListPush
        case 'qr':
          return ListQRCodes
        default:
          return ListEmail
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.section_listing {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  padding: 2rem;
  .listing__header {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-start;
    justify-content: space-between;
    .create_report {
      display: flex;
      height: 40px;
      .el-input {
        margin-right: 15px;
        .el-input__inner {
          &::placeholder {
            font-size: 12px;
            font-family: 'Open Sans', sans-serif;
          }
          border-radius: 2px;
          width: 230px;
        }
        .el-input__prefix {
          color: $--color-search-icon;
        }
      }
    }
  }
  th {
    color: $--text-dark;
    font-size: 11px;
  }
  td {
    color: $--text-dark;
    font-size: 13px;
  }
  .el-table__row {
    cursor: pointer;
  }
  .pictify-0 {
    display: inline-block;
    color: white;
    background-color: #eee;
    border-radius: 20px;
    text-align: center;
    line-height: 17.5px;
    width: 27px;
    height: 27px;
    font-family: "Ubuntu", sans-serif;
  }
  .pictify {
    display: inline-block;
    color: white;
    border-radius: 27px;
    text-align: center;
    line-height: 17.5px;
    width: 27px;
    height: 27px;
    font-family: "Ubuntu", sans-serif;
    img {
      width: 100%;
      height: auto;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    position: relative;

    .el-pagination {
      height: 30px;
      .el-pagination__sizes {
        position: absolute;
        right: 0;
        margin-right: 0;
        .el-input {
          margin: 0;
        }
        .el-select {
          justify-content: flex-end;
        }
      }
    }
  }
  @media only screen and (max-width: $--mobile-width) {
    .el-input--prefix .el-input__inner {
      width: 100%;
    }
    .pagination {
      padding-bottom: 2rem;
      .el-pagination {
        .el-pagination__sizes {
          position: relative;
          display: block;
          margin: 1rem 0;
          text-align: center;
        }
      }
    }
  }
}
</style>
