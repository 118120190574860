<template>
  <div
    ref="embed-container"
    class="report-container"
  >
    <iframe
      v-if="!embedded && ready"
      :src="iframeUrl"
    />
  </div>
</template>

<script>
import * as pbi from 'powerbi-client'
import {
  get as _get,
  uniq as _uniq
} from 'lodash-es'

export default {
  name: 'EmbeddedReport',
  props: {
    embedded: {
      type: Boolean,
      required: true
    },
    embedUrl: {
      type: String,
      required: true
    },
    embedToken: {
      type: String,
      default: ''
    },
    reportId: {
      type: String,
      default: ''
    },
    groupFilter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      embeddedReport: null,
      componentLoaded: false,
      pbiSrvc: window.powerbiService
    }
  },
  computed: {
    group () {
      const groups = this.$store.getters['app/selectedGroups']
      const hasMultiSelectedGroup = _get(groups, 'length') > 1

      // If more than one group selected reset the filters
      if (hasMultiSelectedGroup) {
        this.$store.dispatch('app/selectGroups', [])
        return null
      }

      return _get(groups, '0') || null
    },
    allGroupTags () {
      // Note: if a group does not exist in the CRM database, it has no tag_id.
      // Yet, we need to set a value anyway (0) because we want the
      // filter to apply (and return no data).
      const groups = this.$store.getters['session/groups'] || []
      return _uniq(groups.map(gr => gr.tag_id || 0))
    },
    ready () {
      // 1. Component must be loaded ($refs must be accessible)
      // 2. Groups must be loaded if group filter is enabled
      return this.componentLoaded && (!this.groupFilter || this.allGroupTags)
    },
    iframeUrl () {
      let filter = ''
      if (this.groupFilter) {
        let tags = this.group ? [this.group.tag_id || 0] : this.allGroupTags
        tags = tags.join()
        filter = `&tag=${tags}`
      }
      return `${this.embedUrl}${filter}`
    },
    pbiEmbeddedFilters() {
      // doc: https://github.com/Microsoft/PowerBI-JavaScript/wiki/Filters#contructingfilters
      let filters = []
      if (this.groupFilter) {
        const tagFilter = new pbi.models.BasicFilter(
          {
            table: 'vRefTag_Tag',
            column: 'Id_Tag'
          },
          'In',
          this.group ? [this.group.tag_id || 0] : this.allGroupTags
        )
        filters = [tagFilter]
      }
      return filters
    },
    pbiEmbeddedConfig() {
      // doc: https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details
      return {
        'type': 'report',
        'tokenType': pbi.models.TokenType.Embed,
        'accessToken': this.embedToken,
        'embedUrl': this.embedUrl,
        'id': this.reportId,
        'permissions': pbi.models.Permissions.All,
        'settings': {
          'navContentPaneEnabled': true,
          'localeSettings': {
            'language': this.$i18n.locale,
            'formatLocale': this.$i18n.locale
          }
        },
        'filters': this.pbiEmbeddedFilters
      }
    },
  },
  watch: {
    ready: {
      immediate: true,
      handler (ready) {
        if (ready && this.embedded) {
          this.embedReport()
        }
      }
    }
  },
  created () {
    this.componentLoaded = true
  },
  methods: {
    embedReport () {
      // Embed the report and display it within the container
      const embedContainer = this.$refs['embed-container']
      this.pbiSrvc.reset(embedContainer)
      this.embeddedReport = this.pbiSrvc.embed(embedContainer, this.pbiEmbeddedConfig)
      // Replace default error handler
      this.embeddedReport.off('error')
      this.embeddedReport.on('error', this.handleError)
      // Set up group filter
      this.applyGroupFilter()
    },
    applyGroupFilter () {
      if (!this.embeddedReport) {
        return
      }
      this.embeddedReport.setFilters(this.pbiEmbeddedFilters)
      .catch(errors => {
        this.handleError(errors)
      })
    },
    setToken (token) {
      // https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/refresh-token#automatically-refresh-token-example
      if (token && this.embeddedReport) {
        this.embeddedReport.setAccessToken(token)
      }
    },
    handleError () {
      let errNotif = {
        title: this.$t('ERRORS.UNEXPECTED'),
        message: this.$t('ERRORS.UNEXPECTED_DESC'),
        type: 'error'
      }
      this.$notify(errNotif)
    }
  }
}
</script>

<style lang='scss'>
.report-container {
  width: 100%;
  height: 100%;

  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}
</style>
