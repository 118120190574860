export default {
  methods: {
    logifier(groupname) {
      if (!groupname) { return null }
      const group = this.$store.getters['session/groups'].filter(function (item) {
        if (item.name.toUpperCase() === groupname.toUpperCase()) {
          return item.logo_url
        }
      })[0]
      if (!group) { return null }
      return group.logo_url
    }
  }
}
