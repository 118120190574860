export default class APIException {
  constructor (response) {
    if (!response) {
      response = {
        status: 499,
        message: "Cancelled request",
        headers: {}
      }
    }
    this.status = response.status
    this.headers = response.headers
    if (response.data && response.data.errors ) {
      this.message = this.data.errors
    } else if (response.data && response.data.message) {
      this.message = response.data.message
    } else {
      this.message = 'API returned an error'
    }
    this.toString = function() {
      return `${this.value}: ${this.message}`
    }
  }
}
