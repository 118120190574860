<template>
  <el-dialog
    width="600px"
    custom-class="admin-dialog"
    :visible="showReportFormModal"
    :append-to-body="true"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @open="initDialog"
  >
    <!-- DIALOG TITLE -->
    <span slot="title">
      {{ modalTitle }}
    </span>

    <!-- DIALOG SUBTITLE -->
    <div
      v-if="modalSubtitle"
      class="el-dialog__subtitle"
    >
      {{ modalSubtitle }}
    </div>

    <!-- REPORT TYPE SELECTION -->
    <div
      v-if="step === 1"
      class="type-select"
    >
      <div
        class="type-select__box"
        :class="{ 'type-select__box--active': pbiEmbeddedReport }"
        @click="formData.type_id = reportTypes.POWERBI_EMBEDDED"
      >
        <div class="type-select__box__icon">
          <i class="material-icons">pie_chart</i>
        </div>
        <div class="type-select__box__title">
          <el-radio
            v-model="formData.type_id"
            :label="reportTypes.POWERBI_EMBEDDED"
            name="report-type"
          >
            {{ $t('reports.create.powerbi.type') }}
          </el-radio>
        </div>
        <div class="type-select__box__desc">
          {{ $t('reports.create.powerbi.desc') }}
        </div>
      </div>
      <div
        class="type-select__box"
        :class="{ 'type-select__box--active': ssrsReport }"
        @click="formData.type_id = reportTypes.SSRS"
      >
        <div class="type-select__box__icon">
          <i class="material-icons">assignment</i>
        </div>
        <div class="type-select__box__title">
          <el-radio
            v-model="formData.type_id"
            :label="reportTypes.SSRS"
            name="report-type"
          >
            {{ $t('reports.create.ssrs.type') }}
          </el-radio>
        </div>
        <div class="type-select__box__desc">
          {{ $t('reports.create.ssrs.desc') }}
        </div>
      </div>
    </div>

    <!-- FORM -->
    <el-form
      ref="reportForm"
      v-loading="loading"
      class="admin-dialog__form"
      label-position="top"
      :model="formData"
      :rules="rules"
      @submit.prevent.native="submitForm()"
    >
      <div v-if="step >= 2 && pbiEmbeddedReport">
        <!-- pbi_group_id -->
        <el-form-item
          :label="$t('reports.create.group_id')"
          prop="pbi_group_id"
        >
          <el-input
            v-model="formData.pbi_group_id"
            class="placeholder-input"
            :disabled="step === 3"
          />
        </el-form-item>

        <!-- pbi_report_id -->
        <el-form-item
          :label="$t('reports.create.report_id')"
          prop="pbi_report_id"
        >
          <el-input
            v-model="formData.pbi_report_id"
            class="placeholder-input"
            :disabled="step === 3"
          />
        </el-form-item>

        <!-- pbi_dataset_id -->
        <el-form-item
          :label="$t('reports.create.dataset_id')"
          prop="pbi_dataset_id"
        >
          <el-input
            v-model="formData.pbi_dataset_id"
            class="placeholder-input"
            :disabled="step === 3"
          />
        </el-form-item>

        <el-alert
          v-show="displayParamsError"
          :title="$t('reports.create.invalid_pbi_ids')"
          :closable="false"
          type="error"
          show-icon
        />
      </div>

      <!-- ssrs_report_name -->
      <el-form-item
        v-if="step >= 2 && ssrsReport"
        :label="$t('reports.create.ssrs_report_name')"
        prop="ssrs_report_name"
      >
        <el-select
          v-model="formData.ssrs_report_name"
          :placeholder="$t('reports.create.ssrs_report_name_placeholder')"
          :disabled="step === 3"
          :no-data-text="$t('reports.create.ssrs_report_name_no_data')"
        >
          <el-option
            v-for="item in reportServerItems"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
      </el-form-item>

      <div v-if="step === 3">
        <!-- name -->
        <el-form-item
          :label="$t('reports.create.name')"
          prop="name"
        >
          <el-input
            v-model="formData.name"
            maxlength="200"
            class="placeholder-input"
          />
        </el-form-item>

        <!-- thumbnail -->
        <el-form-item
          :label="$t('reports.create.thumbnail')"
          prop="thumbnail"
        >
          <ImageRadio
            :current-value="formData.thumbnail"
            :items="thumbnails"
            @updated="onThumbnailSelected"
          />
        </el-form-item>

        <!-- groups -->
        <el-form-item
          :label="$t('reports.create.groups')"
          prop="all_groups"
          class="mb-10"
        >
          <p>{{ $t('reports.create.groups_info') }}</p>
          <el-checkbox
            v-model="formData.all_groups"
            :label="$t('reports.create.all_groups')"
            name="all_groups"
            @change="toggleAllGroups"
          />
        </el-form-item>
        <el-form-item
          label-width="0"
          prop="groups"
        >
          <el-select
            v-model="formData.groups"
            :disabled="formData.all_groups"
            multiple
            filterable
            remote
            reserve-keyword
            :remote-method="getGroups"
            :placeholder="$t('reports.create.groups_placeholder')"
            :no-data-text="$t('reports.create.ssrs_report_name_no_data')"
          >
            <el-option
              v-for="group in groupsOptions"
              :key="group.id"
              :label="group.name"
              :value="group.id"
            />
          </el-select>
        </el-form-item>

        <!-- group filter -->
        <el-form-item
          :label="$t('reports.create.group_filter')"
          prop="has_group_filter"
          class="mb-10"
        >
          <p>{{ $t('reports.create.group_filter_info') }}</p>
          <el-checkbox
            v-model="formData.has_group_filter"
            :label="$t('reports.create.has_group_filter')"
            name="has_group_filter"
          />
        </el-form-item>
      </div>
    </el-form>

    <!-- FOOTER -->
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        :disabled="loading || updating"
        type="default"
        class="small-button"
        @click="cancelAction"
      >{{ $t('BUTTON.CANCEL') }}</el-button>
      <el-button
        v-loading="updating"
        :disabled="loading || !formData.type_id"
        type="primary"
        class="small-button"
        @click="confirmAction"
      >{{ $t('BUTTON.CONFIRM') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import config from '@/config'
import { orderBy as _orderBy } from 'lodash-es'
import { ImageRadio } from '@/components/Common/Form'

const _UUID_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/

export default {
  name: 'ModalReportForm',
  components: { ImageRadio },
  props: {
    showReportFormModal: {
      type: Boolean,
      default: false
    },
    selectedReport: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      reportTypes: config.REPORT_TYPES,
      formData: {},
      thumbnails: [],
      groupsOptions: [],
      selectedGroups: [],
      displayParamsError: false,
      step: null,
      rules: {
        pbi_report_id: [
          { required: true, message: this.$t('alerts.form_required_field'), trigger: 'blur' },
          { pattern: _UUID_PATTERN, message: this.$t('alerts.form_valid_field'), trigger: 'blur' }
        ],
        pbi_group_id: [
          { required: true, message: this.$t('alerts.form_required_field'), trigger: 'blur' },
          { pattern: _UUID_PATTERN, message: this.$t('alerts.form_valid_field'), trigger: 'blur' }
        ],
        pbi_dataset_id: [
          { required: true, message: this.$t('alerts.form_required_field'), trigger: 'blur' },
          { pattern: _UUID_PATTERN, message: this.$t('alerts.form_valid_field'), trigger: 'blur' }
        ],
        ssrs_report_name: [
          { required: true, message: this.$t('alerts.form_required_field'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('alerts.form_required_field'), trigger: 'blur' }
        ],
        thumbnail: [
          { required: true, message: this.$t('alerts.form_required_field'), trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    loading () {
      return this.$store.getters['reports/reportLoading'] ||
        this.$store.getters['group/loading'] ||
        this.$store.getters['reports/loading']
    },
    updating () {
      return this.$store.getters['reports/updating']
    },
    groups () {
      return this.$store.getters['group/items']
    },
    reportServerItems () {
      return this.$store.getters['reports/reportServerItems']
    },
    pbiEmbeddedReport () {
      return this.formData.type_id === config.REPORT_TYPES.POWERBI_EMBEDDED
    },
    ssrsReport () {
      return this.formData.type_id === config.REPORT_TYPES.SSRS
    },
    modalTitle () {
      if (this.selectedReport && this.pbiEmbeddedReport) {
        return this.$t('reports.update.powerbi.title')
      } else if (this.selectedReport && this.ssrsReport) {
        return this.$t('reports.update.ssrs.title')
      } else if (this.step > 1 && this.pbiEmbeddedReport) {
        return this.$t('reports.create.powerbi.title')
      } else if (this.step > 1 && this.ssrsReport) {
        return this.$t('reports.create.ssrs.title')
      } else {
        return this.$t('reports.create.title')
      }
    },
    modalSubtitle () {
      if (this.step === 1) {
        return this.$t('reports.create.subtitle')
      } else if (this.step === 2 && this.pbiEmbeddedReport) {
       return this.$t('reports.create.powerbi.subtitle')
      } else if (this.step === 2 && this.ssrsReport) {
        return this.$t('reports.create.ssrs.subtitle')
      }
      return null
    },

  },
  watch: {
    groups (newValue) {
      if (newValue) {
        this.getGroups()
      }
    }
  },
  mounted () {
    this.thumbnails = []
    for (const x of Array(6).keys()) {
      this.thumbnails.push({
        id: `report${x + 1}.svg`,
        src: require(`@/assets/svg/reports/report${x + 1}.svg`)
      })
    }
  },
  methods: {
    resetFormData () {
      this.formData = {
        type_id: null,
        name: null,
        thumbnail: this.thumbnails[0].id,
        all_groups: false,
        groups: [],
        has_group_filter: false,
        pbi_report_id: null,
        pbi_group_id: null,
        pbi_dataset_id: null,
        ssrs_report_name: null
      }
      this.selectedGroups = []
      this.displayParamsError = false
      this.step = null
    },
    toggleAllGroups () {
      if (this.formData.all_groups) {
        // backup selected groups
        this.selectedGroups = this.formData.groups
        this.formData.groups = []
      } else {
        // re-apply previously selected groups
        this.formData.groups = this.selectedGroups
      }
    },
    cancelAction () {
      if (this.$refs.reportForm) {
        this.$refs.reportForm.resetFields()
      }
      this.resetFormData()
      this.$emit('update:showReportFormModal', false)
    },
    async confirmAction () {
      this.displayParamsError = false
      try {
        await this.$refs.reportForm.validate()
      }
      catch (_) {
        return
      }

      if (this.step === 1) {
        this.step = 2
      } else if (this.step === 2) {
        if (this.pbiEmbeddedReport) {
          this.validatePbiParams()
        } else {
          this.step = 3
        }
      } else {
        this.submitForm()
      }
    },
    async validatePbiParams () {
      try {
        await this.$store.dispatch('reports/validatePowerBIParams', this.formData)
        this.step = 3
      }
      catch (err) {
        this.handleError(err)
      }
    },
    async submitForm () {
      try {
        let action, params
        let data = Object.assign({}, this.formData)
        if (data.all_groups) {
          data.groups = []
        }
        if (!this.selectedReport) {
          action = 'create'
          params = data
        } else {
          action = 'update'
          params = {
            reportId: this.selectedReport.id,
            data: data
          }
        }
        await this.$store.dispatch(`reports/${action}Report`, params)
        this.$notify({
          title: this.$t(`reports.${action}.success.title`),
          message: this.$t(`reports.${action}.success.message`),
          type: 'success'
        })
        this.$emit('report-created')
        this.cancelAction()
      }
      catch (err) {
        this.handleError(err)
      }
    },
    handleError (err) {
      if (err.status === 400) {
        this.displayParamsError = true
      } else {
        this.$notify({
          title: this.$t('ERRORS.UNEXPECTED'),
          message: this.$t('ERRORS.UNEXPECTED_DESC'),
          type: 'error'
        })
      }
    },
    onThumbnailSelected (thumb) {
      this.formData.thumbnail = thumb
    },
    getGroups (query) {
      if (query) {
        setTimeout(() => {
          const groups = this.groups.filter(item => {
            return item.name.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          })
          this.groupsOptions = _orderBy(groups, [group => group.name.toLowerCase()])
        }, 200)
      } else {
        this.groupsOptions = _orderBy(this.groups, [group => group.name.toLowerCase()])
      }
    },
    async initDialog () {
      if (this.selectedReport) {
        try {
          const params = {
            reportId: this.selectedReport.id,
            bypassGroups: true
          }
          const response = await this.$store.dispatch('reports/getReport', params)
          this.formData = response.data
          this.step = 3
        } catch(err) {
          this.handleError(err)
          this.cancelAction()
        }
      } else {
        this.resetFormData()
        this.step = 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

::v-deep .el-dialog .el-dialog__body {
  padding: 20px;
}

.admin-dialog {
  .el-dialog__subtitle {
    margin-bottom: 20px;
  }

  .admin-dialog__form {
    ::v-deep .el-form-item__label {
      padding-bottom: 0;
    }

    ::v-deep .el-form-item {
      &.mb-10 {
        margin-bottom: 10px;
      }

      .el-select {
        width: 100%;
      }

      p {
        line-height: 1.4;
        margin: 10px 0;
        text-align: justify;
      }
    }
  }

  .type-select {
    height: 180px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .type-select__box {
      border: 1px solid $--border-color-base;
      height: 100%;
      width: 49%;
      padding: 25px 20px;
      text-align: center;
      cursor: pointer;
      .type-select__box__icon i.material-icons {
        font-size: 24px;
      }
      .type-select__box__title {
        margin: 20px 0;
        ::v-deep .el-radio__label {
          font-weight: 600;
          padding-left: 5px;
        }
      }
      .type-select__box__desc {
        font-size: 12px;
      }
      &:hover {
        border-color: $--color-primary;
      }
      &.type-select__box--active {
        position: relative;
        border-color: $--color-primary;
        .type-select__box__icon,
        .type-select__box__title {
          color: $--color-primary;
        }
      }
    }
  }

  .small-button {
    min-width: 120px !important;
  }
}
</style>
