import { filterStatusColor } from '@/assets/styles/numberly-filters.scss'

const filterStatus = {
  data () {
    return {
      filterStatusColor: filterStatusColor,
      statusPublished: {
        label: this.$t('landing.published_pages'),
        value: {
          id: 'published',
          name: this.$t('landing.published_pages')
        }
      },
      statusUnpublished: {
        label: this.$t('landing.unpublished_pages'),
        value: {
          id: 'unpublished',
          name: this.$t('landing.unpublished_pages')
        }
      }
    }
  },
  methods: {
    /**
     * Get selected type ids, remove tags ids and init the type filter cascade
     */
     initSelectedStatus (defaultSelectedStatus = []) {
       const optionStatus = [this.statusPublished, this.statusUnpublished]
       let selectedStatus = []

      if (defaultSelectedStatus.length &&
          defaultSelectedStatus.length !== optionStatus.length) {
        selectedStatus = optionStatus
          .filter(opt => defaultSelectedStatus.includes(opt.value.id))
          .map(t => [t.value])
      }
      return { options: optionStatus, selected: selectedStatus }
    },

    /**
     * Format generated value from the cascader selection to be used externally of the Filter mechanism
     */
    formatStatusCascader (cascaderStatusSelection) {
      return cascaderStatusSelection.flat().map(v => v.id)
    }
  }
}

export default filterStatus
