<template>
  <div>
    <el-dropdown
      trigger="click"
      @command="handleCommand"
    >
      <el-button
        class="custom-select"
        type="secondary"
      >
        {{ $t('detail.multiple_exports') }}
        <i class="el-icon-arrow-down el-icon--right" />
      </el-button>
      <el-dropdown-menu
        slot="dropdown"
        class="export-download-dropdown"
      >
        <DownloadButton
          key="download"
          @click-download="(p) => $emit('click-download', p)"
        >
          <template v-slot:button>
            <el-dropdown-item
              key="download"
              command="download"
            >
              <i class="material-icons">get_app</i>
              {{ $t('detail.download_report') }}
            </el-dropdown-item>
          </template>
        </DownloadButton>
        <el-tooltip
          class="item"
          effect="dark"
          placement="top"
          :disabled="validExportDate"
        >
          <div slot="content">
            {{ $t('detail.export_sending_data_disabled_tooltip') }}
          </div>
          <!-- NOTE(@chtr): parent div of el-dropdown-item is required -->
          <!-- to be able to have a tooltip while item is disabled -->
          <div>
            <el-dropdown-item
              key="export-sending-data"
              command="export-sending-data"
              :disabled="!validExportDate"
            >
              <i class="material-icons">file_upload</i>
              {{ $t('detail.export_sending_data') }}
            </el-dropdown-item>
          </div>
        </el-tooltip>
        <el-dropdown-item
          key="view-exports"
          command="view-exports"
        >
          <i class="material-icons">insert_drive_file</i>
          {{ $t('detail.view_sending_data') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <ModalExportSendingData 
      :show-modal.sync="showExportSendingDataModal"
      :campaign="campaign"
      :campaign-id="campaignId"
      :channel-id="channelId"
    />
  </div>
</template>
  
<script>
import { DownloadButton } from '@/components/Common/Form' 
import ModalExportSendingData from './ModalExportSendingData.vue'


export default {
  name: 'DataExportButton',
  components: { DownloadButton, ModalExportSendingData },
  props: {
    campaign: {
      type: Object,
      default: () => {}
    },
    campaignId: {
      type: Number,
      required: true,
    },
    channelId: {
      type: Number,
      required: true
    },
    campaignType: {
      type: String,
      required: true
    }
  },
  data(){
    return {
      showExportSendingDataModal: false,
    }
  },
  computed: {
    rangeDate () {
      return this.$store.getters['app/rangeDate']
    },
    sendingDate () {
      return this.campaignType == 'one_shot' ? this.campaign.start_date : this.campaign.end_date
    },
    validExportDate () {
      // for all campaigns types
      // last sending date must be within last 12 months.
      const lastSendingDateDiffTime = new Date() - new Date(this.sendingDate);
      const lastSendingDateDiffDays = Math.floor(lastSendingDateDiffTime / (1000 * 60 * 60 * 24)); 
      let validExportDate = lastSendingDateDiffDays < 366

      // for automatic and applicative campaign
      // end_date in the range must be within last 12 months.
      if (this.campaignType != 'one_shot') {
        const diffTime = new Date() - new Date(this.rangeDate.end);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
        validExportDate = validExportDate && (diffDays < 366)
      }
      return validExportDate
    }
  },
  methods: {
    handleCommand (command) {
      switch (command) {
        case 'export-sending-data':
          this.showExportSendingDataModal = true
          break
        case 'view-exports':
          this.$emit('click-view-exports')
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
  
.export-download-dropdown {
  .el-dropdown-menu__item {
    i {
      vertical-align: text-bottom;
      font-size: 18px;
      text-align: center;
    }
  }
}
</style>
