<template>
  <section class="page_channel">
    <HeaderChannel
      channel="email"
      type="email"
      :kpis="kpis"
      :selected-tags="filters.tags"
      :search="filters.search"
      :tags="tags"
      :channel-tags="filters.channelTags"
      :download-loading="downloadLoading"
      @download="download"
      @update-search="updateSearch"
      @updated-group="updatedGroup"
      @updated-date-range="updatedDateRange"
      @updated-tags="updatedTags"
      @updated-channel-tags="updatedChannelTags"
      @clear-selected-filters="clearSelectedFilters"
    />
    <main class="page_content">
      <TableListing
        type="email"
        :items="emails"
        :search="filters.search"
        :per-page-limit="filters.limit"
        :page="filters.page"
        :total="total"
        :total-idms="totalIdms"
        :display-all-kpis="displayAllKpis"
        @updated-sort-by="updatedSortBy"
        @update-search="updateSearch"
        @update-page-limit="updatePageLimit"
        @update-current-page="updateCurrentPage"
        @update-display-all-kpis="updateDisplayAllKpis"
      />
    </main>
  </section>
</template>

<script>
import {
  debounce as _debounce,
  get as _get
} from 'lodash-es'
import config from '@/config'
import { isoDateString } from '@/helpers/isoDate'
import { urlParams } from '@/mixins'
import { HeaderChannel } from '@/components/Parts/Header'
import { TableListing } from '@/components/Common/Table'


const defaultTags = [
  config.EMAIL_CHANNEL_TAGS['automatic'],
  config.EMAIL_CHANNEL_TAGS['one_shot'],
  config.EMAIL_CHANNEL_TAGS['transactional']
]

export default {
  name: 'ViewsEmailList',
  components: {
    HeaderChannel,
    TableListing
  },
  mixins: [ urlParams ],
  data() {
    return {
      displayAllKpis: false
    }
  },
  computed: {
    currentGroups () {
      return this.$store.getters['app/selectedGroups'] || null
    },
    rangeDateRaw () {
      return this.$store.getters['app/rangeDate']
    },
    rangeDate () {
      const { start, end } = this.$store.getters['app/rangeDate']
      const tz = this.$store.getters['session/user'].timezone || 'UTC'

      return {
        start: isoDateString(start, tz),
        end: isoDateString(end, tz, true),
      }
    },
    kpis () {
      return this.$store.getters['email/kpis']
    },
    filters () {
      return this.$store.getters['email/filters']
    },
    emails () {
      return this.$store.getters['email/emails']
    },
    totalIdms () {
      return this.$store.getters['email/totalIdms']
    },
    total() {
      return this.$store.getters['email/totalEmails']
    },
    tags () {
      return this.$store.getters['tag/tags']
    },
    downloadLoading () {
      return this.$store.getters['email/downloadLoading']
    }
  },
  mounted () {
    let groups
    let tagsFilter
    let tagId
    let tag
    let tagList = []
    let channelsTag = []

    for (let key in this.$route.query) {
      switch (key) {
      case 'start_date':
        this.$store.dispatch('app/setStartDate', this.$route.query[key])
        break
      case 'end_date':
        this.$store.dispatch('app/setEndDate', this.$route.query[key])
        break
      case 'group_ids':
        groups = this.$store.getters['session/groups'].filter(g => this.$route.query[key].split(',').includes(g.id))
        if (groups.length) { this.$store.dispatch('app/selectGroups', groups) }
        break
      case 's':
        this.$store.dispatch('email/setSearch', this.$route.query[key])
        break
      case 'l':
        this.$store.dispatch('email/updatePageLimit', parseInt(this.$route.query[key]))
        break
      case 'p':
        this.$store.dispatch('email/updateCurrentPage', parseInt(this.$route.query[key]))
        break
      case 'tags':
        tagsFilter = this.$route.query[key].split(',')
        for (let value of tagsFilter) {
          tagId = value
          if (tagId.indexOf(":") > -1) {
            tagId = tagId.split(":")[1]
          }
          tagId = parseInt(tagId)
          tag = this.$store.getters['tag/tag'](tagId)
          if (tag) {
            tagList.push(tag)
          } else {
            for (const key in config.EMAIL_CHANNEL_TAGS) {
              if (config.EMAIL_CHANNEL_TAGS[key] === tagId) {
                channelsTag.push(key)
              }
            }
          }
        }
        if (tagList.length > 0) { this.$store.dispatch('email/setTags', tagList) }
        if (channelsTag.length > 0) { this.$store.dispatch('email/setChannelTags', channelsTag) }
        break
      }
    }
    this.loadData({ replaceUrl: true })
  },
  methods: {
    searchReactivity: _debounce(function () {
      this.loadData()
    }, 250),
    updateSearch (search) {
      this.$store.dispatch('email/setSearch', search)
      this.searchReactivity()
    },
    updatedGroup (groups) {
      this.$store.dispatch('app/selectGroups', groups)
      this.loadData()
    },
    updatedDateRange () {
      this.loadData()
    },
    updatePageLimit (pageLimit) {
      this.$store.dispatch('email/updatePageLimit', pageLimit)
      this.loadData()
    },
    updateCurrentPage (currentPage) {
      this.$store.dispatch('email/updateCurrentPage', currentPage)
      this.loadData({ resetPagination: false })
    },
    clearSelectedFilters () {
      this.$store.dispatch('app/selectGroups', [])
      this.$store.dispatch('email/setTags', [])
      this.$store.dispatch('email/resetFilters', [])
      this.loadData()
    },
    updatedTags (tags) {
      this.$store.dispatch('email/setTags', tags)
      this.loadData()
    },
    updatedChannelTags (newChannelTags) {
      this.$store.dispatch('email/setChannelTags', newChannelTags)
      this.loadData()
    },
    updatedSortBy (sortBy) {
      this.$store.dispatch('email/setSortBy', sortBy)
      this.loadData()
    },
    updateDisplayAllKpis() {
      this.displayAllKpis = !this.displayAllKpis
      this.loadData()
    },
    getParams ({ url=false, tagFamilies } = {}) {
      const params = {
        // Date
        start_date: this.rangeDate.start,
        end_date: this.rangeDate.end
      }

      if (this.displayAllKpis) {
        params.display_all_kpis = true
      }

      if (tagFamilies) {
        // Tags families
        const familiesId = tagFamilies ? tagFamilies.map(f => f.id).join(',') : null
        const familiesName = tagFamilies ? tagFamilies.map(f => f.name).join(',') : null
        params.tags_family_ids = familiesId
        params.tags_family_names = familiesName
      } else {
        // Pagination
        params.l = this.filters.limit
        params.p = this.filters.page
      }

      // Prepare tags
      let tags, selectedTags
      if (url) {
        tags = this.filters.channelTags.map(tag => config.EMAIL_CHANNEL_TAGS[tag]).join(',')
        selectedTags = this.filters.tags.map(tag => tag.id).concat(tags).join(',')
      } else {
        const hasChannelTags = this.filters.channelTags.length
        tags = hasChannelTags
          ? this.filters.channelTags.map(tag => config.EMAIL_CHANNEL_TAGS[tag]).join(',')
          : Object.values(config.EMAIL_CHANNEL_TAGS)
        selectedTags = this.filters.tags.map(tag => tag.id).concat(tags).join(',')
      }
      if (selectedTags.length) {
        params.tags = selectedTags
      }

      // Inject other filters
      if (this.filters.search) {
        params.sf = 'campaign_name'
        params.s = this.filters.search
      }
      if (this.filters.orderBy) {
        params.ob = this.filters.orderBy
      }
      if (this.currentGroups.length) {
        if (url) {
          params.group_ids = this.currentGroups.map(g => g.id).toString()
        } else {
          params.group_names = this.currentGroups.map(g => g.name).toString()
        }
      }

      return params
    },
    download (tagFamilies) {
      const requestParams = this.getParams({ tagFamilies })
      this.$store.dispatch('email/export', requestParams)
    },
    loadData (options) {
      // Pagination
      const updatePagination = _get(options, 'resetPagination') && this.filters.page != 1
      if (updatePagination) {
        this.$store.dispatch('email/updateCurrentPage', 1)
      }

      // Requests
      const requestParams = this.getParams()
      this.$store.dispatch('email/getKpis', requestParams)
      this.$store.dispatch('email/getItems', requestParams)

      // Url params update
      const urlParams = this.getParams({ url: true })
      this.urlParams(urlParams, this.rangeDateRaw, defaultTags)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
