<template>
  <img
    :src="logoUrl"
    :style="styles"
    alt="Dashobardly logo"
  >
</template>

<script>
import colorLogo from '@/assets/svg/logo-color.svg'
import whiteLogo from '@/assets/svg/logo.svg'

export default {
  name: 'Logo',
  props: {
    color: { type: Boolean, default: true },
    width: { type: Number, default: 130 },
    margin: { type: String, default: '15px' }
  },
  computed: {
    logoUrl () {
      return this.color ? colorLogo : whiteLogo
    },
    styles () {
      let css = ''
      let custom = false
      if (this.width) {
        css += `max-width:${this.width}px;`
      }
      if (this.height) {
        css += `max-height:${this.height}px;`
      }
      if (this.margin) {
        css += `margin:${this.margin}px;`
      }
      if (this.width || this.height || this.margin) {
        custom = true
      }
      if (!custom) {
        css += 'max-width:200px;'
      }
      return css
    }
  }
}
</script>
