import config from '@/config'
import { filterCampaignTypesColor } from '@/assets/styles/numberly-filters.scss'

const filterCampaignTypes = {
  data () {
    return {
      filterCampaignTypesColor: filterCampaignTypesColor,
      campaignTypeAutomatic: {
        label: this.$t('global.auto'),
        value: {
          id: 'automatic',
          name: this.$t('global.auto')
        }
      },
      campaignTypeTactic: {
        label: this.$t('global.one_shot'),
        value: {
          id: 'one_shot',
          name: this.$t('global.one_shot')
        }
      },
      campaignTypeTransactional: {
        label: this.$t('global.transac'),
        value: {
          id: 'transactional',
          name: this.$t('global.transac')
        }
      }
    }
  },
  methods: {
    /**
     * Get selected type ids, remove tags ids and init the type filter cascade
     */
     initSelectedCampaignTypes (channelType, defaultSelectedCampaignTypes = []) {
      const mapCampaignTypesByCannel = {
        push: config.PUSH_CHANNEL_TAGS,
        sms: config.SMS_CHANNEL_TAGS,
        email: config.EMAIL_CHANNEL_TAGS
      }
      const optionTypes = [this.campaignTypeAutomatic, this.campaignTypeTactic]
      const channelTypeToTagId = mapCampaignTypesByCannel[channelType] || {}
      let typeIds = defaultSelectedCampaignTypes.map(id => channelTypeToTagId[id])
      let selectedTypes = []

      if (this.$route.name !== 'push-list') {
        optionTypes.push(this.campaignTypeTransactional)
      }

      // Small helper to find a channel identifier from its identifier
      const findByTypeId = (tagId) => {
        return Object.keys(channelTypeToTagId).find(key => channelTypeToTagId[key] === parseInt(tagId))
      }

      if (this.$route.query.tags) {
        typeIds = this.$route.query.tags.split(',').map(v => parseInt(v))
      }
      const onlyType = typeIds.filter(id => Object.values(channelTypeToTagId).includes(parseInt(id)))

      if (typeIds.length && (typeIds.length !== optionTypes.length || onlyType.length !== optionTypes.length)) {
        selectedTypes = optionTypes
          .filter(opt => typeIds.map(id => findByTypeId(id)).includes(opt.value.id))
          .map(t => [t.value])
      }

      return { options: optionTypes, selected: selectedTypes }
    },

    /**
     * Format generated value from the cascader selection to be used externally of the Filter mechanism
     */
    formatCampaignTypesCascader (cascaderCampaignTypesSelection) {
      return cascaderCampaignTypesSelection.flat().map(v => v.id)
    }
  }
}

export default filterCampaignTypes
