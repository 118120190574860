<template>
  <section class="page_channel">
    <HeaderQRCodes
      :kpis="kpis"
      :search="filters.search"
      :download-loading="downloadLoading"
      @download="download"
      @update-search="updateSearch"
      @updated-group="updatedGroup"
      @clear-selected-filters="clearSelectedFilters"
    />
    <main class="page_content">
      <TableListing
        type="qr"
        :items="qr_codes"
        :search="filters.search"
        :per-page-limit="filters.limit"
        :page="filters.page"
        :total="totalQR_codes"
        :total-idms="totalQR_codes"
        @updated-sort-by="updatedSortBy"
        @update-search="updateSearch"
        @update-page-limit="updatePageLimit"
        @update-current-page="updateCurrentPage"
      />
    </main>
  </section>
</template>

<script>
import {
  debounce as _debounce,
  get as _get
} from 'lodash'
import { urlParams } from '@/mixins'
import { TableListing } from '@/components/Common/Table'
import { HeaderQRCodes } from '@/components/Parts/Header'

export default {
  name: 'ViewQRList',
  components: {
    HeaderQRCodes,
    TableListing
  },
  mixins: [ urlParams ],
  data () {
    return {
      channel: 'qr'
    }
  },
  computed: {
    downloadLoading () {
      return this.$store.getters['qrCodes/downloadLoading']
    },
    currentGroups () {
      return this.$store.getters['app/selectedGroups'] || null
    },
    filters () {
      return this.$store.getters['qrCodes/filters']
    },
    kpis () {
      return this.$store.getters['qrCodes/kpis']
    },
    qr_codes () {
      return this.$store.getters['qrCodes/items']
    },
    totalQR_codes () {
      return this.$store.getters['qrCodes/totalQR_codes']
    }
  },
  created () {
    let groups

    for (let key in this.$route.query) {
      switch (key) {
        case 'group_ids':
          groups = this.$store.getters['session/groups'].filter(g => this.$route.query[key].split(',').includes(g.id))
          if (groups.length) { this.$store.dispatch('app/selectGroups', groups) }
          break
        case 'l':
          this.$store.dispatch('qrCodes/updatePageLimit', parseInt(this.$route.query[key]))
          break
        case 'p':
          this.$store.dispatch('qrCodes/updateCurrentPage', parseInt(this.$route.query[key]))
          break
        case 's':
          this.$store.dispatch('qrCodes/setSearch', this.$route.query[key])
          break
      }
    }
    this.loadData({ replaceUrl: true })
  },
  methods: {
    searchReactivity: _debounce(function () {
      this.loadData()
    }, 250),
    updateSearch (search) {
      this.$store.dispatch('qrCodes/setSearch', search)
      this.searchReactivity()
    },
    updatedGroup (groups) {
      this.$store.dispatch('app/selectGroups', groups)
      this.loadData()
    },
    updatePageLimit (pageLimit) {
      this.$store.dispatch('qrCodes/updatePageLimit', pageLimit)
      this.loadData()
    },
    updateCurrentPage (currentPage) {
      this.$store.dispatch('qrCodes/updateCurrentPage', currentPage)
      this.loadData({ resetPagination: false })
    },
    updatedSortBy (sortBy) {
      this.$store.dispatch('qrCodes/setSortBy', sortBy)
      this.loadData()
    },
    clearSelectedFilters () {
      this.$store.dispatch('app/selectGroups', [])
      this.loadData()
    },
    getParams ({ isExport=false } = {}) {
      const params = {}

      if (!isExport) {
        // Pagination
        params.l = this.filters.limit
        params.p = this.filters.page
      }

      // Inject other filters
      if (this.filters.search) {
        params.s = this.filters.search
      }
      if (this.filters.orderBy) {
        params.ob = this.filters.orderBy
      }

      if (this.currentGroups.length) {
        params.group_ids = this.currentGroups.map(g => g.id).toString()
      }
      return params
    },
    download () {
      const params = this.getParams({ isExport: true })
      this.$store.dispatch('qrCodes/export', params)
    },
    loadData (options) {
      // Pagination
      const updatePagination = _get(options, 'resetPagination') && this.filters.page != 1
      if (updatePagination) {
        this.$store.dispatch('qrCodes/updateCurrentPage', 1)
      }

      // Requests
      const params = this.getParams()
      this.$store.dispatch('qrCodes/getItems', params)
      this.$store.dispatch('qrCodes/getKpis', params)

      // Url params update
      this.urlParams(params)
    }
  }
}
</script>

<style lang="scss">
</style>
