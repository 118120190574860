import Vue from 'vue'

const SET_APPS = 'SET_APPS'

const moduleApps = {
  namespaced: true,
  state: {
    apps: [],
  },
  mutations: {
    [SET_APPS] (state, apps) {
      Vue.set(state, 'apps', apps)
    },
  },
  actions: {
    getApps ({ commit }, client_id) {
        const url = `/gate/clients/${client_id}/apps`
        return new Promise((resolve, reject) => {
          Vue.axios.get(url)
            .then(response => {
              if (response.status === 200) {
                commit(SET_APPS, response.data)
                resolve(response)
              } else {
                reject(response)
              }
            })
            .catch(error => {
              reject(error.response ? error.response : error)
            })
        })
    }
  },
  getters: {
    apps: state => state.apps,
  }
}

export default moduleApps