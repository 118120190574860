import Vue from 'vue'
import moment from 'moment'

import config from '@/config'
import APIException from './exceptions'


const SET_BOARD = 'SET_BOARD'
const SET_LOADING = 'SET_LOADING'

const moduleSession = {
  namespaced: true,
  state: {
    loading: false,
    boards: {},
  },
  mutations: {
    [SET_LOADING] (state, val) {
      Vue.set(state, 'loading', val)
    },
    [SET_BOARD] (state, { groupName, board }) {
      Vue.set(state.boards, groupName, board)
    },
  },
  actions: {
    async getCampaignStats(_, params ) {
      try {
        const response = await Vue.axios.get(`/api/dashboard`, { params: params })
        if (response.status >= 300) { throw response }

        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    async getBoard({ dispatch, commit }, { groups }) {
      const params = {}
      const campaignParams = {
        tags: [
          config.EMAIL_CHANNEL_TAGS.one_shot,
          config.SMS_CHANNEL_TAGS.one_shot,
          config.PUSH_CHANNEL_TAGS.one_shot
        ].join(','),
        start_date: moment().startOf('day').clone().subtract(1, 'year').toISOString(),
        end_date: moment().endOf('day').toISOString(),
        l: 3,
        ob: '-sending_date'
      }
      if (groups) {
        params['group'] = groups[0].name
        campaignParams['group_names'] = groups.map(g => g.name).toString()
      }

      commit(SET_LOADING, true)
      const promises = [
        dispatch('getCampaignStats', params),
        dispatch('email/getItems', campaignParams, { root: true }),
        dispatch('sms/getItems', campaignParams, { root: true }),
        dispatch('push/getItems', campaignParams, { root: true })
      ]
      return Promise.all(promises.map(p => p.catch(e => e))).then((res) => {
        const errors = res.filter(result => !(result.status && result.status >= 200 && result.status < 300))

        commit(SET_LOADING, false)
        if (errors.length > 0) {
          return new APIException(errors[0])
        }
        commit(SET_BOARD, {
          groupName: groups ? groups[0].name : '_',
          board: {
            info: res[0].data,
            campaigns: {
              email: res[1].data.objects,
              sms: res[2].data.objects,
              push: res[3].data.objects
            }
          }
        })
        return res
      })
    }
  },
  getters: {
    loading: state => state.loading,
    board: state => (groupName) => {
      groupName = groupName ? groupName : '_'
      return state.boards[groupName]
    }
  }
}

export default moduleSession
