import moment from 'moment'
import Vue from 'vue'

import APIException from './exceptions'
import i18n from '@/languages/i18n'

const SET_HEADER_META = 'SET_HEADER_META'
const SET_OVERVIEW = 'SET_OVERVIEW'
const SET_LOADING = 'SET_LOADING'
const SET_REACTIONS = 'SET_REACTIONS'
const SET_DATES = 'SET_DATES'
const SET_DOWNLOAD_LOADING = 'SET_DOWNLOAD_LOADING'
const SET_START_DATE = 'SET_START_DATE'
const SET_END_DATE = 'SET_END_DATE'
const SET_DEFAULT_DATE = 'SET_DEFAULT_DATE'

function setMidnight(dateStr) {
  let date = new Date(dateStr)
  date.setHours(0, 0, 0, 0)
  return date.toJSON()
}

function exportQRDetailFilename(qr, client, lang) {
  const clientName = client.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  const qrName = qr.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  let dateFmt = 'YYYYMMDD'
  if (lang === 'fr') {
    dateFmt = 'DDMMYYYY'
  }
  return `${clientName}_${qrName}_${moment().format(dateFmt)}.xlsx`
}

const moduleQRDetail = {
  namespaced: true,
  state: {
    defaultDate: {},
    header_meta: {},
    overview: {}, 
    reactions: [], 
    loading: false,
    created_at: null,
    start_date: null,
    end_date: null,
    downloadLoading: false
  },
  mutations: {
    [SET_HEADER_META] (state, val) {
      state.header_meta = val
    },
    [SET_OVERVIEW] (state, val) {
      state.overview = val
    },
    [SET_LOADING] (state, val) {
      state.loading = val
    },
    [SET_REACTIONS] (state, val) {
      state.reactions = val
    },
    [SET_DATES] (state, { startDate, endDate }) {
      state.start_date = startDate
      state.end_date = endDate
    },
    [SET_DEFAULT_DATE] (state, dates) {
      Vue.set(state, 'defaultDate', {
        start: dates[0],
        end: dates[1]
      })
      Vue.set(state, 'start_date', dates[0])
      Vue.set(state, 'end_date', dates[1])
    },
    [SET_START_DATE] (state, date) {
      state.start_date = date
    },
    [SET_END_DATE] (state, date) {
      state.end_date = date
    },
    [SET_DOWNLOAD_LOADING] (state, loader) {
      Vue.set(state, 'downloadLoading', loader)
    }
  },
  actions: {
    reset ({ commit }) {
      commit(SET_REACTIONS, [])
      commit(SET_HEADER_META, {})
      commit(SET_OVERVIEW, {})
    },
    async setDates ({ commit }, dates) {
      commit(SET_DATES, {
        startDate: dates[0],
        endDate: dates[1]
      })
    },
    setStartDate ({ commit }, date) {
      commit(SET_START_DATE, date)
    },
    setEndDate ({ commit }, date) {
      commit(SET_END_DATE, date)
    },
    async getHeaderMeta ({ commit }, id) {
      try {
        const response = await Vue.axios.get(`/api/qr_codes/${id}`)
        if (response.status >= 300) { throw response }
        commit(SET_HEADER_META, response.data)
        // Set default date
        let startDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
        if (response.data.created_at) {
          startDate = moment(response.data.created_at).format('YYYY-MM-DD')
          commit(SET_START_DATE, startDate)
        }
        commit(SET_DEFAULT_DATE, [startDate, moment().format('YYYY-MM-DD')])
        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    async getOverview ({ commit }, { id, params }) {
      try {
        const response = await Vue.axios.get(`/api/qr_codes/${id}/overview`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_OVERVIEW, response.data)
      } catch (err) {
        return new APIException(err)
      }
    },
    async getReactions ({ commit }, { id, params }) {
      if (!params.step) {
        params.step = 1
      }
      params['start_date'] = setMidnight(params.start_date)
      params['end_date'] = setMidnight(params.end_date)
      try {
        const response = await Vue.axios.get(`/api/qr_codes/${id}/reactions`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_REACTIONS, response.data.datasets)
      } catch (err) {
        return new APIException(err)
      }
    },
    async exportQR ({ commit, state, rootGetters }, { id, params }) {
      const clientName = rootGetters['session/client'].name
      const qrName = state.header_meta.name
      const filename = exportQRDetailFilename(qrName, clientName, i18n.locale)
      commit(SET_DOWNLOAD_LOADING, true)

      try {
        const xlsx = await Vue.axios({
          url: `/api/qr_codes/${id}/export`,
          method: 'POST',
          responseType: 'blob',
          params: params
        })
        const blob = new Blob([xlsx.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
        })

        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename)
        } else {
          const link = document.createElement('a')
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      } catch (err) {
        return new APIException(err)
      } finally {
        commit(SET_DOWNLOAD_LOADING, false)
      }
    }
  },
  getters: {
    creationDate: state => state.header_meta.creation_date,
    startDate: state => state.start_date,
    reactions: state => state.reactions,
    overview: state => state.overview,
    endDate: state => state.end_date,
    headerMeta: state => state.header_meta,
    downloadLoading: state => state.downloadLoading,
    defaultDate: state => state.defaultDate,
    previewLoading: state => state.previewLoading
  }
}

export default moduleQRDetail
