<template>
  <section class="overview-sms-section">
    <div class="col-left">
      <template v-for="(kpisValue, kpisKey) in overview">
        <component
          :is="components_map[kpisKey].name"
          v-if="isKpi(kpisKey)"
          :key="kpisKey"
          :option="components_map[kpisKey].option"
          :kpiskey="kpisKey"
          :kpisvalue="kpisValue"
          :kpis="kpis"
          :overview="overviewData"
          :channel="'sms'"
        />
      </template>
    </div>

    <div class="col-right">
      <div class="preview mobile-emulator">
        <div class="screen">
          <div class="header-preview">
            <div class="picto-sender">
              <img
                src="@/assets/svg/pictos/sender-sms.svg"
                alt="Sender sms picto"
              >
            </div>
          </div>
          <div class="body-preview">
            <div class="bubble">
              <div class="phyla">
                <img
                  src="@/assets/svg/pictos/phyla.png"
                  alt="Phyla picto"
                >
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="msg"
                v-html="message"
              />
              <!-- eslint-enable vue/no-v-html -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HeaderSMS } from '@/components/Parts/Header'
import {
  BlockGeneric,
  BlockGraph,
  BlockLanding,
  BlockGraphCountries
} from '@/components/Common/Block'

export default {
  name: 'ViewSMSDetailOverview',
  components: { HeaderSMS, BlockGeneric, BlockGraph, BlockLanding, BlockGraphCountries },
  data () {
    return {
      components_map: {
        delivery: { name: 'BlockGeneric', option: null },
        undelivered: { name: 'BlockGraph', option: null },
        clicks: { name: 'BlockGeneric', option: 'table' },
        landing_pages: { name: 'BlockLanding', option: null },
        unsubscriptions: { name: 'BlockGeneric', option: null },
        countries: { name: 'BlockGraphCountries', option: null },
      },
      kpis: {
        sortOrder: {
          delivery: [
            'sms_sent',
            'delivered',
            'delivery_rate',
            'undelivered',
            'unidentified',
            'unit_sms_billed'
          ],
          undelivered: [

          ],
          clicks: [
            'total_clicks',
            'click_rate',
            'links'
          ],
          landing_pages: [
            'visit_rate',
            'click_rate',
            'total_visits',
            'unique_visits',
            'total_clicks',
            'unique_clicks',
            'total_submissions'
          ],
          unsubscriptions: [
            'unsubscribed',
            'unsubscription_rate'
          ]
        },
        important: [
          'sms_sent',
          'delivery_rate',
          'click_rate',
          'unsubscription_rate',
          'visit_rate',
          'click_rate'
        ],
        name: {
          sms_sent: 'send',
          delivery_rate: 'delivery',
          open_rate: 'open',
          click_rate: 'click',
          total_clicks: 'total_clicks',
          unsubscription_rate: 'unsubscribe',
          visit_rate: 'visit',
          unique_clicks: 'unique_clicks',
          total_submissions: 'total_submissions',
          unique_visits: 'unique_visits'
        }
      },
      notKpi: [
        'content_text'
      ],
      preview: 'mobile'
    }
  },
  computed: {
    campaign () {
      return this.$store.getters['smsCampaign/campaign'] || {}
    },
    overviewData () {
      return this.$store.getters['smsCampaign/overview'] || {}
    },
    countries () {
      return this.$store.getters['smsCampaign/countries'] || {}
    },
    client () {
      return this.$store.getters['session/client'] || {}
    },
    message () {
      const msg = this.overviewData.content_text
      const urlRegex = /(https?:\/\/[^\s]+)/g
      const funcReplace = (url) => `<a href="${url}" target="_blank">${url}</a>`
      return msg && msg.includes('http') ? msg.replace(urlRegex, funcReplace) : msg
    },
    overview () {
      let orderedOverview = {}
      for (const key in this.overviewData) {
        if (!this.kpis.sortOrder[key]) {
          orderedOverview[key] = this.overviewData[key]
          continue
        }
        if (key === 'landing_pages') {
          orderedOverview.landing_pages = []
          const lps = this.overviewData.landing_pages

          for (let i in lps) {
            orderedOverview.landing_pages[i] = {
              id: lps[i].id,
              lp_name: lps[i].lp_name,
              data: this.kpis.sortOrder.landing_pages.map(kpiKey => {
                  return {
                    key: kpiKey,
                    name: this.kpis.name[kpiKey] || null,
                    value: lps[i][kpiKey]
                  }
                })
            }
          }
        } else {
          orderedOverview[key] = this.kpis.sortOrder[key]
            .map(kpiKey => {
              return {
                key: kpiKey,
                name: this.kpis.name[kpiKey] || null,
                value: this.overviewData[key][kpiKey]
              }
            })
        }
      }
      // Show countries graph only if 2 or more countries available
      if (this.countries.labels && this.countries.labels.length > 1) {
        // Reorder orderedOverview for having countries graph in 2nd position
        return {
          delivery: orderedOverview.delivery,
          countries: this.countries,
          ...orderedOverview
        }
      }
      return orderedOverview
    }
  },
  methods: {
    /**
     * Check if they key is a KPI
     * @param {String} key (kpi key)
     * @return {Boolean}
     */
    isKpi (key) {
      return !this.notKpi.includes(key)
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.overview-sms-section {
  display: flex;
  padding-bottom: 3rem;

  @media only screen and (max-width: $--mobile-width) {
    flex-direction: column;
  }

  .col-left {
    width: 680px;

    @media only screen and (max-width: $--mobile-width) {
      width: 100%;
      flex: 1;
    }
  }

  .col-right {
    width: 520px;

    .switch {
      text-align: center;
      margin-bottom: 1.2rem;
    }

    .preview {
      margin: auto;
    }

    .mobile-emulator {
      background: url("~@/assets/svg/emails/preview/frame.svg") no-repeat center center;
      width: 450px;
      height: 800px;
      display: flex;
      justify-content: center;
      align-items: center;

      .screen {
        position: relative;
        width: 320px;
        min-height: 630px;
        max-height: 630px;
        height: 630px;
        background-color: #f2f3f8;
        border: 1px solid #e9e9e9;
        top: 2px;
        .header-preview {
          width: 100%;
          height: 100px;
          background-color: #e0e3ed;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .picto-sender {
            width: 37px;
            height: 37px;
          }

          .sender-name {
            margin-top: 5px;
          }
        }

        .body-preview {
          padding: 2rem;

          .bubble {
            position: relative;
            padding: 1rem;
            border-radius: 10px;
            background-color: #e0e3ed;

            .msg {
              font-size: 12px;
            }

            .phyla {
              position: absolute;
              bottom: -9px;
              left: -21px;
              width: 30px;

              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }

    .desktop-emulator {
      position: relative;
      width: 520px;
      min-height: 630px;
      background-color: #d5dde3;
      border: solid 1px #dcdfe6;

      .content {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    @media only screen and (max-width: $--mobile-width) {
      width: 100%;

      .mobile-emulator,
      .desktop-emulator {
        width: 100%;
      }
    }
  }
}
</style>
