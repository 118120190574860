<template>
  <div
    class="kpi-card"
    @mouseover="displayEdit = true"
    @mouseleave="hideEditButton"
  >
    <el-popover
      v-model="displayPopover"
      placement="bottom"
      trigger="click"
      content="this is content, this is content, this is content"
      popper-class="kpi-popover"
    >
      <el-button
        v-for="(updatePreferenceKpi, index) in allKpis"
        :key="index"
        class="kpi-popover-item"
        :disabled="kpiPreferences.includes(index)"
        @click="updatePreference(index)"
      >
        <i
          v-if="kpi === index"
          class="icon material-icons md-15"
          style="color: #228785"
        >
          check
        </i>
        <div
          class="kpi-popover-item-label"
          :class="{
            current: kpi === index,
            included: kpiPreferences.includes(index) && kpi !== index,
          }"
        >
          {{ $t(`${getTranslationKey(updatePreferenceKpi.translate_key)}`) }}
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            :open-delay="300"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              slot="content"
              v-html="$t(`${getTranslationKey(updatePreferenceKpi.translate_key)}_tip`)"
            />
            <!--eslint-enable-->
            <i class="material-icons md-12 help">help_outline</i>
          </el-tooltip>
        </div>
      </el-button>
      <el-button
        v-show="displayEdit"
        slot="reference"
        class="kpi-edit"
      >
        <i
          class="edit-icon material-icons md-12"
        >
          edit
        </i>
      </el-button>
    </el-popover>
    <i
      class="icon material-icons md-24"
      :style="`color: ${allKpis[kpi].icon.color}`"
    >
      {{ allKpis[kpi].icon.key }}
    </i>
    <p class="kpi-value">
      {{ formatters[allKpis[kpi].type](value) }}
    </p>
    <div class="kpi-label">
      {{ $t(`${getTranslationKey(kpi)}`) }}
      <el-tooltip
        effect="dark"
        placement="top"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          slot="content"
          v-html="$t(`${getTranslationKey(kpi)}_tip`)"
        />
        <!--eslint-enable-->
        <i class="material-icons md-12 help">help_outline</i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'

export default {
  name: "KPICard",
  props: {
    kpi: {
      type: String,
      required: true,
    },
    kpiPreferences: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    channel: {
      type: String,
      required: true,
    },
    allKpis: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      displayEdit: false,
      displayPopover: false,
      formatters: {
        "currency": this.formatCurrency,
        "rate": this.formatPercent,
        "number": this.formatInteger,
      }
    };
  },
  computed: {
    clientCurrency() {
      return this.$store.getters["session/client"].currency;
    },
  },
  methods: {
    getTranslationKey(translateKey) {
      return `kpi.${this.channel}.${translateKey}`
    },
    hideEditButton() {
      if (!this.displayPopover) {
        this.displayEdit = false
      }
    },
    updatePreference(index) {
      this.$emit('update-preferences', index)
      this.hideEditButton()
      this.displayPopover = false
    },
    formatInteger(number) {
      return numeral(number).format("0,0");
    },
    formatPercent(number) {
      return numeral(number).format("0.00%");
    },
    formatCurrency(number) {
      return `${numeral(number).format("0,0.00")} ${this.clientCurrency}`;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.help {
  height: 12px;
  line-height: 0.85;
  margin-left: 1px;
  vertical-align: middle;
  color: #C0C4CC
}
.kpi-card {
  position: relative;
  text-align: center;
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .kpi-edit {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
    min-width: 0;
    width: 20px;
    height: 20px;
    border: none;
    background-color: #E9F3F3;
    color: #228785;

    &:focus {
      background-color: #E9F3F3;
    }

    &:hover {
      background-color: #BDDBDB;
    }

    .edit-icon {
      margin-right: 0px;
    }
  }
  .icon {
    width: 24px;
  }

  .kpi-value {
    font-size: 18px;
    font-weight: 600;
    margin: 0.5rem 0;
  }

  .kpi-label {
    color: var(--neutral-grey-8, #9B9B9B);
    text-align: center;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

}

.kpi-popover {
  max-height: 300px;
  width: 285px;
  padding: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  .kpi-popover-item {
    width: 100%;
    margin: 0;
    padding: 6px 20px;
    border: none;

    span {
      height: 30px;
      display: flex;
      flex-direction: row;
      color: $--color-text-primary;
      justify-content: center;
      font-family: 'Open Sans', sans-serif;

      .icon {
        margin: auto 0;
      }

      .kpi-popover-item-label {
        margin: auto;
        margin-left: 25px;
        max-width: 85%;
        white-space: normal;
        text-align: left;
        word-break: break-word;

        &.included {
          color: #B1B1B1
        }

        &.current {
          color: $--color-primary;
          font-weight: bold;
          margin-left: 5px;
        }
      }
    }
  }
}

</style>
