import moment from 'moment'
import Vue from 'vue'

import APIException from './exceptions'
import i18n from '@/languages/i18n'


const SET_CAMPAIGN = 'SET_CAMPAIGN'
const SET_HEADER_META = 'SET_HEADER_META'
const SET_HEADER_SPLITS = 'SET_HEADER_SPLITS'
const SET_OVERVIEW = 'SET_OVERVIEW'
const SET_SPLITS = 'SET_SPLITS'
const SET_CURRENT_SPLIT = 'SET_CURRENT_SPLIT'
const SET_DOWNLOAD_LOADING = 'SET_DOWNLOAD_LOADING'
const RESET_STATE = 'RESET_STATE'
const SET_REACTION = 'SET_REACTION'


function buildRequest(params, state) {
  if (state.currentSplit.length && state.currentSplit[0].type) {
    if (!params['split'] || !params['split'].length) {
      params['split'] = state.currentSplit.map(item => item.id).join('|')
    }
    params['split_type'] = state.currentSplit[0].type
  }
  return params
}

function exportPushFilename(campaign, client, lang) {
  // NomClient_NomCampagne_AAAAMMJJ / ClientName_CampaignName__YYYYMMDD
  const clientName = client.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  const campaignName = campaign.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  let dateFmt = 'YYYYMMDD'
  if (lang === 'fr') {
    dateFmt = 'DDMMYYYY'
  }
  return `${clientName}_push_${campaignName}_${moment().format(dateFmt)}.xlsx`
}

function exportPushSplitFilename(campaign, client, lang) {
  // Nom du fichier : NomClient_SplitView_push_NomCampagne_AAAAMMJJ / ClientName_SplitView_push_CampaignName__YYYYMMDD
  const clientName = client.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  const campaignName = campaign.replace(/^\w/, c => c.toUpperCase()).split(' ').join('')
  let view = 'TableView'
  let dateFmt = 'YYYYMMDD'

  if (lang === 'fr') {
    view = 'VueTableau'
    dateFmt = 'DDMMYYYY'
  }
  return `${clientName}_${view}_push_${campaignName}_${moment().format(dateFmt)}.xlsx`
}

const modulePushCampaign = {
  namespaced: true,
  state: {
    campaign: null,
    header_meta: {},
    header_splits: {},
    overview: {},
    splits: [],
    reactions: [],
    currentSplit: [],
    downloadLoading: false
  },
  mutations: {
    [SET_CAMPAIGN] (state, { campaignId, data }) {
      data.id = campaignId
      Vue.set(state, 'campaign', data)
    },
    [SET_HEADER_META] (state, val) {
      Vue.set(state, 'header_meta', val)
    },
    [SET_HEADER_SPLITS] (state, val) {
      Vue.set(state, 'header_splits', val)
    },
    [SET_OVERVIEW] (state, val) {
      Vue.set(state, 'overview', val)
    },
    [SET_REACTION] (state, val) {
      state.reactions = val
    },
    [SET_SPLITS] (state, val) {
      Vue.set(state, 'splits', val)
    },
    [SET_CURRENT_SPLIT] (state, val) {
      Vue.set(state, 'currentSplit', val)
    },
    [SET_DOWNLOAD_LOADING] (state, loader) {
      Vue.set(state, 'downloadLoading', loader)
    },
    [RESET_STATE] (state, campaignId) {
      if (state.campaign && campaignId !== state.campaign.campaign_id) {
        Vue.set(state, 'campaign', null)
      }
      Vue.set(state, 'header_meta', {})
      Vue.set(state, 'header_splits', {})
      Vue.set(state, 'overview', {})
      Vue.set(state, 'splits', [])
      Vue.set(state, 'currentSplit', [])
      Vue.set(state, 'downloadLoading', false)
    }
  },
  actions: {
    resetState ({ commit }, campaignId) {
      commit(RESET_STATE, campaignId)
    },
    selectSplit ({ commit }, split) {
      commit(SET_CURRENT_SPLIT, split)
    },
    resetSplits ({ commit }) {
      commit(SET_SPLITS, [])
    },
    async getCampaign ({ commit }, campaignId) {
      try {
        const response = await Vue.axios.get(`/api/push_notifications/${campaignId}`)
        if (response.status >= 300) { throw response }

        const data = response.data
        commit(SET_CAMPAIGN, { campaignId, data })
        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    async getHeaderMeta({ commit }, { id, params }) {
      try {
        const response = await Vue.axios.get(`/api/push_notifications/${id}/metadata`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_HEADER_META, response.data)
      } catch (err) {
        return new APIException(err)
      }
    },
    async getHeaderSplits({ commit }, { id, params }) {
      try {
        const response = await Vue.axios.get(`/api/push_notifications/${id}/splits`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_HEADER_SPLITS, response.data)
      } catch (err) {
        return new APIException(err)
      }
    },
    async getOverview({ state, commit }, { id, params }) {
      try {
        buildRequest(params, state)
        const response = await Vue.axios.get(`/api/push_notifications/${id}/overview`, {
          params: params
        })
        if (response.status >= 300) { throw response }

        commit(SET_OVERVIEW, response.data)
      } catch (err) {
        return new APIException(err)
      }
    },
    async getReactions({ state, commit }, { id, params }) {
      params = buildRequest(params, state)
      if (!params['period']) {
        params['period'] = 24
      }
      try {
        const response = await Vue.axios.get(`/api/push_notifications/${id}/reactions`, {
          params: params
        })
        if (response.status >= 300) { throw response }

        commit(SET_REACTION, response.data.datasets)
      } catch (err) {
        return new APIException(err)
      }
    },
    async getSplits ({ state, commit }, { id, params }) {
      try {
        buildRequest(params, state)
        const response = await Vue.axios.get(`/api/push_notifications/${id}/splits/data`, { params: params })
        if (response.status >= 300) { throw response }

        commit(SET_SPLITS, response.data)
        return response
      } catch (err) {
        return new APIException(err)
      }
    },
    async exportCampaign({ commit, state, rootGetters }, { id, params }) {
      const clientName = rootGetters['session/client'].name
      const campaignName = state.campaign.campaign_name
      const filename = exportPushFilename(campaignName, clientName, i18n.locale)
      buildRequest(params, state)
      commit(SET_DOWNLOAD_LOADING, true)

      try {
        const xlsx = await Vue.axios({
          url: `/api/push_notifications/${id}/export`,
          params: params,
          method: 'POST',
          responseType: 'blob'
        })

        const blob = new Blob([xlsx.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
        })

        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename)
        } else {
          const link = document.createElement('a')
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      } catch (err) {
        return new APIException(err)
      } finally {
        commit(SET_DOWNLOAD_LOADING, false)
      }
    },
    async exportCampaignSplits({ commit, state, rootGetters }, { id, params }) {
      const clientName = rootGetters['session/client'].name
      const campaignName = state.campaign.campaign_name
      const fileName = exportPushSplitFilename(campaignName, clientName, i18n.locale)
      buildRequest(params, state)
      commit(SET_DOWNLOAD_LOADING, true)

      try {
        const xlsx = await Vue.axios({
          url: `/api/push_notifications/${id}/splits/export`,
          method: 'POST',
          responseType: 'blob',
          params: params
        })
        const blob = new Blob([xlsx.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
        })

        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, fileName)
        } else {
          const link = document.createElement('a')
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', fileName)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      } catch (err) {
        return new APIException(err)
      } finally {
        commit(SET_DOWNLOAD_LOADING, false)
      }
    }
  },
  getters: {
    campaign: state => state.campaign,
    currentSplit: state => state.currentSplit,
    overview: state => state.overview,
    headerSplits: state => state.header_splits,
    headerMeta: state => state.header_meta,
    splits: state => state.splits,
    downloadLoading: state => state.downloadLoading,
    reactions: state => state.reactions
  }
}

export default modulePushCampaign
