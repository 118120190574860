<template>
  <table class="links-table">
    <thead>
      <tr>
        <th class="url">
          {{ $t('table.header.link') }}
        </th>
        <th class="total">
          {{ $t('table.header.total_click') }}
        </th>
        <th class="unique">
          {{ $t('table.header.unique_click') }}
        </th>
      </tr>
    </thead>
    <tbody v-if="kpisvalue[2].value[0].url !== null">
      <tr
        v-for="(link, i) in kpisvalue[2].value"
        :key="'link_'+i"
      >
        <td class="url">
          <a
            :href="link.url"
            target="_blank"
          >{{ link.url | truncate(36) }}</a>
        </td>
        <td class="total">
          {{ link.total_clicks }}
        </td>
        <td class="unique">
          {{ link.unique_clicks }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td>{{ $t('table.content.empty_links') }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'BlockTable',
  props: {
    kpisvalue: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

.links-table {
  table-layout: fixed;
  width: 100%;
  font-size: 11px;

  .border {
    border-bottom: solid 1px $--color-pale-blue;
  }
  td {
    word-wrap: break-word;
  }
  .url {
    text-align: left;
    width: 100% !important;
    padding-bottom: 0.35rem;
    margin-bottom: 0.35rem;
    a {
      color: $--text-dark;
      display: block;
      display: -webkit-box;
      max-width: 100%;
      margin: 0 auto;
      line-height: 1.4;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .unique,
  .total {
    width: 50px !important;
    text-align: center;
    padding-bottom: 0.5rem;
    margin-bottom: 0.35rem;
  }
}
</style>
