<template>
  <div
    v-loading="isLoading"
    class="map-reporting"
  >
    <iframe
      ref="map-view-page"
      sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      :style="`
        width: 100%;
        max-height: ${isLoading ? '500px': 'none'};
      `"
      frameborder="0"
      @load="resizeIframe()"
    />
  </div>
</template>

<script>
/* eslint camelcase: ['error', {properties: never}] */
export default {
  name: 'MapReporting',
  props: {
    html: {
      type: String,
      required: true
    },
    stats: {
      type: Array,
      required: true
    },
    displayedLinks: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isLoading: true
    }
  },
  watch: {
    /**
     * Update map on segment update
     */
    html () {
      this.updateMap()
    },
    displayedLinks () {
      this.updateMap()
    }
  },
  mounted () {
    this.cleanMap()
    this.updateMap()
  },
  methods: {
    /**
     * Resize iFrame depending content height
     */
    resizeIframe () {
      this.$nextTick(() => {
        const iframe = this.$refs['map-view-page']
        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px'
        iframe.contentWindow.document.body.style = 'overflow: hidden;'
      })
    },
    /**
     * Clean ifram
     */
    cleanMap () {
      const iframe = this.$refs['map-view-page']
      iframe.contentWindow.document.open()
      iframe.contentWindow.document.write('')
      iframe.contentWindow.document.close()
    },
    /**
     * Update map on data change
     */
    updateMap () {
      this.isLoading = true
      const iframe = this.$refs['map-view-page']
      iframe.contentWindow.document.open()
      iframe.contentWindow.document.write(this.html)
      iframe.contentWindow.document.close()
      // NOTE(@chtr): use setTimeout to delay link highlighting
      // nextTick doesn't works in this case, to dig....
      setTimeout(() => {
        this.isLoading = false
        this.highlighLinks()
      }, 500);
    },
    /**
     * Add highlight to html depending filter
     * and stats
     */
    highlighLinks () {
      const iframe = this.$refs['map-view-page']
      const links = iframe.contentWindow.document.getElementsByTagName('a')

      for (const link of links) {
        if (!link.href) {
          /* eslint-disable no-console */
          console.warn(`No href for link: ${link}`)
          /* eslint-enable no-console */
          continue
        }

        const linkId = (link.href.match('r=([^&#]*)') || [])[1] || null
        const displayedLinksIds = this.displayedLinks.map(el => el.link_number)
        if (linkId === null) {
          /* eslint-disable no-console */
          console.warn(`No ID for link: ${link.href}`)
          /* eslint-enable no-console */
          continue
        }
        if (!displayedLinksIds.includes(Number(linkId))) {
          // Filter does not include this id
          continue
        }

        const linkStats = this.stats.find(stat => stat.link_number === Number(linkId))
        if (!linkStats) {
          /* eslint-disable no-console */
          console.warn(`No stats for link: ${linkId}`)
          /* eslint-enable no-console */
          continue
        }
        this.highlighLink({
          id: linkId,
          dom: link,
          clicks: linkStats.total_clicks,
          rate: linkStats.percentage,
          url: linkStats.link
        })
      }
    },
    /**
     * Generate highligh effect on a link
     * @param {String} id     (Link id)
     * @param {String} dom    (Link DOM)
     * @param {String} clicks (Link total clicks)
     * @param {Float}  rate   (Link rate between 0 and 1)
     * @param {String} url    (Link url from stats)
     */
    highlighLink ({ id, dom, clicks, rate, url }) {
      // Create DOM container
      const filterDom = document.createElement('div')
      const labelDom = document.createElement('div')
      // Get hsl color based on rate
      const color = this.getColorFromPercent(rate)
      // DOM String
      const percentage = `${this.$options.filters.round2dec(rate * 100)}`
      const nbrClicks = `${this.$t('table.header.total_click')}: ${clicks}`
      const nbrPercent = `${percentage}${this.$t('table.header.total_percent')}`
      const filterString = `id: ${id} / ${nbrClicks} / ${nbrPercent}`
      const labelString = `<div title="${filterString}">${percentage}%</div>`
      // Ensure link redirection
      dom.href = url
      // Add target if not defined
      if (!dom.target) { dom.target = '_blank' }
      // DOM Css
      const filterCss = {
        'z-index': 10,
        'top': 0,
        'left': 0,
        'right': 0,
        'bottom': 0,
        'width': 'calc(100% - 2px)', // remove borders extra width
        'position': 'absolute',
        'border': `1px solid ${['hsla(', color, ',70%, 50%, 1)'].join('')}`,
        'background': ['hsla(', color, ',70%, 50%, 0.5)'].join('')
      }
      const labelCss = {
        'z-index': 11,
        'line-height': '12px',
        'font-size': '12px',
        'padding': '1px',
        'top': '2px',
        'left': '2px',
        'position': 'absolute',
        'background': 'rgba(255, 255, 255, .7)',
        'color': 'rgba(51, 51, 51, 1)'
      }
      // DOM Generation
      filterDom.title = filterString
      labelDom.innerHTML = labelString
      Object.assign(filterDom.style, filterCss)
      Object.assign(labelDom.style, labelCss)
      dom.style.position = 'relative'
      dom.style.display = 'inline-block'
      dom.appendChild(filterDom)
      dom.appendChild(labelDom)
    },
    /**
     * Generate color depending perentage
     */
    getColorFromPercent (rate) {
      const maxHue = 180
      const minHue = 0
      // to add an offset
      return Math.floor((1 - rate) * maxHue + minHue).toString()
    }
  }
}
</script>

<style lang='scss'>
.map-reporting {
  background: white;
  border: 1px solid #DCDFE6;
  min-height: 500px;
  width: 800px;
}
</style>
