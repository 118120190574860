<template>
  <div class="avatar-holder">
    <img
      v-if="avatarUrl"
      :src="avatarUrl"
      :style="imgStyle"
      alt="User avatar"
    >
    <span
      v-else
      class="initials"
    >
      {{ initials }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'AvatarHolder',
  props: {
    avatarUrl: { type: String, default: null },
    displayName: { type: String, default: null },
    width: { type: Number, default: 48 },
    height: { type: Number, default: 48 },
    fontSize: { type: Number, default: 10 }
  },
  computed: {
    holderUrl () {
      return `holder.js/${this.width}x${this.height}?size=${this.fontSize}&text=${this.initials}`
    },
    initials () {
      return this.$options.filters.initials(this.displayName)
    },
    imgStyle () {
      return `width:${this.width}px; height: ${this.height}px;`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.avatar-holder {
  & > img {
    border-radius: 50%;
    box-shadow: none;
  }

  .initials {
    height: 25px;
    width: 25px;
    padding-left: 2px;
    display: flex;
    font-size: 11px;
    color: $--text-avatar;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $--avatar-background-color;
    font-family: 'Arial';
    font-weight: bold;
  }
}
</style>
